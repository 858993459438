// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import BootstrapVue from 'bootstrap-vue'
import VueResource from 'vue-resource'
import Vuelidate from 'vuelidate'
import VueHighcharts from 'vue-highcharts'
import VueNotifications from 'vue-notification'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faSpinner,
  faBackward,
  faSignOutAlt,
  faSync,
  faSignInAlt,
  faCog,
  faExclamationCircle,
  faArrowLeft,
  faFillDrip,
  faFilePdf,
  faFileAlt,
  faFileExcel,
  faEllipsisH,
  faAngleRight,
  faAngleLeft,
  faTimes,
  faAdjust,
  faSearch,
  faCube,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Datetime from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import Storage from 'vue-ls'

library.add(faSpinner)
library.add(faBackward)
library.add(faSignOutAlt)
library.add(faSignInAlt)
library.add(faArrowLeft)
library.add(faFileExcel)
library.add(faFilePdf)
library.add(faEllipsisH)
library.add(faAngleRight)
library.add(faAngleLeft)
library.add(faTimes)
library.add(faAdjust)
library.add(faSearch)
library.add(faFillDrip)
library.add(faFileAlt)
library.add(faExclamationCircle)
library.add(faSync)
library.add(faCog)
library.add(faCube)
library.add(faInfoCircle)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(Vuelidate)
Vue.use(VueResource)
Vue.use(VueHighcharts)
Vue.use(BootstrapVue)
Vue.use(Datetime)
Vue.use(VueNotifications)
Vue.use(Storage, { namespace: 'vuejs__' })

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router: router,
  store,
  components: { App },
  template: '<App/>'
})
