<template>
  <b-container fluid>
    <b-row>
      <b-col md="6" class="text-left">
        <b-btn
          variant="outline-secondary"
          :size="'sm'"
          style="margin: auto;"
          @click="goBack()"
          ><font-awesome-icon icon="arrow-left" />&nbsp;Tilbake</b-btn
        >
      </b-col>
    </b-row>
    <b-container fluid>
      <b-row v-if="this.couldNotFindLogger">
        <b-col md="4" class="text-right"
          ><font-awesome-icon icon="exclamation-circle" size="4x"
        /></b-col>
        <b-col md="4" class="text-left" style="padding-top:20px"
          >Måler med Id='{{ this.$route.params.id }}' ble ikke funnet</b-col
        >
      </b-row>
      <b-row v-if="this.currentLogger != null">
        <b-col md="12" class="text-center">
          <h2 v-html="loggerName" />
          <h5>{{ lastTime }}</h5>
        </b-col>
      </b-row>
    </b-container>
    <ChartSensor v-if="this.currentLogger != null" :logger_type="logger_type" />
  </b-container>
</template>

<script>
import { SUCCESS } from '../../store/actions/backend.js'
import { REQUEST_LOGGER_INFO, REQUEST_ASSOCIATED_LOGGER_INFO } from '../../store/action-types'
import ChartSensor from './chart-sensor'
import { toPublicString, asTimeInMilliseconds } from '../../utils/Date.js'
import { mapGetters, mapActions } from 'vuex'
import { IKKE_NAVN } from '../../utils/logger_consts.js'

export default {
  props: ['logger_type', 'title'],
  computed: {
    ...mapGetters([
      'loggerInfos',
      'measurements',
      'measurement_status',
      'logger_status'
    ]),

    lastTimeInMilliseconds () {
      if (this.currentLogger && this.currentLogger.logger.timeSeries.length > 0) {
        return asTimeInMilliseconds(this.currentLogger.logger.timeSeries[0].latestData.t)
      }

      return 0
    },

    lastTime () {
      if (this.currentLogger && this.currentLogger.logger.timeSeries.length > 0) {
        return 'Sist nedlastet ' + toPublicString(this.currentLogger.logger.timeSeries[0].latestData.t)
      }

      return 'n/a'
    },

    loggerName () {
      return this.currentLogger.logger.logger.name == null || this.currentLogger.logger.logger.name.length === 0 ? `<i>` + IKKE_NAVN + `</i>` : this.currentLogger.logger.logger.name
    },

    isLoaded () {
      return this.measurement_status === SUCCESS || this.logger_status === SUCCESS
    },

    couldNotFindLogger () {
      return this.isLoaded && this.currentLogger == null
    },

    currentLogger: function () {
      if (this.loggerInfos && this.loggerInfos.length > 0) {
        let loggerId = Number(this.$route.params.id)
        return this.loggerInfos.find(w => w.logger.logger.id === loggerId)
      }

      return null
    }
  },

  name: 'logger_details',
  components: { ChartSensor },
  methods: {
    ...mapActions({
      loadLoggerInfo: REQUEST_LOGGER_INFO,
      loadAssociatedLoggers: REQUEST_ASSOCIATED_LOGGER_INFO
    }),

    goBack: function () {
      this.$router.push('/' + this.logger_type)
    }
  },
  watch: {
    currentLogger: function () {
      if (!this.currentLogger) {
        this.$router.push('/' + this.logger_type)
      }
    }
  },

  created () {
    if (!this.currentLogger) {
      this.loadLoggerInfo(this.logger_type)
    }

    if (this.currentLogger != null && this.currentLogger.logger.associated_logger_status !== SUCCESS) {
      this.loadAssociatedLoggers(this.$route.params.id)
    }
  }
}
</script>
