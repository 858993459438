<template>
  <b-container fluid>
    <b-row>
      <b-col md="6" class="text-left">
        <b-btn
          variant="outline-secondary"
          :size="'sm'"
          style="margin: auto;"
          @click="goBack()"
          ><font-awesome-icon icon="arrow-left" />&nbsp;Tilbake</b-btn
        >
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-4 mb-4" style="height: 85%;">
        <b-embed
          style="height: 85vh;"
          type="iframe"
          aspect="16by9"
          src="../../pdf/Gjennomgang med ROSIM.pdf"
          allowfullscreen
        ></b-embed>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

export default {
  name: 'weir_pdf_viewer',
  methods: {
    goBack: function () {
      this.$router.push('/weir')
    }
  }
}
</script>
