<template>
  <div>
    <LoggerList
      logger_type="flow"
      title="Vannføring"
      icon_marker_letter="F"
    ></LoggerList>
  </div>
</template>

<script>
import LoggerList from '../index'
export default {
  name: 'flow-list-form',
  components: {
    LoggerList
  }
}
</script>
