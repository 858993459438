export const AUTH_BASE_URL = 'https://rosim-identity2.azurewebsites.net'
export const LOGIN_URL = AUTH_BASE_URL + '/connect/token'
export const AUTH_CLIENT_ID = 'cmVnbmJ5Z2Vfb3ZlcmZsb3c6U3RvcmdhdGVuIHNwcmVyIHNlZw=='

export const BACKEND_BASE_URL = 'https://regnbyge-backend.azurewebsites.net'
export const SENSOR_REQUEST_URL = BACKEND_BASE_URL + '/logger'
export const LOGGER_REQUEST_URL = BACKEND_BASE_URL + '/logger/'
export const LOGGER_INFO_REQUEST_URL = BACKEND_BASE_URL + '/logger?type='
export const OVERFLOW_REQUEST_URL = BACKEND_BASE_URL + '/overflow'
export const OVERFLOW_REPORT_REQUEST_URL = OVERFLOW_REQUEST_URL + '/report'
export const USER_INFO_URL = BACKEND_BASE_URL + '/user'
export const CHECK_LOGGER_URL = OVERFLOW_REQUEST_URL + '/check'
export const UNCHECK_LOGGER_URL = OVERFLOW_REQUEST_URL + '/uncheck'
export const PHOSPHOR_QUANTITY_URL = OVERFLOW_REQUEST_URL + '/phosphor'
export const OVERFLOW_REPORT_BRIEF_REQUEST_URL = OVERFLOW_REQUEST_URL + '/report/brief'
export const MASHUP_REQUEST_URL = BACKEND_BASE_URL + '/mashup'
export const LOGGER_REPORT_REQUEST_URL = BACKEND_BASE_URL + '/logger/values'
export const LOGGER_FILE_FORMATS_REQUEST_URL = BACKEND_BASE_URL + '/logger/fileFormats'
export const ASSOCIATED_PART = '/associated'

export const ERROR = 'ERROR'
export const SUCCESS = 'SUCCESS'
export const LOADING = 'LOADING'

export const UNAUTHORIZED = 401
