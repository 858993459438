<template>
  <div>
    <!-- Modal Component -->
    <b-modal
      id="mashup_modal"
      ref="mashup_modal"
      headerClass="p-2"
      size="lg"
      button-size="sm"
      cancel-title="Avbryt"
      centered
      title="Mashup editering"
      @ok="handleOk"
      @shown="resetModal"
    >
      <b-card>
        <b-container fluid>
          <b-row v-if="hasErrors" class="text-center">
            <b-col md="12">
              <b-alert
                show
                variant="danger"
                style="margin: auto;"
                class="center-text"
                >{{ error }}</b-alert
              >
              <br />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="2" class="text-left text-bottom">
              <small><b>Sensor:</b></small>
            </b-col>
            <b-col md="4">
              <b-form-select
                :size="'sm'"
                class="mb-3"
                v-model="selectedSensor"
                :options="options"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="2" class="text-left text-bottom">
              <small><b>Starttid:</b></small>
            </b-col>
            <b-col md="4" class="text-left">
              <b-form-input
                class="mb-3"
                v-model="startDate"
                lang="nb-No"
                :size="'sm'"
                :type="'date'"
              ></b-form-input>
            </b-col>
            <b-col md="4">
              <b-form-input
                v-model="startTime"
                :size="'sm'"
                :type="'time'"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row class="text-left text-bottom">
            <b-col md="1"></b-col>
            <b-col md="2">
              <small><b>Sluttid:</b></small>
            </b-col>
            <b-col md="4">
              <b-form-input
                class="mb-3"
                :size="'sm'"
                v-model="endDate"
                :type="'date'"
              ></b-form-input>
            </b-col>
            <b-col md="4">
              <b-form-input
                v-model="endTime"
                :size="'sm'"
                :type="'time'"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row class="text-center">
            <b-col md="12">
              <b-btn
                class="mx-auto"
                :size="'sm'"
                variant="outline-primary"
                @click="addMashup()"
                >Legg til mashup</b-btn
              >
            </b-col>
          </b-row>
        </b-container>
      </b-card>
      <hr />
      <b-container fluid v-if="!isLoaded">
        <font-awesome-icon icon="spinner" size="4x" spin />
      </b-container>
      <b-container fluid v-if="isLoaded && mashups.length == 0">
        <b-row class="report_row">
          <b-col md="12" class="text-center">
            There is no mashups
          </b-col>
        </b-row>
      </b-container>
      <b-container fluid v-if="isLoaded && mashups.length > 0">
        <b-row class="report_header">
          <b-col md="3" class="text-center"
            ><small><b>Sensor</b></small></b-col
          >
          <b-col md="4" class="text-center"
            ><small><b>Starttid</b></small></b-col
          >
          <b-col md="4" class="text-center"
            ><small><b>Sluttid</b></small></b-col
          >
          <b-col md="1"></b-col>
        </b-row>
        <b-row
          :key="mashup.id"
          v-for="mashup in mashups"
          :class="getOddClass(mashup)"
        >
          <b-col md="3" class="text-center"
            ><small
              >{{ getOptionNameFromValue(mashup.sensorType) }}
            </small></b-col
          >
          <b-col md="4" class="text-center"
            ><small>{{ dateToPublicString(mashup.startTime) }} </small></b-col
          >
          <b-col md="4" class="text-center"
            ><small>{{ dateToPublicString(mashup.endTime) }} </small></b-col
          >
          <b-col md="1" class="text-center"
            ><b-button
              :size="'sm'"
              :variant="'link'"
              style="color:#dc3545bd"
              @click="removeMashup(mashup)"
              ><font-awesome-icon icon="times"/></b-button
          ></b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import { toPublicString, asTimeInMilliseconds } from '../../../utils/Date.js'
import {
  REQUEST_MASHUP_PERIODS,
  ADD_MASHUP_PERIODS,
  REMOVE_MASHUP_PERIODS
} from '../../../store/action-types'
import { SUCCESS } from '../../../store/actions/backend'
import { mapGetters, mapActions } from 'vuex'

const uuidv4 = require('uuid')

export default {
  name: 'mashup-form',
  computed: {
    ...mapGetters(['mashup_periods', 'mashup_status']),
    isLoaded () {
      return this.mashup_status === SUCCESS
    },
    hasErrors () {
      return this.error !== ''
    }
  },
  data () {
    return {
      startTime: null,
      startDate: null,
      endTime: null,
      endDate: null,
      error: '',
      selectedSensor: null,
      mashups: [],
      deleted_mashups: [],
      added_mashups: [],
      options: [
        { value: null, text: 'Velg sensor', disabled: true },
        { value: 0, text: 'Weir level' },
        { value: 1, text: 'Weir flow' },
        { value: 2, text: 'Outlet level' }
      ]
    }
  },
  methods: {
    ...mapActions({
      reloadMashupPeriods: REQUEST_MASHUP_PERIODS,
      addMashupPeriods: ADD_MASHUP_PERIODS,
      deleteMashupPeriods: REMOVE_MASHUP_PERIODS
    }),
    addMashup: function () {
      this.error = ''
      if (this.selectedSensor == null) {
        this.error = 'Sensor must be selected'
        return
      }

      if (this.startDate == null || this.startTime == null) {
        this.error = 'Start date and time must be specified'
        return
      }

      if (this.endDate == null || this.endTime == null) {
        this.error = 'End date and time must be specified'
        return
      }

      var start = this.startDate + 'T' + this.startTime + ':00'
      var end = this.endDate + 'T' + this.endTime + ':00'

      if (asTimeInMilliseconds(end) <= asTimeInMilliseconds(start)) {
        this.error = 'Start date must be less than end date'
      }

      if (this.error !== '') {
        return
      }

      var mashup = {
        id: uuidv4(),
        loggerPointId: this.$route.params.id,
        startTime: this.startDate + 'T' + this.startTime + ':00',
        endTime: this.endDate + 'T' + this.endTime + ':00',
        sensorType: this.selectedSensor,
        status: 'new'
      }
      this.mashups.push(mashup)
      this.added_mashups.push(mashup)
    },
    removeMashup: function (mashup) {
      mashup.status = 'delete'
      this.mashups.splice(this.mashups.indexOf(mashup), 1)
      this.deleted_mashups.push(mashup)
    },
    getOptionNameFromValue: function (value) {
      var retText = ''
      this.options.forEach(element => {
        if (element.value === value) {
          retText = element.text
        }
      })
      return retText
    },
    is_odd_row: function (mashup) {
      return this.mashups.indexOf(mashup) % 2
    },
    getOddClass: function (mashup) {
      return this.is_odd_row(mashup) ? 'report_odd_row' : 'report_row'
    },
    dateToPublicString: function (date) {
      return toPublicString(date)
    },
    handleOk: function (evt) {
      evt.preventDefault()
      if (this.added_mashups.length > 0) {
        this.addMashupPeriods({ data: this.added_mashups })
        this.added_mashups = []
      }
      if (this.deleted_mashups.length > 0) {
        this.deleteMashupPeriods({ data: this.deleted_mashups })
        this.deleted_mashups = []
      }
      this.$refs.mashup_modal.hide()
      this.reloadMashupPeriods({ loggerId: this.$route.params.id })
    },
    resetModal: function () {
      this.startTime = null
      this.startDate = null
      this.endTime = null
      this.endDate = null
      this.error = ''
      this.selectedSensor = null
      this.mashups = []
      this.deleted_mashups = []
      this.added_mashups = []

      if (this.mashup_status === SUCCESS) {
        this.mashups = this.mashup_periods.slice()
      } else {
        this.reloadMashupPeriods({ loggerId: this.$route.params.id })
      }
    }
  },
  watch: {
    isLoaded: function () {
      if (this.mashup_status === SUCCESS) {
        this.mashups = this.mashup_periods.slice()
      }
    }
  }
}
</script>
