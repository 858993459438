export function toUrlDateString (date) {
  var dateToS = new Date(date)

  return dateToS.getUTCFullYear() + '-' + ('00' + (dateToS.getUTCMonth() + 1)).slice(-2) + '-' + ('00' + dateToS.getUTCDate()).slice(-2) + 'T' + ('00' + dateToS.getUTCHours()).slice(-2) + ':' + ('00' + dateToS.getUTCMinutes()).slice(-2)
}

export function toPublicString (dateAsString) {
  var matches = splitDateAsString(dateAsString)
  matches.splice(0, 1)
  return (matches[2] + '-' + matches[1] + '-' + matches[0] + ' kl. ' + matches[3] + ':' + matches[4])
}

export function parseDateAsSensor (dateAsString) {
  if (dateAsString == null || dateAsString === '') {
    return ''
  }
  var matches = splitDateAsString(dateAsString)
  matches.splice(0, 1)
  return matches[2] + '.' + matches[1] + '.' + matches[0].toString().substring(2, 4)// + ' kl. ' + matches[3] + ':' + matches[4]
}

export function toSensorDateString (date) {
  if (date) {
    return ('00' + date.getDate()).slice(-2) + '.' + ('00' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear().toString().slice(-2)
  }
  return ''
}

export function asTimeInMilliseconds (dateAsString) {
  return convertInternalDateToMilliseconds(parseSensorDateStringToDate(dateAsString))
}

function convertInternalDateToMilliseconds (internalDate) {
  return Date.UTC(
    internalDate.getFullYear(), internalDate.getMonth(), internalDate.getDate(), internalDate.getHours(), internalDate.getMinutes(), internalDate.getSeconds()
  )
}

export function parseSensorDateStringToDate (dateAsString) {
  var matches = splitDateAsString(dateAsString)
  matches.splice(0, 1)
  matches = matches.map(match => parseInt(match))
  return new Date(matches[0], matches[1] - 1, matches[2], matches[3], matches[4], matches[5])
}

export function getMonthName (index) {
  var monthNames = ['Jan', 'Febr', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'Aug', 'Sept', 'Okt', 'Nov', 'Des']
  return monthNames[index - 1]
}

export function parseDateWithoutTimeStringToDate (dateAsString) {
  var matches = splitDateWithoutTimeAsString(dateAsString)
  matches.splice(0, 1)
  matches = matches.map(match => parseInt(match))
  return Date.UTC(matches[0], matches[1] - 1, matches[2])
}

function splitDateAsString (dateAsString) {
  var dateExpression = /^(\d{4})-(0?[1-9]|1[0-2])-(0?[1-9]|[12][0-9]|3[01])[T\s](0?[0-9]|1[0-9]|2[0-3]):([0-9]|[0-5][0-9]):([0-9]|[0-5][0-9])(?:\.(\d{1,7}))?Z?$/
  var matches = dateExpression.exec(dateAsString)
  if (!matches || matches.length < 7) {
    throw new Error('' + dateAsString + ' doesn\'t follow pattern expected (YYYY-MM-DDTHH:mm:ss[.fffffff][Z])')
  }
  return matches
}

function splitDateWithoutTimeAsString (dateAsString) {
  var dateExpression = /^(\d{4})-(0?[1-9]|1[0-2])-(0?[1-9]|[12][0-9]|3[01])$/
  var matches = dateExpression.exec(dateAsString)
  if (!matches || matches.length < 3) {
    throw new Error('' + dateAsString + ' doesn\'t follow pattern expected (YYYY-MM-DD)')
  }
  return matches
}
