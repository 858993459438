import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import logger from './modules/logger'
import measurements from './modules/measurements'
import overflowreports from './modules/overflowreports'
import report from './modules/report'
import mashup from './modules/mashup'
import fileFormats from './modules/fileFormats'
import page from './modules/page'
import createPersist from 'vuex-localstorage'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    logger,
    measurements,
    overflowreports,
    report,
    mashup,
    fileFormats,
    page
  },
  plugins: [createPersist({
    namespace: 'namespace-for-state',
    expires: 30 * 60 * 1e3
  })],
  strict: process.env.NODE_ENV !== 'production'
})
