<template>
  <div>
    <LoggerList
      logger_type="rain"
      title="Nedbør"
      icon_marker_letter="R"
    ></LoggerList>
  </div>
</template>

<script>
import LoggerList from '../index'
export default {
  name: 'rain-list-form',
  components: {
    LoggerList
  }
}
</script>
