import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/login/index'
import Flow from '@/components/logger-info/flow/index'
import FlowDetails from '@/components/logger-info/flow/details'
import Level from '@/components/logger-info/level/index'
import LevelDetails from '@/components/logger-info/level/details'
import Rain from '@/components/logger-info/rain/index'
import RainDetails from '@/components/logger-info/rain/details'
import Weir from '@/components/overflow/index'
import Report from '@/components/overflow/report/index'
import Download from '@/components/logger-info/download/index'
import WeirDetails from '@/components/overflow/details'
import WeirPdfViewer from '@/components/overflow/pdfviewer'
import Home from '@/components/home/index'
import store from '../store'
import { LEVEL, FLOW, WEIR, RAIN } from '../utils/logger_consts'

Vue.use(Router)

const ifNotAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    window.localStorage.clear()
  }
  next()
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    if ((to.meta !== undefined && to.meta.resource !== undefined && !store.getters.hasResource(to.meta.resource))) {
      next('/')
    }
    next()
  } else {
    next('/login')
  }
}

export default new Router({
  mode: 'history',
  routes: [
    { path: '/login', component: Login, beforeEnter: ifNotAuthenticated },
    { name: 'main', path: '/', component: Home, beforeEnter: ifAuthenticated },
    { name: 'rain', path: '/rain', component: Rain, beforeEnter: ifAuthenticated, meta: { resource: RAIN } },
    { name: 'rainDownload', path: '/rain/download', component: Download, beforeEnter: ifAuthenticated, meta: { resource: RAIN } },
    { name: 'rainDetails', path: '/rain/:id', component: RainDetails, beforeEnter: ifAuthenticated, meta: { resource: RAIN } },
    { name: 'flow', path: '/flow', component: Flow, beforeEnter: ifAuthenticated, meta: { resource: FLOW } },
    { name: 'flowDownload', path: '/flow/download', component: Download, beforeEnter: ifAuthenticated, meta: { resource: FLOW } },
    { name: 'flowDetails', path: '/flow/:id', component: FlowDetails, beforeEnter: ifAuthenticated, meta: { resource: FLOW } },
    { name: 'level', path: '/level', component: Level, beforeEnter: ifAuthenticated, meta: { resource: LEVEL } },
    { name: 'levelDownload', path: '/level/download', component: Download, beforeEnter: ifAuthenticated, meta: { resource: LEVEL } },
    { name: 'levelDetails', path: '/level/:id', component: LevelDetails, beforeEnter: ifAuthenticated, meta: { resource: LEVEL } },
    { name: 'weir', path: '/weir', component: Weir, beforeEnter: ifAuthenticated, meta: { resource: WEIR } },
    { name: 'weirReport', path: '/weir/report', component: Report, beforeEnter: ifAuthenticated, meta: { resource: WEIR } },
    { name: 'weirDetails', path: '/weir/:id', component: WeirDetails, beforeEnter: ifAuthenticated, meta: { resource: WEIR } },
    { name: 'weirPdfViewer', path: '/weirpdfviewer/:id', component: WeirPdfViewer, beforeEnter: ifAuthenticated, meta: { resource: WEIR } }
  ]
})
