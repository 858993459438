<template>
  <div>
    <div v-if="isAuthenticated && hasWeirResource">
      <Weir />
    </div>
    <div v-if="isAuthenticated && hasLevelResource && !hasWeirResource">
      <Level />
    </div>
    <div v-if="isAuthenticated && hasFlowResource && !hasLevelResource && !hasWeirResource">
      <Flow />
    </div>
    <div
      v-if="
        isAuthenticated &&
          hasRainResource &&
          !hasFlowResource &&
          !hasLevelResource &&
          !hasWeirResource
      "
    >
      <Rain />
    </div>
    <div v-if="showWelcome">
      <h1>Welcome to Rosie Online!</h1>
      <h2>You don't have access to any parts of Rosie Online right now!</h2>
      <h2>Ask your manager to add them!</h2>
    </div>
  </div>
</template>

<style>
.home {
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>

<script>
import Weir from '@/components/overflow/index'
import Level from '@/components/logger-info/level/index'
import Flow from '@/components/logger-info/flow/index'
import Rain from '@/components/logger-info/rain/index'
import { mapGetters } from 'vuex'

import { LOADING } from '../../store/actions/backend'
import { LEVEL, FLOW, WEIR, RAIN } from '../../utils/logger_consts'

export default {
  name: 'rosie-home',
  components: {
    Weir,
    Level,
    Flow,
    Rain
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'authStatus', 'hasResource']),

    showWelcome: function () {
      return (
        (!this.isAuthenticated && this.authStatus !== LOADING) ||
        (!this.hasRainResource && !this.hasFlowResource && !this.hasLevelResource && !this.hasWeirResource)
      )
    },

    hasLevelResource: function () {
      return this.hasResource(LEVEL)
    },

    hasWeirResource: function () {
      return this.hasResource(WEIR)
    },

    hasFlowResource: function () {
      return this.hasResource(FLOW)
    },

    hasRainResource: function () {
      return this.hasResource(RAIN)
    }
  }
}
</script>
