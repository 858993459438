<template>
  <LoggerInfoDetails logger_type="rain"></LoggerInfoDetails>
</template>

<script>
import LoggerInfoDetails from '../details'

export default {
  name: 'rain_details',
  components: { LoggerInfoDetails }
}
</script>
