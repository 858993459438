<template>
  <b-container fluid>
    <b-row>
      <b-col md="6" class="text-left">
        <b-btn
          variant="outline-secondary"
          :size="'sm'"
          style="margin: auto;"
          @click="goBack()"
          ><font-awesome-icon icon="arrow-left" />&nbsp;Tilbake</b-btn
        >
      </b-col>
      <b-col md="6" class="text-right">
        <b-btn
          variant="outline-secondary"
          v-if="showModel3D"
          :size="'sm'"
          style="margin: auto;"
          @click="handle3DModelclick()"
          ><font-awesome-icon icon="cube" />&nbsp;3d model</b-btn
        >
        <b-btn
          variant="outline-secondary"
          :size="'sm'"
          v-bind:disabled="!isLoaded"
          style="margin: auto;"
          class="mx-1"
          @click="handleReportClick(true)"
          ><font-awesome-icon icon="file-pdf" />&nbsp;Rapport.pdf</b-btn
        >
        <b-btn
          variant="outline-secondary"
          :size="'sm'"
          v-bind:disabled="!isLoaded"
          style="margin: auto;"
          @click="handleReportClick(false)"
          ><font-awesome-icon icon="file-excel" />&nbsp;Rapport.csv</b-btn
        >
      </b-col>
    </b-row>
    <b-container fluid>
      <b-row>
        <b-col md="12" class="text-center">
          <h2>{{ loggerName }}</h2>
          <h5>{{ lastTime }}</h5>
        </b-col>
      </b-row>
    </b-container>
    <chart />
    <Mashup />
  </b-container>
</template>

<script>
import { SUCCESS } from '../../store/actions/backend.js'
import { REQUEST_REPORT, REQUEST_OVERFLOW_DATA } from '../../store/action-types'
import Chart from './chart'
import { toPublicString, asTimeInMilliseconds } from '../../utils/Date.js'
import Mashup from './mashup'
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters(['measurements', 'measurement_status']),

    lastTimeInMilliseconds () {
      return this.measurements && this.measurements.lastLevel
        ? asTimeInMilliseconds(this.measurements.lastLevel.t)
        : 0
    },

    lastTime () {
      return this.measurements && this.measurements.lastLevel
        ? 'Sist nedlastet ' + toPublicString(this.measurements.lastLevel.t)
        : ''
    },

    loggerName () {
      return this.measurements ? this.measurements.logger.name : ''
    },

    showModel3D () {
      return this.measurements && this.measurements.logger.showModel3D ? this.measurements.logger.showModel3D : false
    },

    isLoaded () {
      return this.measurement_status === SUCCESS
    }
  },
  name: 'weir_details',
  components: { Chart, Mashup },
  methods: {
    ...mapActions({
      loadReport: REQUEST_REPORT,
      loadData: REQUEST_OVERFLOW_DATA
    }),

    goBack: function () {
      this.$router.push('/weir')
    },

    handleReportClick: function (isPdf) {
      this.loadReport({
        loggerIds: [this.measurements.logger.id],
        isPdf: isPdf
      })
    },
    handle3DModelclick: function() {
      window.open(this.measurements.logger.model3DUrl, '_blank', 'noreferrer');
    }
  },

  created () {
    this.loadData({ loggerId: this.$route.params.id, force: true })
  }
}
</script>
