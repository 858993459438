export const LEVEL = 'level'
export const OVERFLOW_LEVEL = 'overflow_level'
export const LEVEL_MASHUP = 'level_mashup'
export const FLOW = 'flow'
export const FLOW_MASHUP = 'flow_mashup'
export const OVERFLOW_FLOW = 'overflow_flow'
export const OVERFLOW_FLOW_MASHUP = 'overflow_flow_mashup'
export const VELOCITY = 'velocity'
export const RAIN = 'rain'
export const ASSOCIATED_RAIN = 'associated_rain'
export const WEIR = 'weir'
export const REPORT = 'weirReport'
export const THRESHOLD = 'threshold'
export const IKKE_NAVN = '&lt;ikke navn&gt;'
