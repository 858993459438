<template>
  <div>
    <LoggerList
      logger_type="level"
      title="Nivå"
      icon_marker_letter="L"
    ></LoggerList>
  </div>
</template>

<script>
import LoggerList from '../index'
export default {
  name: 'level-list-form',
  components: {
    LoggerList
  }
}
</script>
