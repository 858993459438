<template>
  <b-container fluid>
    <b-container fluid v-if="isLoaded">
      <div v-if="isError">
        <b-alert
          show
          variant="danger"
          style="margin: auto;width: 500px;"
          class="center-text"
          >Ugyldig brukernavn eller passord.</b-alert
        >
        <br />
      </div>
      <b-card style="margin: auto;width: 500px;" class="center-text">
        <b-form-input
          v-model="username"
          :state="usernameState"
          @keyup.enter.native="handleLoginClick()"
          id="input-login"
          type="text"
          placeholder="Brukernavn"
          autofocus
        />
        <b-form-invalid-feedback
          v-if="!$v.username.required && loginClicked"
          id="inputUsernameFeedback"
          >Brukernavn er obligatorisk</b-form-invalid-feedback
        >
        <br />
        <b-form-input
          v-model="password"
          :state="passwordState"
          @keyup.enter.native="handleLoginClick()"
          id="input-password"
          type="password"
          placeholder="Passord"
        />
        <b-form-invalid-feedback
          v-if="!$v.password.required && loginClicked"
          id="inputUsernameFeedback"
          >Passord er obligatorisk</b-form-invalid-feedback
        >
        <br />
        <b-button
          variant="secondary"
          style="margin: auto;"
          @click="handleLoginClick()"
          >Logg inn&nbsp;&nbsp;<font-awesome-icon icon="sign-in-alt"
        /></b-button>
      </b-card>
    </b-container>
    <b-container fluid v-if="!isLoaded">
      <font-awesome-icon icon="spinner" size="4x" spin />
    </b-container>
  </b-container>
</template>

<script>
import { LOADING, ERROR, SUCCESS } from '../../store/actions/backend.js'
import { AUTHORIZE } from '../../store/action-types'
import { required } from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'login-form',
  computed: {
    ...mapGetters(['authStatus', 'refreshStatus']),

    isLoaded () {
      return this.authStatus === '' || this.authStatus !== LOADING
    },

    isError () {
      return this.authStatus === ERROR && this.refreshStatus !== ERROR
    },

    usernameState () {
      return this.loginClicked && this.username.length <= 0 ? false : null
    },

    passwordState () {
      return this.loginClicked && this.password.length <= 0 ? false : null
    }
  },
  watch: {
    isLoaded: function () {
      if (this.authStatus === SUCCESS) {
        this.$router.push('/')
      }
    }
  },

  data () {
    return {
      username: '',
      password: '',
      loginClicked: false
    }
  },

  validations: {
    username: {
      required
    },
    password: {
      required
    }
  },

  methods: {
    ...mapActions({
      authorize: AUTHORIZE
    }),

    handleLoginClick: function () {
      const { username, password } = this
      this.loginClicked = true
      if (username && username.length > 0 && password && password.length > 0) {
        this.authorize({ username, password })
      }
    }
  }
}
</script>
