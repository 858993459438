<template>
  <div>
    <b-container id="container-list" fluid>
      <b-row v-if="isLoaded">
        <b-col md="3 pr-2">
          <b-container fluid="md">
            <b-row class="text-left align-items-center">
              <b-col md="auto mx-0 px-0">
                <h3>{{ this.title }}</h3>
              </b-col>
              <b-col class="auto text-right mx-0 px-0">
                <b-form-radio-group
                  class="pr-2"
                  size="sm"
                  buttons
                  button-variant="outline-secondary"
                  v-model="selected_active_option"
                  :options="active_options"
                ></b-form-radio-group>
                <b-btn
                  @click="handleFilterClick()"
                  variant="outline-secondary"
                  size="sm"
                  ><font-awesome-icon icon="search" size="1x" />
                  <span class="showOnLargeScreen">&nbsp;Søk</span>
                </b-btn>
              </b-col>
            </b-row>
            <b-row v-if="filterShown">
              <b-col md="12 my-2 mx-0 px-0">
                <b-form-input
                  v-if="filterShown"
                  v-model="filterSearchText"
                  placeholder="Søk..."
                  type="search"
                  size="sm"
                />
              </b-col>
            </b-row>
            <b-row class="logger_row_header text-left">
              <b-col md="6 text-left mx-0 px-0">
                SID / Navn
              </b-col>
              <b-col md="4 text-center mx-0 px-0">
                Siste målt
              </b-col>
              <b-col md="2 text-center mx-0 px-0">
                Siste nivå, m
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12 mx-0 px-0">
                <PerfectScrollbar :settings="scroll_settings" class="wrapper">
                  <b-container v-if="showActiveLoggers">
                    <b-row class="logger_row_type_header text-left">
                      <b-col md="6 text-left mx-0 px-0">
                        I drift&nbsp;&nbsp;<em>({{this.filteredActiveLoggerInfos.length}} av {{this.activeLoggerInfos.length}})</em>
                      </b-col>
                    </b-row>
                    <b-row v-if="this.filteredActiveLoggerInfos.length <= 0" class="logger_row">
                      <b-col md="12 text-center mx-0 px-0 text-secondary font-italic" >
                        Ingenting å vise
                      </b-col>
                    </b-row>
                    <b-row
                      :key="loggerInfo.logger.logger.id"
                      v-for="loggerInfo in filteredActiveLoggerInfos"
                      class="logger_row text-left"
                    >
                      <b-col md="6 text-left mx-0 px-0">
                        <a :href="getLoggerUrl(loggerInfo)" class="text-dark" v-html="getLoggerName(loggerInfo)" />
                      </b-col>
                      <b-col md="4 text-center mx-0 px-0">{{
                        toFormattedLastMeasuredTime(loggerInfo)
                      }}</b-col>
                      <b-col md="2 text-center mx-0 px-0">{{
                        getLastMeasuredValue(loggerInfo)
                      }}</b-col>
                    </b-row>
                  </b-container>
                  <b-container v-if="showHistoricalLoggers">
                    <b-row class="logger_row_type_header text-left">
                      <b-col md="6 text-left mx-0 px-0">
                        Historiske&nbsp;&nbsp;<em>({{this.filteredHistoricalLoggerInfos.length}} av {{this.historicalLoggerInfos.length}})</em>
                      </b-col>
                    </b-row>
                    <b-row v-if="this.filteredHistoricalLoggerInfos.length <= 0" class="logger_row">
                      <b-col md="12 text-center mx-0 px-0 text-secondary font-italic" >
                        Ingenting å vise
                      </b-col>
                    </b-row>
                    <b-row
                      :key="loggerInfo.logger.logger.id"
                      v-for="loggerInfo in filteredHistoricalLoggerInfos"
                      class="logger_row_historical text-left"
                    >
                      <b-col md="6 text-left mx-0 px-0">
                        <a :href="getLoggerUrl(loggerInfo)" class="text-dark" v-html="getLoggerName(loggerInfo)" />
                      </b-col>
                      <b-col md="4 text-center mx-0 px-0">{{
                        toFormattedLastMeasuredTime(loggerInfo)
                      }}</b-col>
                      <b-col md="2 text-center mx-0 px-0">{{
                        getLastMeasuredValue(loggerInfo)
                      }}</b-col>
                    </b-row>
                  </b-container>
                </PerfectScrollbar>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
        <b-col md="9" class="text-center" style="padding-left: 5px">
          <l-map
            ref="loggerMap"
            :zoom="this.pageSettings.zoom"
            :center="this.pageSettings.center"
            @update:zoom="zoomUpdated"
            @update:center="centerUpdated"
            @ready="refreshMap"
            style="height: 90vh; visibility: none;"
          >
            <l-tile-layer :url="url" :attribution="attribution" />
            <l-marker
              :lat-lng="[
                loggerInfo.logger.logger.geoY,
                loggerInfo.logger.logger.geoX
              ]"
              :key="loggerInfo.logger.logger.id"
              v-for="loggerInfo in filteredActiveLoggerInfos"
            >
              <l-icon>
                <table>
                  <tr>
                    <td>
                      <img v-if="icon_marker_letter === 'R'" src="../../assets/images/rain.png" style="width: 24px;height: 24px;"/>
                      <span
                        v-if="icon_marker_letter !== 'R'"
                        class="border border-secondary bg-light text-secondary rounded p-0"
                        style="border-width:1px !important;"
                        >&nbsp;{{ icon_marker_letter }}&nbsp;</span>
                    </td>
                    <td>
                      <strong class="align-baseline">{{
                        getName(loggerInfo)
                      }}</strong>
                    </td>
                  </tr>
                </table>
              </l-icon>
              <l-popup>
                <a :href="getLoggerUrl(loggerInfo)" v-html="getLoggerName(loggerInfo)" />
                <div style="padding-top: 5px">
                  Siste målt: {{ toFormattedLastMeasuredTime(loggerInfo) }}
                  <br />
                  Siste nivå, m: {{ getLastMeasuredValue(loggerInfo) }}
                </div>
              </l-popup>
            </l-marker>
            <l-marker
              :lat-lng="[
                loggerInfo.logger.logger.geoY,
                loggerInfo.logger.logger.geoX
              ]"
              :key="loggerInfo.logger.logger.id"
              v-for="loggerInfo in filteredHistoricalLoggerInfos"
            >
              <l-icon>
                <table>
                  <td>
                      <img v-if="icon_marker_letter === 'R'" src="../../assets/images/rain_history.png" style="width: 24px;height: 24px;"/>
                      <span
                        v-if="icon_marker_letter !== 'R'"
                      class="border border-secondary rounded p-0 logger_historical_icon"
                      >&nbsp;{{ icon_marker_letter }}&nbsp;</span
                    >
                  </td>
                  <td>
                    <strong class="align-baseline">{{
                      getName(loggerInfo)
                    }}</strong>
                  </td>
                </table>
              </l-icon>
              <l-popup>
                <a
                  :href="getLoggerUrl(loggerInfo)"
                  >{{ getLoggerName(loggerInfo) }}</a
                >
                <div style="padding-top: 5px">
                  Siste målt: {{ toFormattedLastMeasuredTime(loggerInfo) }}
                  <br />
                  Siste nivå, m: {{ getLastMeasuredValue(loggerInfo) }}
                </div>
              </l-popup>
            </l-marker>

            <l-control-scale
              :position="'bottomleft'"
              :metric="true"
              :imperial="true"
            />
          </l-map>
        </b-col>
      </b-row>
      <b-row v-if="!isLoaded && !isError">
        <b-col md="12" class="text-center"
          ><font-awesome-icon icon="spinner" size="4x" spin
        /></b-col>
      </b-row>
      <b-row v-if="isError">
        <b-col md="4" class="text-right"
          ><font-awesome-icon icon="exclamation-circle" size="4x"
        /></b-col>
        <b-col md="4" class="text-left" style="padding-top:20px"
          >Noe gikk galt</b-col
        >
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { SUCCESS, ERROR } from '../../store/actions/backend.js'
import { REQUEST_LOGGER_INFO, UPDATE_PAGE_SETTINGS } from '../../store/action-types'
import {
  LMap,
  LPopup,
  LTileLayer,
  LControlScale,
  LIcon,
  LMarker
} from 'vue2-leaflet'
import { mapGetters, mapActions } from 'vuex'
import PerfectScrollbar from 'vue-perfect-scrollbar'
import {
  toSensorDateString,
  parseSensorDateStringToDate
} from '../../utils/Date.js'
import { IKKE_NAVN } from '../../utils/logger_consts.js'
import L from 'leaflet'

delete L.Icon.Default.prototype._getIconUrl

const OPTION_ALL_LOGGERS = 'all'
const OPTION_ACTIVE_LOGGERS = 'active'
const OPTION_HISTORICAL_LOGGERS = 'historical'

export default {
  name: 'logger-list-form',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup,
    LControlScale,
    PerfectScrollbar
  },
  props: ['logger_type', 'title', 'icon_marker_letter'],
  methods: {
    ...mapActions({
      loadInfo: REQUEST_LOGGER_INFO,
      updatePageSettings: UPDATE_PAGE_SETTINGS
    }),
    refreshMap: function() {
      let self = this
      setTimeout( function () {
        self.$refs.loggerMap.mapObject.invalidateSize()
      }, 1000)
    },

    toFormattedLastMeasuredTime: function (loggerInfo) {
      if (loggerInfo.logger.timeSeries.length > 0) {
        var lastDate = parseSensorDateStringToDate(loggerInfo.logger.timeSeries[0].latestData.t)
        return toSensorDateString(lastDate)
      }
    },
    getLoggerUrl: function (loggerInfo) {
      return '/' + this.logger_type + '/' + loggerInfo.logger.logger.id
    },
    getLoggerName: function (loggerInfo) {
      return loggerInfo.logger.logger.name == null || loggerInfo.logger.logger.name.length === 0 ? `<i>` + IKKE_NAVN + `</i>` : loggerInfo.logger.logger.name
    },
    getLastMeasuredValue: function (loggerInfo) {
      if (loggerInfo.logger.timeSeries.length > 0) {
        return loggerInfo.logger.timeSeries[0].latestData.v
      }

      return 'n/a'
    },

    getName: function (loggerInfo) {
      return loggerInfo.checked ? loggerInfo.logger.logger.name : ''
    },

    getSize: function (loggerInfo) {
      return loggerInfo.checked ? '2x' : 'sm'
    },

    getColor: function (loggerInfo) {
      return loggerInfo.checked ? '#dc3545' : '#6c757d'
    },

    handleFilterClick: function () {
      this.filterShown = !this.filterShown
    },

    zoomUpdated: function (zoom) {
      this.pageSettings.zoom = zoom
      this.updatePageSettings(this.pageSettings)
    },

    centerUpdated: function (center) {
      this.pageSettings.center = center
      this.updatePageSettings(this.pageSettings)
    }
  },

  computed: {
    ...mapGetters(['loggerInfos', 'logger_status', 'userExtent', 'pagesSettings']),

    filteredActiveLoggerInfos () {
      const loggers = this.activeLoggerInfos
      if (loggers && loggers.length > 0) {
        let searchText = this.filterSearchText.toLowerCase()
        return loggers.filter(l => l.logger.logger.name.toLowerCase().includes(searchText))
      }

      return loggers
    },

    activeLoggerInfos () {
      if (this.showActiveLoggers) {
        return this.currentLoggerInfos.filter(l => l.logger.logger.isActive)
      }

      return []
    },

    filteredHistoricalLoggerInfos () {
      const loggers = this.historicalLoggerInfos
      if (loggers && loggers.length > 0) {
        let searchText = this.filterSearchText.toLowerCase()
        return loggers.filter(l => l.logger.logger.name.toLowerCase().includes(searchText))
      }

      return loggers
    },

    historicalLoggerInfos () {
      if (this.showHistoricalLoggers) {
        return this.currentLoggerInfos.filter(l => !l.logger.logger.isActive)
      }

      return []
    },

    currentLoggerInfos () {
      return this.loggerInfos.filter(l => l.logger.logger.type.toLowerCase().includes(this.logger_type))
    },

    isLoaded () {
      return this.logger_status === SUCCESS || this.logger_status === ''
    },

    isError () {
      return this.logger_status === ERROR
    },

    showActiveLoggers () {
      return this.selected_active_option === OPTION_ACTIVE_LOGGERS || this.selected_active_option === OPTION_ALL_LOGGERS
    },

    showHistoricalLoggers () {
      return this.selected_active_option === OPTION_HISTORICAL_LOGGERS || this.selected_active_option === OPTION_ALL_LOGGERS
    }
  },

  created () {
    const numberOfLastYears = 10
    const currentYear = new Date().getFullYear()
    let yearStored = this.year
    if (isNaN(yearStored) || (yearStored > currentYear && yearStored < currentYear - numberOfLastYears)) {
      yearStored = currentYear
    }

    const yearInfoList = []
    for (let year = currentYear; year >= currentYear - numberOfLastYears; year--) {
      yearInfoList.push({ value: year, text: year + '' })
    }

    this.yearOptions = yearInfoList
    this.yearSelected = yearStored

    if (this.currentLoggerInfos.length === 0) {
      this.loadInfo(this.logger_type)
    }

    if (this.pagesSettings.length > 0 && this.pagesSettings.find(ps => ps.page === this.logger_type)) {
      var foundSettings = this.pagesSettings.find(ps => ps.page === this.logger_type)
      this.pageSettings.center = foundSettings.center
      this.pageSettings.zoom = foundSettings.zoom
      this.selected_active_option = foundSettings.selected_active_option
      this.filterShown = foundSettings.filterShown
      this.filterSearchText = foundSettings.filterSearchText ? foundSettings.filterSearchText : ''
    } else {
      this.pageSettings.center = {
        lat: (this.userExtent.minY + this.userExtent.maxY) / 2,
        lng: (this.userExtent.minX + this.userExtent.maxX) / 2
      }
      this.pageSettings.zoom = 12
      this.pageSettings.page = this.logger_type
      this.pageSettings.selected_active_option = this.selected_active_option
      this.pageSettings.filterShown = this.filterShown
      this.pageSettings.filterSearchText = this.filterSearchText

      this.updatePageSettings(this.pageSettings)
    }
  },
  watch: {
    selected_active_option: function () {
      this.pageSettings.selected_active_option = this.selected_active_option
      this.updatePageSettings(this.pageSettings)
    },
    filterShown: function () {
      this.pageSettings.filterShown = this.filterShown
      this.updatePageSettings(this.pageSettings)
    },
    filterSearchText: function () {
      this.pageSettings.filterSearchText = this.filterSearchText
      this.updatePageSettings(this.pageSettings)
    }
  },

  data () {
    return {
      pageSettings: {page: this.logger_type, zoom: 12, center: {}, selected_active_option: OPTION_ACTIVE_LOGGERS, filterShown: false, filterSearchText: ''},
      allChecked: false,
      selected_active_option: OPTION_ACTIVE_LOGGERS,
      active_options: [
        { html: 'I&nbsp;drift', value: OPTION_ACTIVE_LOGGERS },
        { text: 'Historiske', value: OPTION_HISTORICAL_LOGGERS },
        { text: 'Alle', value: OPTION_ALL_LOGGERS }
      ],
      yearSelected: 0,
      yearOptions: [],
      selectedLoggers: [],
      filterSearchText: '',
      filterShown: false,
      url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
      scroll_settings: {
        suppressScrollX: true
      },
      sliderOptions: {
        eventType: 'auto',
        width: 'auto',
        height: 6,
        dotSize: 16,
        dotHeight: null,
        dotWidth: null,
        min: 0,
        max: 100,
        interval: 1,
        show: true,
        speed: 0.5,
        disabled: false,
        piecewise: false,
        piecewiseStyle: null,
        piecewiseLabel: false,
        tooltip: 'always',
        tooltipDir: 'top',
        reverse: false,
        data: null,
        clickable: true,
        realTime: false,
        lazy: false,
        formatter: null,
        bgStyle: null,
        sliderStyle: null,
        processStyle: {
          backgroundColor: '#28a745'
        },
        tooltipStyle: null,
        piecewiseActiveStyle: null
      }
    }
  }
}
</script>
