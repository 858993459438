import {
  LOGGER_FILE_FORMATS_REQUEST_URL,
  ERROR,
  SUCCESS,
  LOADING,
  UNAUTHORIZED
} from '../actions/backend'
import {
  LOGGER_FILE_FORMATS_REQUEST,
  LOGGER_FILE_FORMATS_REQUEST_SUCCESS,
  LOGGER_FILE_FORMATS_REQUEST_ERROR
} from '../mutation-types'
import {
  REQUEST_REFRESH_TOKEN,
  REQUEST_LOGGER_FILE_FORMATS
} from '../action-types'
import { AUTH_TOKEN_KEY } from '../storage-keys'
import axios from 'axios'

const state = {
  fileFormatsStatus: '',
  fileFormats: []
}

const getters = {
  fileFormatsStatus: state => state.fileFormatsStatus,
  fileFormats: state => state.fileFormats
}

const actions = {
  [REQUEST_LOGGER_FILE_FORMATS]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit(LOGGER_FILE_FORMATS_REQUEST)
      axios({
        url: LOGGER_FILE_FORMATS_REQUEST_URL,
        method: 'GET',
        type: 'json',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem(AUTH_TOKEN_KEY) || '',
          Accept: 'application/json, text/plain, */*',
          'Cache-Control': 'no-cache'
        }
      })
        .then(resp => {
          commit(LOGGER_FILE_FORMATS_REQUEST_SUCCESS, resp.data)
          resolve(resp)
        })
        .catch(err => {
          if (err.response != null && err.response.status === UNAUTHORIZED) {
            dispatch(REQUEST_REFRESH_TOKEN, {
              previousRequest: REQUEST_LOGGER_FILE_FORMATS
            })
          } else {
            commit(LOGGER_FILE_FORMATS_REQUEST_ERROR, err)
            reject(err)
          }
        })
    })
  }
}

const mutations = {
  [LOGGER_FILE_FORMATS_REQUEST]: state => {
    state.fileFormatsStatus = LOADING
  },
  [LOGGER_FILE_FORMATS_REQUEST_SUCCESS]: (state, data) => {
    state.fileFormatsStatus = SUCCESS
    state.fileFormats = []

    data.slice().forEach(element => state.fileFormats.push(element))
  },
  [LOGGER_FILE_FORMATS_REQUEST_ERROR]: state => {
    state.fileFormatsStatus = ERROR
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
