import {
  SENSOR_REQUEST_URL,
  LOGGER_INFO_REQUEST_URL,
  LOGGER_REQUEST_URL,
  CHECK_LOGGER_URL,
  UNCHECK_LOGGER_URL,
  OVERFLOW_REQUEST_URL,
  ASSOCIATED_PART,
  ERROR,
  SUCCESS,
  LOADING,
  OVERFLOW_REPORT_REQUEST_URL,
  PHOSPHOR_QUANTITY_URL,
  UNAUTHORIZED
} from '../actions/backend'
import {
  REQUEST_SENSOR_DATA,
  REQUEST_LOGGER_INFO,
  REQUEST_ASSOCIATED_LOGGER_INFO,
  REQUEST_REFRESH_TOKEN,
  REQUEST_OVERFLOW,
  REQUEST_OVERFLOW_STATUS,
  REQUEST_ALL_OVERFLOW_STATUSES,
  CLEAR_ALL_OVERFLOW_STATUSES,
  SET_YEAR,
  CHECK_LOGGER,
  UPDATE_WEIR_PHOSPHOR_QUANTITY
} from '../action-types'
import {
  LOGGER_INFO_REQUEST,
  LOGGER_INFO_REQUEST_SUCCESS,
  LOGGER_INFO_REQUEST_ERROR,
  ASSOCIATED_LOGGER_INFO_REQUEST,
  ASSOCIATED_LOGGER_INFO_REQUEST_SUCCESS,
  ASSOCIATED_LOGGER_INFO_REQUEST_ERROR,
  OVERFLOW_YEAR_REPORT_REQUEST,
  OVERFLOW_YEAR_REPORT_REQUEST_SUCCESS,
  OVERFLOW_YEAR_REPORT_REQUEST_ERROR,
  OVERFLOW_YEAR_REPORT_REQUEST_ATTEMPT_ERROR,
  OVERFLOW_YEAR_REPORT_CLEARING,
  LOGGER_CHECKING,
  OVERFLOW_REQUEST,
  OVERFLOW_REQUEST_SUCCESS,
  OVERFLOW_REQUEST_ERROR,
  OVERFLOW_ALL_STATUSES_CLEARING,
  OVERFLOW_ALL_STATUSES_REQUEST,
  OVERFLOW_STATUS_REQUEST,
  OVERFLOW_STATUS_REQUEST_SUCCESS,
  OVERFLOW_STATUS_REQUEST_ERROR,
  YEAR_ASSIGNMENT,
  LOGGER_CHECKING_SUCCESS,
  LOGGER_CHECKING_ERROR,
  WEIR_PHOSPHOR_QUANTITY_UPDATING,
  WEIR_PHOSPHOR_QUANTITY_UPDATING_SUCCESS,
  WEIR_PHOSPHOR_QUANTITY_UPDATING_ERROR,
  SENSOR_DATA_REQUEST,
  SENSOR_DATA_REQUEST_SUCCESS,
  SENSOR_DATA_REQUEST_ERROR
} from '../mutation-types'
import { AUTH_TOKEN_KEY } from '../storage-keys'
import axios from 'axios'

var cancelTokenSource

const state = {
  weirInfos: [],
  loggerInfos: [],
  status: '',
  logger_status: '',
  maxOverflowHours: 0,
  maxOverflowVolume: 0,
  maxOverflowEventCount: 0,
  maxOverflowPhosphorQuantity: 0,
  year: new Date().getFullYear(),
  year_report_status: '',
  request_count: 0,
  weirPhosphorQuantityUpdatingStatus: null,
  sensor_measurement_status: '',
  sensor_measurements: null
}

const getters = {
  weirInfos: state => state.weirInfos,
  status: state => state.status,
  loggerInfos: state => state.loggerInfos,
  logger_status: state => state.logger_status,
  weirInfoById: state => id => state.weirInfos.find(w => w.logger.id === Number(id)),
  maxOverflowHours: state => state.maxOverflowHours,
  maxOverflowVolume: state => state.maxOverflowVolume,
  maxOverflowEventCount: state => state.maxOverflowEventCount,
  maxOverflowPhosphorQuantity: state => state.maxOverflowPhosphorQuantity,
  year: state => state.year,
  year_report_status: state => state.year_report_status,
  weirPhosphorQuantityUpdatingStatus: state =>
    state.weirPhosphorQuantityUpdatingStatus,
  sensor_measurements: state => state.sensor_measurements,
  sensor_measurement_status: state => state.sensor_measurement_status
}

const actions = {
  [REQUEST_OVERFLOW]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit(OVERFLOW_REQUEST)
      axios({
        url: OVERFLOW_REQUEST_URL,
        method: 'GET',
        type: 'json',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem(AUTH_TOKEN_KEY) || '',
          Accept: 'application/json, text/plain, */*',
          'Cache-Control': 'no-cache'
        }
      })
        .then(resp => {
          commit(OVERFLOW_REQUEST_SUCCESS, resp.data)
          resolve(resp)
        })
        .catch(err => {
          if (err.response != null && err.response.status === UNAUTHORIZED) {
            dispatch(REQUEST_REFRESH_TOKEN, {
              previousRequest: REQUEST_OVERFLOW
            })
          } else {
            commit(OVERFLOW_REQUEST_ERROR, err)
          }
          reject(err)
        })
    })
  },
  [REQUEST_LOGGER_INFO]: ({ commit, dispatch }, type) => {
    return new Promise((resolve, reject) => {
      commit(LOGGER_INFO_REQUEST)
      axios({
        url: LOGGER_INFO_REQUEST_URL + type,
        method: 'GET',
        type: 'json',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem(AUTH_TOKEN_KEY) || '',
          Accept: 'application/json, text/plain, */*',
          'Cache-Control': 'no-cache'
        }
      })
        .then(resp => {
          commit(LOGGER_INFO_REQUEST_SUCCESS, {
            resp: resp,
            type: type
          })
          resolve(resp)
        })
        .catch(err => {
          if (err.response != null && err.response.status === UNAUTHORIZED) {
            dispatch(REQUEST_REFRESH_TOKEN, {
              previousRequest: REQUEST_LOGGER_INFO,
              param: type
            })
          } else {
            commit(LOGGER_INFO_REQUEST_ERROR, err)
          }
          reject(err)
        })
    })
  },
  [REQUEST_ASSOCIATED_LOGGER_INFO]: ({ commit, dispatch }, loggerId) => {
    return new Promise((resolve, reject) => {
      commit(ASSOCIATED_LOGGER_INFO_REQUEST, { loggerId: loggerId })
      axios({
        url: LOGGER_REQUEST_URL + loggerId + ASSOCIATED_PART,
        method: 'GET',
        type: 'json',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem(AUTH_TOKEN_KEY) || '',
          Accept: 'application/json, text/plain, */*',
          'Cache-Control': 'no-cache'
        }
      })
        .then(resp => {
          commit(ASSOCIATED_LOGGER_INFO_REQUEST_SUCCESS, {
            loggerId: loggerId,
            resp: resp
          })
          if (resp.data != null && resp.data.length > 0 && resp.data.find(el => el.logger.type.toLowerCase() === 'rain') !== null) {
            dispatch(REQUEST_LOGGER_INFO, 'rain')
          }
          resolve(resp)
        })
        .catch(err => {
          if (err.response != null && err.response.status === UNAUTHORIZED) {
            dispatch(REQUEST_REFRESH_TOKEN, {
              previousRequest: REQUEST_LOGGER_INFO,
              param: loggerId
            })
          } else {
            commit(ASSOCIATED_LOGGER_INFO_REQUEST_ERROR, { loggerId: loggerId })
          }
          reject(err)
        })
    })
  },
  [CLEAR_ALL_OVERFLOW_STATUSES]: ({ commit }) => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel(`canceled on ${CLEAR_ALL_OVERFLOW_STATUSES}`)
    }
    cancelTokenSource = undefined
    commit(OVERFLOW_ALL_STATUSES_CLEARING)
  },
  [REQUEST_ALL_OVERFLOW_STATUSES]: ({ state, commit, dispatch }, param) => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel(`canceled on ${REQUEST_ALL_OVERFLOW_STATUSES}`)
    }
    cancelTokenSource = undefined
    commit(OVERFLOW_ALL_STATUSES_REQUEST)
    if (Array.isArray(state.weirInfos) && state.weirInfos.length > 0) {
      cancelTokenSource = axios.CancelToken.source()
      const promises = state.weirInfos.map(weirInfo => {
        if (
          weirInfo.reports == null ||
          !weirInfo.reports.find(r => r.year === param.year)
        ) {
          dispatch(REQUEST_OVERFLOW_STATUS, {
            data: [weirInfo.logger.id],
            year: param.year,
            cancelToken: cancelTokenSource.token
          })
        } else {
          let overflow = weirInfo.reports.find(r => r.year === param.year)
          commit(OVERFLOW_STATUS_REQUEST_SUCCESS, [
            {
              overflow: overflow,
              logger: weirInfo.logger
            }
          ])
        }
      })
      Promise.all(promises).then(() => {
        cancelTokenSource = undefined
      })
    }
  },
  [REQUEST_OVERFLOW_STATUS]: ({ commit, dispatch }, param) => {
    return new Promise((resolve, reject) => {
      commit(OVERFLOW_STATUS_REQUEST, param)
      axios({
        url: `${OVERFLOW_REPORT_REQUEST_URL}?findLastTime=true&year=${encodeURIComponent(
          param.year
        )}`,
        method: 'post',
        type: 'json',
        data: param.data,
        cancelToken: param.cancelToken,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem(AUTH_TOKEN_KEY) || '',
          Accept: 'application/json, text/plain, */*',
          'Cache-Control': 'no-cache'
        }
      })
        .then(resp => {
          commit(OVERFLOW_STATUS_REQUEST_SUCCESS, resp.data)
          resolve(resp)
        })
        .catch(err => {
          if (axios.isCancel(err)) {
            console.info(
              "Weir overflow report request canceled with the following message: '%s'",
              err.message
            )
          } else {
            if (err.response != null && err.response.status === UNAUTHORIZED) {
              dispatch(REQUEST_REFRESH_TOKEN, {
                previousRequest: REQUEST_OVERFLOW_STATUS,
                param: param
              })
            } else {
              commit(OVERFLOW_STATUS_REQUEST_ERROR, param)
            }
            reject(err)
          }
        })
    })
  },
  [SET_YEAR]: ({ commit }, { year }) => {
    commit(YEAR_ASSIGNMENT, year)
  },
  [CHECK_LOGGER]: ({ commit, dispatch }, param) => {
    return new Promise((resolve, reject) => {
      commit(LOGGER_CHECKING, param)
      axios({
        url: `${param.checked ? CHECK_LOGGER_URL : UNCHECK_LOGGER_URL}`,
        method: 'post',
        type: 'json',
        data: [param.weirInfo.logger.id],
        cancelToken: param.cancelToken,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem(AUTH_TOKEN_KEY) || '',
          Accept: 'application/json, text/plain, */*',
          'Cache-Control': 'no-cache'
        }
      })
        .then(resp => {
          commit(LOGGER_CHECKING_SUCCESS, param)
          resolve(resp)
        })
        .catch(err => {
          if (axios.isCancel(err)) {
            console.info(
              "Weir overflow report request canceled with the following message: '%s'",
              err.message
            )
          } else {
            if (err.response != null && err.response.status === UNAUTHORIZED) {
              dispatch(REQUEST_REFRESH_TOKEN, {
                previousRequest: CHECK_LOGGER,
                param: param
              })
            } else {
              commit(LOGGER_CHECKING_ERROR)
            }
            reject(err)
          }
        })
    })
  },
  [UPDATE_WEIR_PHOSPHOR_QUANTITY]: ({ commit }, param) => {
    return new Promise((resolve, reject) => {
      commit(WEIR_PHOSPHOR_QUANTITY_UPDATING)
      axios({
        url:
          PHOSPHOR_QUANTITY_URL + '?quantity=' + param.phosphorQuantity,
        method: 'post',
        type: 'json',
        data: [param.weirInfo.logger.id],
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem(AUTH_TOKEN_KEY) || '',
          Accept: 'application/json, text/plain, */*',
          'Cache-Control': 'no-cache'
        }
      })
        .then(resp => {
          commit(WEIR_PHOSPHOR_QUANTITY_UPDATING_SUCCESS, {
            resp: resp,
            param: param
          })
          resolve(resp)
        })
        .catch(err => {
          commit(WEIR_PHOSPHOR_QUANTITY_UPDATING_ERROR, err)
          reject(err)
        })
    })
  },
  [REQUEST_SENSOR_DATA]: ({ commit, dispatch }, param) => {
    var requestUrl =
      SENSOR_REQUEST_URL + '/' + encodeURIComponent(param.loggerId) + '/values'
    requestUrl += '?type=' + encodeURIComponent(param.sensorType)
    if (param.dateTo) {
      requestUrl += '&to=' + encodeURIComponent(param.dateTo)
    }
    if (param.dateFrom) {
      requestUrl += '&from=' + encodeURIComponent(param.dateFrom)
    }
    requestUrl += '&aggregation=Hour'

    return new Promise((resolve, reject) => {
      commit(SENSOR_DATA_REQUEST, {
        param: param
      })
      axios({
        url: requestUrl,
        method: 'GET',
        type: 'json',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem(AUTH_TOKEN_KEY) || '',
          Accept: 'application/json, text/plain, */*',
          'Cache-Control': 'no-cache'
        }
      })
        .then(resp => {
          commit(SENSOR_DATA_REQUEST_SUCCESS, {
            resp: resp,
            param: param
          })
          resolve(resp)
        })
        .catch(err => {
          if (err.response != null && err.response.status === UNAUTHORIZED) {
            dispatch(REQUEST_REFRESH_TOKEN, {
              previousRequest: REQUEST_SENSOR_DATA,
              param: param
            })
          } else {
            commit(SENSOR_DATA_REQUEST_ERROR, {
              param: param
            })
          }
          reject(err)
        })
    })
  }
}

const mutations = {
  [LOGGER_INFO_REQUEST]: state => {
    state.logger_status = LOADING
  },
  [ASSOCIATED_LOGGER_INFO_REQUEST]: (state, param) => {
    if (state.loggerInfos.length > 0) {
      let element = state.loggerInfos.find(
        l => l.logger.logger.id === Number(param.loggerId)
      )
      if (element != null) {
        element.logger.logger.associated_loggers = []
        element.logger.associated_logger_status = LOADING
      }
    }
  },
  [LOGGER_INFO_REQUEST_SUCCESS]: (state, param) => {
    state.logger_status = SUCCESS
    if (state.loggerInfos.length > 0) {
      for (let index = state.loggerInfos.length - 1; index >= 0; index--) {
        const element = state.loggerInfos[index]
        if (element.logger.logger.type.toLowerCase() === param.type.toLowerCase()) {
          state.loggerInfos.splice(index, 1)
        }
      }
    }

    param.resp.data.slice().forEach(element => {
      if (element.timeSeries != null) {
        element.timeSeries.forEach(ts => {
          ts.measurement_status = ''
          ts.measurements = null
        })
      }
      state.loggerInfos.push({
        logger: element,
        checked: element.checked
      })
    })
  },
  [ASSOCIATED_LOGGER_INFO_REQUEST_SUCCESS]: (state, param) => {
    let element = state.loggerInfos.find(l => l.logger.logger.id === Number(param.loggerId))

    if (state.loggerInfos.length > 0 && param.resp.data.length > 0 && element != null) {
      element.logger.logger.associated_loggers = param.resp.data
      let rainLoggerFound = false
      if (state.loggerInfos.length > 0) {
        for (let index = state.loggerInfos.length - 1; index >= 0; index--) {
          const element = state.loggerInfos[index]
          if (param.resp.data.find(el1 => el1.logger.id === element.logger.logger.id)) {
            rainLoggerFound = true
          }
        }
      }

      if (!rainLoggerFound) {
        param.resp.data.slice().forEach(element => {
          if (element.timeSeries != null) {
            element.timeSeries.forEach(ts => {
              ts.measurement_status = ''
              ts.measurements = null
            })
          }
          state.loggerInfos.push({
            logger: element,
            checked: element.checked
          })
        })
      }
    }

    if (element != null) {
      element.logger.associated_logger_status = SUCCESS
    }
  },
  [LOGGER_INFO_REQUEST_ERROR]: state => {
    state.logger_status = ERROR
  },
  [ASSOCIATED_LOGGER_INFO_REQUEST_ERROR]: (state, param) => {
    let element = state.loggerInfos.find(l => l.logger.logger.id === Number(param.loggerId))

    if (state.loggerInfos.length > 0) {
      if (element != null) {
        element.logger.logger.associated_loggers = []
      }
    }

    if (element != null) {
      element.logger.associated_logger_status = SUCCESS
    }
  },
  [OVERFLOW_REQUEST]: state => {
    state.status = LOADING
  },
  [OVERFLOW_REQUEST_SUCCESS]: (state, data) => {
    state.status = SUCCESS
    state.weirInfos = []

    data.slice().forEach(element => {
      if (element.name != null && element.name.includes('-')) {
        element.name = element.name.substr(element.name.indexOf('-') + 1)
      }

      state.weirInfos.push({
        logger: element,
        overflowHours: 0,
        overflowVolume: 0,
        overflowEventCount: 0,
        overflowPhosphorQuantity: 0,
        lastTimeInOverflow: null,
        checked: element.checked
      })
    })

    state.maxOverflowHours = 1
    state.maxOverflowVolume = 1
  },
  [OVERFLOW_REQUEST_ERROR]: state => {
    state.status = ERROR
  },
  [OVERFLOW_ALL_STATUSES_CLEARING]: state => {
    if (Array.isArray(state.weirInfos) && state.weirInfos.length > 0) {
      var weirInfos = state.weirInfos.slice()
      weirInfos.forEach(element => {
        element.overflowHours = 0
        element.overflowVolume = 0
        element.overflowEventCount = 0
        element.lastTimeInOverflow = null
      })
      state.weirInfos = weirInfos
    }

    state.maxOverflowHours = 1
    state.maxOverflowVolume = 1
    state.maxOverflowEventCount = 1
  },
  [OVERFLOW_ALL_STATUSES_REQUEST]: () => {
    // do nothing
  },
  [OVERFLOW_STATUS_REQUEST]: (param) => {
    var weirInfos = state.weirInfos.slice()
    if (weirInfos != null) {
      let element = weirInfos.find(
        w => w.logger.id === param.data
      )
      if (element != null) {
        if (element.reports == null) {
          element.reports = []
        }

        let foundReport = element.reports.find(
          r => r.year === param.year
        )

        if (foundReport == null) {
          element.reports.push({ status: LOADING })
        } else {
          foundReport.status = LOADING
        }
      }
    }
  },
  [OVERFLOW_STATUS_REQUEST_SUCCESS]: (state, data) => {
    let maxOverflowHours = state.maxOverflowHours
    let maxOverflowVolume = state.maxOverflowVolume
    let maxOverflowEventCount = state.maxOverflowEventCount
    var weirInfos = state.weirInfos.slice()
    data.forEach(report => {
      if (report.overflow.year === state.year) {
        let element = weirInfos.find(
          w => w.logger.id === report.logger.id
        )
        element.overflowHours = report.overflow.entries.reduce(
          (accumulator, currentValue) =>
            accumulator + currentValue.lengthInHours,
          0
        )
        element.overflowVolume = report.overflow.entries.reduce(
          (accumulator, currentValue) => accumulator + currentValue.volume,
          0
        )
        element.overflowEventCount = report.overflow.entries.reduce(
          (accumulator, currentValue) => accumulator + currentValue.eventCount,
          0
        )
        element.lastTimeInOverflow = report.overflow.lastTime
        if (element.reports == null) {
          element.reports = []
        }
        let foundReport = element.reports.find(
          r => r.year === report.overflow.year
        )

        report.overflow.status = SUCCESS

        if (foundReport == null) {
          element.reports.push(report.overflow)
        } else {
          foundReport = report.overflow
        }
        maxOverflowHours = Math.max(maxOverflowHours, element.overflowHours)
        maxOverflowVolume = Math.max(maxOverflowVolume, element.overflowVolume)
        maxOverflowEventCount = Math.max(
          maxOverflowEventCount,
          element.overflowEventCount
        )
      }
    })

    if (state.maxOverflowHours < maxOverflowHours) {
      state.maxOverflowHours = maxOverflowHours
    }

    if (state.maxOverflowVolume < maxOverflowVolume) {
      state.maxOverflowVolume = maxOverflowVolume
    }
    if (state.maxOverflowEventCount < maxOverflowEventCount) {
      state.maxOverflowEventCount = maxOverflowEventCount
    }

    state.weirInfos = weirInfos
  },
  [OVERFLOW_STATUS_REQUEST_ERROR]: (param) => {
    var weirInfos = state.weirInfos.slice()
    if (weirInfos !== null) {
      let element = weirInfos.find(
        w => w.logger.id === param.data
      )
      if (element != null) {

        if (element.reports == null) {
          element.reports = []
        }

        let foundReport = element.reports.find(
          r => r.year === param.year
        )

        if (foundReport == null) {
          element.reports.push({ status: ERROR })
        } else {
          foundReport.status = ERROR
        }
      }
    }
  },
  [YEAR_ASSIGNMENT]: (state, year) => {
    state.year = year
  },
  [LOGGER_CHECKING]: () => {
    // do nothing
  },
  [LOGGER_CHECKING_SUCCESS]: (state, param) => {
    const weir = state.weirInfos.find(
      w => w.logger.id === param.weirInfo.logger.id
    )
    weir.checked = param.checked
  },
  [LOGGER_CHECKING_ERROR]: () => {
    // do nothing
  },
  [OVERFLOW_YEAR_REPORT_CLEARING]: (state, loggerId) => {
    clearOverflowReports(state, loggerId)
  },
  [OVERFLOW_YEAR_REPORT_REQUEST]: (state, param) => {
    state.year_report_status = LOADING
    removeOverflowReports(state, param.loggerId, param.year)
    updateStatus(state, param.loggerId, param.year, LOADING)
    state.request_count++
  },
  [OVERFLOW_YEAR_REPORT_REQUEST_SUCCESS]: (state, param) => {
    updateOverflowReports(
      state,
      param.loggerId,
      param.year,
      param.report,
      SUCCESS
    )
    state.request_count = state.request_count - 1
    if (state.request_count === 0) {
      state.year_report_status = SUCCESS
    }
  },
  [OVERFLOW_YEAR_REPORT_REQUEST_ERROR]: (state, param) => {
    state.request_count = state.request_count - 1
    updateStatus(state, param.loggerId, param.year, ERROR)
    if (state.request_count === 0) {
      state.year_report_status = ERROR
    }
  },
  [OVERFLOW_YEAR_REPORT_REQUEST_ATTEMPT_ERROR]: (state) => {
    state.request_count = state.request_count - 1
  },
  [WEIR_PHOSPHOR_QUANTITY_UPDATING]: (state) => {
    state.weirPhosphorQuantityUpdatingStatus = LOADING
  },
  [WEIR_PHOSPHOR_QUANTITY_UPDATING_SUCCESS]: (state, param) => {
    state.weirPhosphorQuantityUpdatingStatus = SUCCESS
    updateWeirPhosphorQuantity(
      state,
      param.param.weirInfo,
      param.param.phosphorQuantity
    )
  },
  [WEIR_PHOSPHOR_QUANTITY_UPDATING_ERROR]: (state) => {
    state.weirPhosphorQuantityUpdatingStatus = ERROR
  },
  [SENSOR_DATA_REQUEST]: (state, param) => {
    updateSensorData(state, param, LOADING)
  },
  [SENSOR_DATA_REQUEST_SUCCESS]: (state, param) => {
    updateSensorData(state, param, SUCCESS)
  },
  [SENSOR_DATA_REQUEST_ERROR]: (state, param) => {
    updateSensorData(state, param, ERROR)
  }
}

function clearOverflowReports(state, loggerId) {
  const weir = state.weirInfos.find(w => w.logger.id === loggerId)
  weir.reports = []
}

function updateSensorData(state, param, status) {
  var logger = state.loggerInfos.find(
    w => w.logger.logger.id === Number(param.param.loggerId)
  ).logger

  logger.timeSeries.forEach(element => {
    if (element.type.toLowerCase() === param.param.sensorType) {
      if (status === SUCCESS) {
        element.measurements = param.resp.data.measurements
      }
      if (status === LOADING) {
        element.measurements = null
      }
      element.measurement_status = status
    }
  })
}

function removeOverflowReports(state, loggerId, year) {
  let weir = state.weirInfos.find(w => w.logger.id === loggerId)
  if (weir == null) {
    weir = { logger: { id: loggerId } }
    state.weirInfos.push(weir)
  }
  if (weir.reports == null) {
    weir.reports = []
  }
  var overflowReports = weir.reports.slice()
  for (let index = weir.reports.length; index > 0; index--) {
    const element = overflowReports[index - 1]
    if (element.year === year) {
      element.entries = null
      overflowReports[index - 1] = element
    }
  }
  weir.reports = overflowReports
}

function updateWeirPhosphorQuantity(state, weirInfo, phosphorQuantity) {
  const weir = state.weirInfos.find(w => w.logger.id === weirInfo.logger.id)
  if (weir != null) {
    weir.logger.phosphorQuantity = phosphorQuantity
  }
}

function updateOverflowReports(state, loggerId, year, report, status) {
  var foundReport = false
  const weir = state.weirInfos.find(w => w.logger.id === loggerId)
  if (weir.reports == null) {
    weir.reports = []
  } else {
    weir.reports.forEach(element => {
      if (element.year === year) {
        foundReport = true
        element.entries = report[0].overflow.entries
        element.lastTime = report[0].overflow.lastTime
        element.status = status
      }
    })
  }
  if (!foundReport) {
    let element = report[0].overflow
    element.status = status
    weir.reports.push(element)
  }
}

function updateStatus(state, loggerId, year, status) {
  const weir = state.weirInfos.find(w => w.logger.id === loggerId)
  if (weir.reports == null) {
    weir.reports = []
  }
  var reportStatuses = weir.reports.slice()
  var foundStatus = false
  reportStatuses.forEach(element => {
    if (element.year === year) {
      foundStatus = true
      element.status = status
    }
  })
  if (!foundStatus) {
    reportStatuses.push({
      year: year,
      status: status
    })
  }
  weir.reports = reportStatuses
}

export default {
  state,
  getters,
  actions,
  mutations
}
