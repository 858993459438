<template>
  <div>
    <b-container id="container-list" fluid>
      <div v-if="isLoaded">
        <splitpanes
          class="default-theme"
          @resized="paneResized"
          @ready="refreshMap"
          @pane-maximize="paneMaximazed"
        >
          <pane :size="leftPaneSize" min-size="10">
            <b-container fluid>
              <b-row class="text-left align-items-center px-2">
                <b-col md="auto mx-0 px-0">
                  <h3>
                    Overløp for {{ yearSelected }}
                    <b-dropdown
                      variant="link"
                      right
                      size="sm"
                      style="margin-left: -10px"
                    >
                      <b-dd-item
                        size="sm"
                        :key="year.value"
                        v-for="year in yearOptions"
                        @click="handleSetYear(year.value)"
                        >{{ year.text }}</b-dd-item
                      >
                    </b-dropdown>
                  </h3>
                </b-col>
                <b-col md="auto mx-0 px-0" v-if="!this.areAllReportsLoaded">
                  <font-awesome-icon icon="spinner" size="1x" spin />
                </b-col>
                <b-col class="auto text-right mx-0 px-0">
                  <b-btn
                    @click="handleFilterClick()"
                    variant="outline-secondary"
                    size="sm"
                    ><font-awesome-icon
                      icon="search"
                      size="1x"
                    />&nbsp;Søk</b-btn
                  >
                </b-col>
              </b-row>
              <b-row
                v-if="filterShown && isOverflowLikeArcGis"
                class="filter_row"
              >
                <b-col md="12" class="center">
                  <b-form-input
                    v-model="filterSearchText"
                    placeholder="Søk..."
                    type="search"
                    size="sm"
                  />
                </b-col>
              </b-row>
              <b-card
                no-body
                v-if="filterShown && !isOverflowLikeArcGis"
                class="row"
              >
                <b-row class="filter_row">
                  <b-col md="5" class="center">
                    <b-form-input
                      v-model="filterSearchText"
                      placeholder="Søk..."
                      type="search"
                      size="sm"
                    />
                  </b-col>
                  <b-col md="2" class="center" v-if="!isOverflowLikeArcGis">
                    <div class="text-right" style="width: 100%">Andel:</div>
                  </b-col>
                  <b-col
                    md="5"
                    style="margin-top: 24px"
                    v-if="!isOverflowLikeArcGis"
                  >
                    <vue-slider
                      v-bind="sliderOptions"
                      v-model="filterSliderValue"
                    >
                      <div
                        class="vue-slider-tooltip slider"
                        slot="tooltip"
                        slot-scope="{ value }"
                      >
                        {{ value }}%
                      </div>
                    </vue-slider>
                  </b-col>
                </b-row>
              </b-card>
              <b-table
                small
                striped
                sticky-header
                hover
                :items="filteredWeirInfos"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                class="table_wrapper text-center"
                v-if="isOverflowLikeArcGis"
              >
                <template #head()="data">
                  <div class="text-center no-wrap">
                    {{ data.label }}
                  </div>
                </template>
                <template #head(id)="data">
                  <div class="text-left no-wrap" style="padding-left: 39px">
                    <input
                      type="checkbox"
                      :checked="allChecked"
                      style="vertical-align: text-top"
                      @change="handleCheckAllClick($event)"
                    />
                    {{ data.label }}
                  </div>
                </template>
                <template #cell(id)="data">
                  <div class="text-left no-wrap">
                    <a
                      :href="'/weirpdfviewer/' + data.item.logger.id"
                      class="text-primary pr-1"
                      style="margin-right: 5px"
                      ><font-awesome-icon icon="info-circle" size="lg"
                    /></a>
                    <a
                      href="#"
                      class="text-primary"
                      style="margin-right: 5px"
                      @click="handleFlyToClick(data.item)"
                      ><font-awesome-icon icon="search" size="lg"
                    /></a>
                    <input
                      type="checkbox"
                      :checked="data.item.checked"
                      @change="handleCheckLoggerClick(data.item, $event)"
                      style="vertical-align: text-bottom; margin-right: 5px"
                    />
                    <a
                      :href="'/weir/' + data.item.logger.id"
                      class="text-dark"
                      >{{ data.item.logger.alias ?? data.item.logger.name }}</a
                    >
                  </div>
                </template>
                <template #head(system)="data">
                  <div class="text-center no-wrap">{{ data.label }}</div>
                </template>
                <template #cell(system)="data">
                  <div class="text-left no-wrap">
                    {{ data.item.logger.system ?? '-' }}
                  </div>
                </template>
                <template #head(isPumpstation)="data">
                  <div class="text-center no-wrap">{{ data.label }}</div>
                </template>
                <template #cell(isPumpstation)="data">
                  <div class="text-left">
                    {{
                      data.item.logger.isPumpstation !== null
                        ? data.item.logger.isPumpstation
                          ? 'Ja'
                          : 'Nei'
                        : '-'
                    }}
                  </div>
                </template>
                <template #cell(area)="data">
                  <div class="text-right">
                    {{
                      data.item.logger.area
                        ? data.item.logger.area.toFixed(1)
                        : '-'
                    }}
                  </div>
                </template>
                <template #cell(systemOrder)="data">
                  <div class="text-right">
                    {{ data.item.logger.systemOrder ?? '-' }}
                  </div>
                </template>
                <template #cell(lastOverflow)="data">
                  <div class="text-right">
                    {{ toFormattedLastOverflowTime(data.item) }}
                  </div>
                </template>
                <template #cell(overflowHours)="data">
                  <div class="text-right">
                    {{ Math.round(data.item.overflowHours) }}
                  </div>
                </template>
                <template #cell(overflowVolume)="data">
                  <div class="text-right">
                    {{ Math.round(data.item.overflowVolume) }}
                  </div>
                </template>
                <template #cell(overflowEventCount)="data">
                  <div class="text-right">
                    {{ Math.round(data.item.overflowEventCount) }}
                  </div>
                </template>
                <template #cell(kgP)="data">
                  <div class="text-right">
                    {{
                      Math.round(
                        data.item.overflowVolume * getFactorX(data.item) * 100
                      ) / 100
                    }}
                  </div>
                </template>
              </b-table>
              <b-row
                v-if="!isOverflowLikeArcGis"
                class="logger_row_header text-left"
              >
                <b-col md="4 text-left mx-0 px-0">
                  <input
                    type="checkbox"
                    :checked="allChecked"
                    style="vertical-align: text-top"
                    @change="handleCheckAllClick($event)"
                  />
                  SID / Navn
                </b-col>
                <b-col md="2 text-center mx-0 px-0"> Siste Overløp </b-col>
                <b-col md="4 text-center mx-0 px-0">
                  {{ flowLengthColumnHeader
                  }}<b-dropdown
                    class="show-volume-drpdwn"
                    variant="link"
                    right
                    size="sm"
                  >
                    <b-dd-item-btn size="sm" @click="setShowLength()"
                      >Driftstid, timer</b-dd-item-btn
                    >
                    <b-dd-item-btn size="sm" @click="setShowVolume()"
                      >Mengde i overløp, m&sup3;</b-dd-item-btn
                    >
                    <b-dd-item-btn size="sm" @click="setShowEventCount()"
                      >Antall</b-dd-item-btn
                    >
                    <b-dd-item-btn size="sm" @click="setShowKgP()"
                      >kgP i overløp</b-dd-item-btn
                    >
                  </b-dropdown>
                </b-col>
                <b-col md="1 text-center mx-0 px-0"> </b-col>
                <b-col md="1 text-center mx-0 px-0"> Type </b-col>
              </b-row>
              <b-row>
                <b-col md="12 mx-0 px-0">
                  <PerfectScrollbar
                    :settings="scroll_settings"
                    class="wrapper"
                    v-if="!isOverflowLikeArcGis"
                  >
                    <b-container>
                      <b-row
                        v-if="this.filteredWeirInfos.length <= 0"
                        class="logger_row"
                      >
                        <b-col
                          md="12 text-center mx-0 px-0 text-secondary font-italic"
                        >
                          Ingenting å vise
                        </b-col>
                      </b-row>
                      <b-row
                        :key="weirInfo.logger.id"
                        v-for="weirInfo in filteredWeirInfos"
                        class="logger_row text-left"
                      >
                        <b-col md="4 text-left mx-0 px-0">
                          <input
                            type="checkbox"
                            :checked="weirInfo.checked"
                            @change="handleCheckLoggerClick(weirInfo, $event)"
                            style="
                              vertical-align: text-bottom;
                              margin-bottom: 1px;
                            "
                          />
                          <a
                            href="#"
                            class="text-secondary px-1"
                            @click="handleOpenSettingsModal(weirInfo)"
                          >
                            <font-awesome-icon icon="cog" size="1x" />
                          </a>
                          <a
                            :href="'/weir/' + weirInfo.logger.id"
                            class="text-dark"
                            >{{ weirInfo.logger.name }}</a
                          >
                        </b-col>
                        <b-col md="2 text-center mx-0 px-0">{{
                          toFormattedLastOverflowTime(weirInfo)
                        }}</b-col>
                        <b-col md="4 center mx-0 px-0" v-if="showLength">
                          <b-progress
                            :max="maxOverflowHours"
                            variant="success"
                            style="
                              height: inherit;
                              width: 100%;
                              font-size: 10px;
                            "
                            show-progress
                          >
                            <b-progress-bar
                              :value="Math.max(weirInfo.overflowHours, 0)"
                              :label="
                                (
                                  (weirInfo.overflowHours * 100) /
                                  maxOverflowHours
                                ).toFixed(0) + '%'
                              "
                            />
                          </b-progress>
                        </b-col>
                        <b-col md="1 text-right mx-0 px-0" v-if="showLength">
                          {{ Math.round(weirInfo.overflowHours) }}
                        </b-col>
                        <b-col md="4 center mx-0 px-0" v-if="showVolume">
                          <b-progress
                            :max="maxOverflowVolume"
                            variant="success"
                            style="
                              height: inherit;
                              width: 100%;
                              font-size: 10px;
                            "
                            show-progress
                          >
                            <b-progress-bar
                              :value="Math.max(weirInfo.overflowVolume, 0)"
                              :label="
                                (
                                  (weirInfo.overflowVolume * 100) /
                                  maxOverflowVolume
                                ).toFixed(0) + '%'
                              "
                            />
                          </b-progress>
                        </b-col>
                        <b-col md="1 text-right mx-0 px-0" v-if="showVolume">
                          {{ Math.round(weirInfo.overflowVolume) }}
                        </b-col>
                        <b-col md="4 center mx-0 px-0" v-if="showEventCount">
                          <b-progress
                            :max="maxOverflowEventCount"
                            variant="success"
                            style="
                              height: inherit;
                              width: 100%;
                              font-size: 10px;
                            "
                            show-progress
                          >
                            <b-progress-bar
                              :value="Math.max(weirInfo.overflowEventCount, 0)"
                              :label="
                                (
                                  (weirInfo.overflowEventCount * 100) /
                                  maxOverflowEventCount
                                ).toFixed(0) + '%'
                              "
                            />
                          </b-progress>
                        </b-col>
                        <b-col
                          md="1 text-right mx-0 px-0"
                          v-if="showEventCount"
                        >
                          {{ Math.round(weirInfo.overflowEventCount) }}
                        </b-col>
                        <b-col md="4 center mx-0 px-0" v-if="showKgP">
                          <b-progress
                            :max="maxOverflowPhosphorQuantity"
                            variant="success"
                            style="
                              height: inherit;
                              width: 100%;
                              font-size: 10px;
                            "
                            show-progress
                          >
                            <b-progress-bar
                              :value="
                                Math.max(
                                  Math.round(
                                    weirInfo.overflowVolume *
                                      getFactorX(weirInfo) *
                                      100
                                  ) / 100,
                                  0
                                )
                              "
                              :label="
                                (
                                  Math.round(
                                    weirInfo.overflowVolume *
                                      getFactorX(weirInfo) *
                                      100
                                  ) / maxOverflowPhosphorQuantity
                                ).toFixed(0) + '%'
                              "
                            />
                          </b-progress>
                        </b-col>
                        <b-col md="1 text-right mx-0 px-0" v-if="showKgP">
                          {{
                            Math.round(
                              weirInfo.overflowVolume *
                                getFactorX(weirInfo) *
                                100
                            ) / 100
                          }}
                        </b-col>
                        <b-col md="1 text-center mx-0 px-0">
                          <b-badge
                            pill
                            variant="secondary"
                            v-if="
                              weirInfo.logger.type.toLowerCase() ===
                              weirComputedType
                            "
                            >B</b-badge
                          >
                          <b-badge
                            pill
                            variant="secondary"
                            v-else-if="
                              weirInfo.logger.type.toLowerCase() ===
                              weirComputedAndMeasuredType
                            "
                            >BM</b-badge
                          >
                          <b-badge pill variant="secondary" v-else
                            >&nbsp;M&nbsp;</b-badge
                          >
                        </b-col>
                      </b-row>
                    </b-container>
                  </PerfectScrollbar>
                </b-col>
              </b-row>
            </b-container>
          </pane>
          <pane :size="rightPaneSize">
            <l-map
              ref="overflowMap"
              :zoom="this.pageSettings.zoom"
              :center="this.pageSettings.center"
              @update:zoom="zoomUpdated"
              @update:center="centerUpdated"
              style="height: 90vh"
            >
              <l-tile-layer :url="url" :attribution="attribution" />
              <l-marker
                :lat-lng="[weirInfo.logger.y, weirInfo.logger.x]"
                :key="weirInfo.logger.id"
                v-for="weirInfo in filteredWeirInfos"
              >
                <l-icon>
                  <table>
                    <td>
                      <font-awesome-icon
                        icon="adjust"
                        :size="getWeirSize(weirInfo)"
                        :color="getWeirColor(weirInfo)"
                        :transform="{ rotate: 135 }"
                      />
                    </td>
                    <td>
                      <strong class="align-baseline">{{
                        getWeirName(weirInfo)
                      }}</strong>
                    </td>
                  </table>
                </l-icon>
                <l-popup>
                  <input
                    type="checkbox"
                    :checked="weirInfo.checked"
                    @change="handleCheckLoggerClick(weirInfo, $event)"
                    style="vertical-align: text-bottom; margin-bottom: 2px"
                  />
                  <a class="px-1" :href="'/weir/' + weirInfo.logger.id">{{
                    weirInfo.logger.alias ?? weirInfo.logger.name
                  }}</a>
                  <div style="padding-top: 5px" v-if="!isOverflowLikeArcGis">
                    Siste Overløp: {{ toFormattedLastOverflowTime(weirInfo) }}
                    <br />
                    Driftstid, timer: {{ Math.round(weirInfo.overflowHours) }}
                    <br />
                    Mengde i overløp, m&sup3;:
                    {{ Math.round(weirInfo.overflowVolume) }} <br />
                    Antall: {{ Math.round(weirInfo.overflowEventCount) }}
                    <br />
                    kgP i overløp:
                    {{
                      Math.round(
                        weirInfo.overflowVolume * getFactorX(weirInfo) * 100
                      ) / 100
                    }}
                  </div>
                  <div style="padding-top: 5px" v-if="isOverflowLikeArcGis">
                    Sted: {{ weirInfo.logger.system }} <br />
                    Siste Overløp:
                    {{ toFormattedLastOverflowTime(weirInfo) }} <br />
                    Driftstid, timer:
                    {{ Math.round(weirInfo.overflowHours) }} <br />
                    Mengde i overløp, m&sup3;:
                    {{ Math.round(weirInfo.overflowVolume) }} <br />
                    Antall: {{ Math.round(weirInfo.overflowEventCount) }}
                    <br />
                    kgP i overløp:
                    {{
                      Math.round(
                        weirInfo.overflowVolume * getFactorX(weirInfo) * 100
                      ) / 100
                    }}
                    <br />
                    Pumpe:
                    {{
                      weirInfo.logger.isPumpstation !== null
                        ? weirInfo.logger.isPumpstation
                          ? 'Ja'
                          : 'Nei'
                        : '-'
                    }}
                    <br />
                    Hektar:
                    {{
                      weirInfo.logger.area
                        ? weirInfo.logger.area.toFixed(1)
                        : '-'
                    }}
                    <br />
                    Nr: {{ weirInfo.logger.systemOrder ?? '-' }}
                  </div>
                </l-popup>
              </l-marker>
              <l-control-scale
                :position="'bottomleft'"
                :metric="true"
                :imperial="false"
              />
            </l-map>
          </pane>
        </splitpanes>
      </div>
      <b-row v-if="!isLoaded && !isError">
        <b-col md="12" class="text-center"
          ><font-awesome-icon icon="spinner" size="4x" spin
        /></b-col>
      </b-row>
      <b-row v-if="isError">
        <b-col md="4" class="text-right"
          ><font-awesome-icon icon="exclamation-circle" size="4x"
        /></b-col>
        <b-col md="4" class="text-left" style="padding-top: 20px"
          >Noe gikk galt</b-col
        >
      </b-row>
    </b-container>
    <b-modal
      v-model="showSettingsModal"
      @ok="handleSettingsOk"
      cancel-title="Avbryt"
      :title="phosphorSettinsTitle"
    >
      <b-overlay :show="phosphorQuantityStatusIsLoading" rounded="sm">
        <form ref="form" @submit.stop.prevent="handleSettingsSubmit">
          <b-form-group
            :state="phosphorQuantityInputState"
            label="mgP per liter"
            label-for="phosphorQuantityInput"
            invalid-feedback="'mgP per liter' is required and should be >= 0"
          >
            <b-form-input
              id="phosphorQuantityInput"
              v-model="phosphorQuantityform"
              :state="phosphorQuantityInputState"
              type="number"
              size="sm"
              lang="no"
              required
            />
          </b-form-group>
        </form>
      </b-overlay>
    </b-modal>
  </div>
</template>

<style>
@import '../../../node_modules/leaflet/dist/leaflet.css';
</style>

<script>
import { LOADING, SUCCESS, ERROR } from '../../store/actions/backend.js'
import {
  REQUEST_OVERFLOW,
  REQUEST_ALL_OVERFLOW_STATUSES,
  CLEAR_ALL_OVERFLOW_STATUSES,
  SET_YEAR,
  CHECK_LOGGER,
  UPDATE_WEIR_PHOSPHOR_QUANTITY,
  UPDATE_PAGE_SETTINGS
} from '../../store/action-types'
import { mapGetters, mapActions } from 'vuex'
import {
  LMap,
  LPopup,
  LTileLayer,
  LControlScale,
  LIcon,
  LMarker
} from 'vue2-leaflet'
import { Splitpanes, Pane } from 'splitpanes'

import PerfectScrollbar from 'vue-perfect-scrollbar'
import {
  toSensorDateString,
  parseSensorDateStringToDate
} from '../../utils/Date.js'
import { WEIR } from '../../utils/logger_consts.js'

import L from 'leaflet'
import '../../../node_modules/leaflet/dist/leaflet.css'
import 'splitpanes/dist/splitpanes.css'

delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('../../../node_modules/leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('../../../node_modules/leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('../../../node_modules/leaflet/dist/images/marker-shadow.png')
})

const LENGTH = 'length'
const VOLUME = 'volume'
const EVENT_COUNT = 'event_count'
const KGP = 'kgp'

export default {
  name: 'weir-form',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup,
    LControlScale,
    PerfectScrollbar,
    Splitpanes,
    Pane
  },
  computed: {
    ...mapGetters([
      'weirInfos',
      'status',
      'userExtent',
      'maxOverflowHours',
      'maxOverflowVolume',
      'maxOverflowEventCount',
      'year',
      'phosphorQuantity',
      'weirPhosphorQuantityUpdatingStatus',
      'pagesSettings',
      'userOptions'
    ]),

    areAllReportsLoaded: function () {
      if (this.weirInfos == null) {
        return true
      }

      let existNotSuccess = true

      this.weirInfos.forEach(w => {
        if (
          w.reports == null ||
          w.reports.findIndex(
            r => r.status === LOADING && r.year == this.yearSelected
          ) > -1
        ) {
          existNotSuccess = false
        }
      })

      return existNotSuccess
    },

    isOverflowLikeArcGis: function () {
      if (
        this.userOptions !== null &&
        this.userOptions.presentations !== null &&
        this.userOptions.presentations.indexOf('OverflowLikeArcGis') >= 0
      ) {
        return true
      }

      return false
    },

    phosphorSettinsTitle: function () {
      return this.currentWeir && this.currentWeir.logger
        ? 'Innstillinger for ' + this.currentWeir.logger.name
        : ''
    },

    phosphorQuantityInputState: function () {
      return (
        this.phosphorQuantityform != null &&
        this.phosphorQuantityform !== '' &&
        parseInt(this.phosphorQuantityform) >= 0
      )
    },

    phosphorQuantityStatusIsLoading: function () {
      return this.weirPhosphorQuantityUpdatingStatus === LOADING
    },

    maxOverflowPhosphorQuantity: function () {
      var mainPhosphorQuantity = this.phosphorQuantity
      var maxValue = 0

      this.weirInfos.forEach(weirInfo => {
        let pQFactor =
          (weirInfo.logger.phosphorQuantity != null
            ? weirInfo.logger.phosphorQuantity
            : mainPhosphorQuantity) / 1000
        maxValue = Math.max(
          Math.round(weirInfo.overflowVolume * pQFactor * 100) / 100,
          maxValue
        )
      })

      return maxValue
    },

    isLoaded () {
      return this.status === SUCCESS || this.status === ''
    },

    isError () {
      return this.status === ERROR
    },

    center () {
      return [
        (this.userExtent.minY + this.userExtent.maxY) / 2,
        (this.userExtent.minX + this.userExtent.maxX) / 2
      ]
    },

    bounds () {
      return L.latLngBounds([
        [this.userExtent.minY, this.userExtent.minX],
        [this.userExtent.maxY, this.userExtent.maxX]
      ])
    },
    showVolume: function () {
      return this.showColumnState === VOLUME
    },
    showLength: function () {
      return this.showColumnState === LENGTH
    },
    showEventCount: function () {
      return this.showColumnState === EVENT_COUNT
    },
    showKgP: function () {
      return this.showColumnState === KGP
    },
    flowLengthColumnHeader () {
      if (this.showVolume) {
        return 'Mengde i overløp, m³'
      }

      if (this.showLength) {
        return 'Driftstid, timer'
      }

      if (this.showEventCount) {
        return 'Antall'
      }

      return 'kgP i overløp'
    },

    filteredWeirInfos () {
      return this.weirInfos
        .filter(w => {
          return (
            ((w.logger.system != null &&
              w.logger.system
                .toLowerCase()
                .includes(this.filterSearchText.toLowerCase())) ||
              (w.logger.alias != null &&
                w.logger.alias
                  .toLowerCase()
                  .includes(this.filterSearchText.toLowerCase())) ||
              (w.logger.alias === null &&
                w.logger.name != null &&
                w.logger.name
                  .toLowerCase()
                  .includes(this.filterSearchText.toLowerCase()))) &&
            (this.filterSliderValue === 0 ||
              (this.showLength &&
                ((w.overflowHours * 100) / this.maxOverflowHours).toFixed(0) >=
                  this.filterSliderValue) ||
              (this.showVolume &&
                ((w.overflowVolume * 100) / this.maxOverflowVolume).toFixed(
                  0
                ) >= this.filterSliderValue) ||
              (this.showKgP &&
                (
                  Math.round(w.overflowVolume * this.getFactorX(w) * 100) /
                  this.maxOverflowPhosphorQuantity
                ).toFixed(0) >= this.filterSliderValue) ||
              (this.showEventCount &&
                (
                  (w.overflowEventCount * 100) /
                  this.maxOverflowEventCount
                ).toFixed(0) >= this.filterSliderValue))
          )
        })
        .sort((lhs, rhs) => {
          if (!this.isOverflowLikeArcGis) {
            if (
              (this.showLength &&
                lhs.overflowHours !== null &&
                rhs.overflowHours !== null) ||
              ((this.showVolume || this.showKgP) &&
                lhs.overflowVolume !== null &&
                rhs.overflowVolume !== null) ||
              (this.showEventCount &&
                lhs.overflowEventCount !== null &&
                rhs.overflowEventCount !== null)
            ) {
              var comparison = 0
              if (this.showVolume) {
                comparison = -(lhs.overflowVolume - rhs.overflowVolume)
              }

              if (this.showKgP) {
                comparison = -(
                  Math.round(lhs.overflowVolume * this.getFactorX(lhs) * 100) /
                    this.maxOverflowPhosphorQuantity -
                  Math.round(rhs.overflowVolume * this.getFactorX(rhs) * 100) /
                    this.maxOverflowPhosphorQuantity
                )
              }

              if (this.showLength) {
                comparison = -(lhs.overflowHours - rhs.overflowHours)
              }

              if (this.showEventCount) {
                comparison = -(lhs.overflowEventCount - rhs.overflowEventCount)
              }

              if (comparison !== 0) {
                return comparison
              }
            }

            return lhs.logger.name.localeCompare(rhs.logger.name)
          } else {
            if (this.sortBy === 'overflowHours') {
              comparison = -(lhs.overflowHours - rhs.overflowHours)
            }

            if (this.sortBy === 'overflowEventCount') {
              comparison = -(lhs.overflowEventCount - rhs.overflowEventCount)
            }

            if (this.sortBy === 'overflowVolume') {
              comparison = -(lhs.overflowVolume - rhs.overflowVolume)
            }

            if (this.sortBy === 'kgP') {
              comparison = lhs.overflowVolume - rhs.overflowVolume
            }

            if (this.sortBy === 'systemOrder') {
              comparison =
                (lhs.logger.systemOrder ?? 0) - (rhs.logger.systemOrder ?? 0)
            }

            if (this.sortBy === 'area') {
              comparison = (lhs.logger.area ?? 0) - (rhs.logger.area ?? 0)
            }

            if (this.sortBy === 'id') {
              comparison =
                lhs.logger.alias != null
                  ? lhs.logger.alias.localeCompare(
                      rhs.logger.alias ?? rhs.logger.name
                    )
                  : lhs.logger.name.localeCompare(
                      rhs.logger.alias ?? rhs.logger.name
                    )
            }

            if (this.sortBy === 'lastOverflow') {
              let lhsLastOverflowDate = lhs.lastTimeInOverflow
                ? parseSensorDateStringToDate(lhs.lastTimeInOverflow)
                : this.minDate
              let rhsLastOverflowDate = rhs.lastTimeInOverflow
                ? parseSensorDateStringToDate(rhs.lastTimeInOverflow)
                : this.minDate

              comparison = lhsLastOverflowDate <= rhsLastOverflowDate ? -1 : 1
            }

            if (this.sortBy === 'system') {
              comparison =
                lhs.logger.system != null
                  ? lhs.logger.system.localeCompare(rhs.logger.system ?? '-')
                  : '-'.localeCompare(rhs.logger.system ?? '-')
            }

            if (this.sortBy === 'isPumpstation') {
              let lhsIsPump =
                lhs.logger.isPumpstation != null
                  ? lhs.logger.isPumpstation
                    ? 'Ja'
                    : 'Nei'
                  : '-'
              let rhsIsPump =
                rhs.logger.isPumpstation != null
                  ? rhs.logger.isPumpstation
                    ? 'Ja'
                    : 'Nei'
                  : '-'
              comparison = lhsIsPump.localeCompare(rhsIsPump)
            }

            return this.sortDesc ? -comparison : comparison
          }
        })
    },

    weirComputedType () {
      return 'computed'
    },

    weirComputedAndMeasuredType () {
      return 'computed with measured'
    }
  },
  methods: {
    ...mapActions({
      loadOverflow: REQUEST_OVERFLOW,
      loadAllStatuses: REQUEST_ALL_OVERFLOW_STATUSES,
      clearAllStatuses: CLEAR_ALL_OVERFLOW_STATUSES,
      updateYear: SET_YEAR,
      checkLogger: CHECK_LOGGER,
      updateWeirPhosphorQuantity: UPDATE_WEIR_PHOSPHOR_QUANTITY,
      updatePageSettings: UPDATE_PAGE_SETTINGS
    }),
    paneMaximazed: function () { 
      this.pageSettings.leftPaneSize = 10
      this.pageSettings.rightPaneSize = 90
      this.leftPaneSize = 10
      this.rightPaneSize = 90
      this.updatePageSettings(this.pageSettings)
      this.refreshMap()
    },
    paneResized: function (event) {
      this.pageSettings.leftPaneSize = event[0].size
      this.pageSettings.rightPaneSize = event[1].size
      this.leftPaneSize = event[0].size
      this.rightPaneSize = event[1].size
      this.updatePageSettings(this.pageSettings)
      this.refreshMap()
    },

    refreshMap: function () {
      let self = this
      setTimeout(function () {
        self.$refs.overflowMap.mapObject.invalidateSize()
      }, 1000)
    },
    handleFlyToClick: function (weirInfo) {
      this.$refs.overflowMap.mapObject.flyTo(
        [weirInfo.logger.y, weirInfo.logger.x],
        18
      )
    },

    handleCheckLoggerClick: function (weirInfo, e) {
      this.checkLogger({ weirInfo: weirInfo, checked: e.target.checked })
      let anyUnchecked =
        this.filteredWeirInfos.find(weirInfo => weirInfo.checked !== true) !=
        null
      this.allChecked = !anyUnchecked
    },

    handleCheckAllClick: function (e) {
      this.allChecked = e.target.checked
      this.filteredWeirInfos.forEach(weirInfo => {
        if (weirInfo.checked !== e.target.checked) {
          this.checkLogger({ weirInfo: weirInfo, checked: e.target.checked })
        }
      })
    },

    handleOpenSettingsModal: function (weirInfo) {
      this.currentWeir = weirInfo
      this.phosphorQuantityform = weirInfo.logger.phosphorQuantity
      this.showSettingsModal = true
    },

    handleSettingsOk: function (bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSettingsSubmit()
    },

    handleSettingsSubmit: function () {
      if (!this.phosphorQuantityInputState) {
        return
      }

      const payload = {
        weirInfo: this.currentWeir,
        phosphorQuantity: this.phosphorQuantityform
      }
      this.updateWeirPhosphorQuantity(payload).then(() => {
        this.showSettingsModal = false
      })
    },

    handleSetYear: function (year) {
      this.yearSelected = year

      if (this.year === year) {
        return
      }

      const payload = { year: year }
      this.updateYear(payload)
      this.clearAllStatuses()
      var self = this
      setTimeout(function () {
        if (self.weirInfos && self.isLoaded) {
          self.loadAllStatuses(payload)
        }
      }, 100)
    },

    getFactorX: function (weirInfo) {
      let pQuatity = this.phosphorQuantity
      if (
        weirInfo &&
        weirInfo.logger &&
        weirInfo.logger.phosphorQuantity != null
      ) {
        pQuatity = weirInfo.logger.phosphorQuantity
      }

      return pQuatity / 1000
    },

    getWeirName: function (weirInfo) {
      return weirInfo.checked ? weirInfo.logger.name : ''
    },

    getWeirSize: function (weirInfo) {
      return weirInfo.checked ? '2x' : 'sm'
    },

    getWeirColor: function (weirInfo) {
      return weirInfo.checked ? '#dc3545' : '#6c757d'
    },

    setShowVolume: function () {
      this.showColumnState = VOLUME
    },

    setShowLength: function () {
      this.showColumnState = LENGTH
    },

    setShowEventCount: function () {
      this.showColumnState = EVENT_COUNT
    },

    setShowKgP: function () {
      this.showColumnState = KGP
    },

    handleFilterClick: function () {
      this.filterShown = !this.filterShown
    },

    toFormattedLastOverflowTime: function (weirInfo) {
      if (weirInfo.lastTimeInOverflow) {
        let lastOverflowDate = parseSensorDateStringToDate(
          weirInfo.lastTimeInOverflow
        )
        if (lastOverflowDate.getFullYear() === this.yearSelected) {
          return toSensorDateString(lastOverflowDate)
        }
      }

      return '-'
    },

    zoomUpdated: function (zoom) {
      this.pageSettings.zoom = zoom
      this.updatePageSettings(this.pageSettings)
    },

    centerUpdated: function (center) {
      this.pageSettings.center = center
      this.updatePageSettings(this.pageSettings)
    }
  },

  created () {
    const numberOfLastYears = 10
    const currentYear = new Date().getFullYear()
    let yearStored = this.year
    if (
      isNaN(yearStored) ||
      (yearStored > currentYear && yearStored < currentYear - numberOfLastYears)
    ) {
      yearStored = currentYear
    }

    const yearInfoList = []
    for (
      let year = currentYear;
      year >= currentYear - numberOfLastYears;
      year--
    ) {
      yearInfoList.push({ value: year, text: year + '' })
    }

    this.yearOptions = yearInfoList
    this.yearSelected = yearStored

    if (
      this.weirInfos.length === 0 ||
      this.weirInfos.filter(l => l.logger == null || l.logger.type == null)
        .length > 0
    ) {
      this.loadOverflow()
    }

    if (
      this.pagesSettings.length > 0 &&
      this.pagesSettings.find(ps => ps.page === WEIR)
    ) {
      var foundSettings = this.pagesSettings.find(ps => ps.page === WEIR)

      this.pageSettings.center = foundSettings.center
      this.pageSettings.zoom = foundSettings.zoom
      this.pageSettings.leftPaneSize = foundSettings.leftPaneSize
      this.pageSettings.rightPaneSize = foundSettings.rightPaneSize
      this.leftPaneSize = foundSettings.leftPaneSize ?? 30
      this.rightPaneSize = foundSettings.rightPaneSize ?? 70
      this.filterShown = foundSettings.filterShown
      this.filterSearchText = foundSettings.filterSearchText
        ? foundSettings.filterSearchText
        : ''
      this.filterSliderValue = foundSettings.filterSliderValue
      this.showColumnState = foundSettings.showColumnState
    } else {
      this.pageSettings.center = {
        lat: (this.userExtent.minY + this.userExtent.maxY) / 2,
        lng: (this.userExtent.minX + this.userExtent.maxX) / 2
      }

      this.pageSettings.zoom = 12
      this.pageSettings.page = WEIR
      this.pageSettings.filterShown = this.filterShown
      this.pageSettings.filterSliderValue = this.filterSliderValue
      this.pageSettings.filterSearchText = this.filterSearchText
      this.pageSettings.showColumnState = this.showColumnState
      this.pageSettings.leftPaneSize = 30
      this.pageSettings.rightPaneSize = 70

      this.leftPaneSize = 30
      this.rightPaneSize = 70

      this.updatePageSettings(this.pageSettings)
    }

    console.log(this.pageSettings)
  },

  watch: {
    isLoaded: function () {
      if (this.weirInfos && this.isLoaded) {
        this.loadAllStatuses({ year: this.year })
      }
    },
    filterShown: function () {
      this.pageSettings.filterShown = this.filterShown
      this.updatePageSettings(this.pageSettings)
    },
    filterSearchText: function () {
      this.pageSettings.filterSearchText = this.filterSearchText
      this.updatePageSettings(this.pageSettings)
    },
    filterSliderValue: function () {
      this.pageSettings.filterSliderValue = this.filterSliderValue
      this.updatePageSettings(this.pageSettings)
    },
    showColumnState: function () {
      this.pageSettings.showColumnState = this.showColumnState
      this.updatePageSettings(this.pageSettings)
    }
  },

  data () {
    return {
      fields: [
        {
          key: 'id',
          label: 'SID/Navn',
          sortable: true
        },
        {
          key: 'system',
          label: 'Sted',
          sortable: true
        },
        {
          key: 'lastOverflow',
          label: 'Siste overløp',
          sortable: true
        },
        {
          key: 'overflowHours',
          label: 'Driftstid, t',
          sortable: true
        },
        {
          key: 'overflowVolume',
          label: 'Mengde, m³',
          sortable: true
        },
        {
          key: 'overflowEventCount',
          label: 'Antall',
          sortable: true
        },
        {
          key: 'kgP',
          label: 'KgP',
          sortable: true
        },
        {
          key: 'isPumpstation',
          label: 'Pumpe',
          sortable: true
        },
        {
          key: 'area',
          label: 'Hektar',
          sortable: true
        },
        {
          key: 'systemOrder',
          label: 'Nr',
          sortable: true
        }
      ],
      sortBy: 'systemOrder',
      sortDesc: false,
      pageSettings: {
        page: WEIR,
        zoom: 12,
        center: {},
        filterShown: false,
        filterSearchText: '',
        filterSliderValue: 0,
        showColumnState: LENGTH,
        leftPaneSize: 30,
        rightPaneSize: 70
      },
      leftPaneSize: 30,
      rightPaneSize: 70,
      allChecked: false,
      showSettingsModal: false,
      phosphorQuantityform: null,
      currentWeir: null,
      yearSelected: 0,
      yearOptions: [],
      selectedWeirs: [],
      filterSliderValue: 0,
      filterSearchText: '',
      showColumnState: LENGTH,
      minDate: new Date('01-01-1990'),
      filterShown: false,
      url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
      scroll_settings: {
        suppressScrollX: true
      },
      sliderOptions: {
        eventType: 'auto',
        width: 'auto',
        height: 6,
        dotSize: 16,
        dotHeight: null,
        dotWidth: null,
        min: 0,
        max: 100,
        interval: 1,
        show: true,
        speed: 0.5,
        disabled: false,
        piecewise: false,
        piecewiseStyle: null,
        piecewiseLabel: false,
        tooltip: 'always',
        tooltipDir: 'top',
        reverse: false,
        data: null,
        clickable: true,
        realTime: false,
        lazy: false,
        formatter: null,
        bgStyle: null,
        sliderStyle: null,
        processStyle: {
          backgroundColor: '#28a745'
        },
        tooltipStyle: null,
        piecewiseActiveStyle: null
      }
    }
  }
}
</script>
