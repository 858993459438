import { OVERFLOW_REPORT_REQUEST_URL, UNAUTHORIZED } from '../actions/backend'
import {
  OVERFLOW_YEAR_REPORT_REQUEST,
  OVERFLOW_YEAR_REPORT_CLEARING,
  OVERFLOW_YEAR_REPORT_REQUEST_SUCCESS,
  OVERFLOW_YEAR_REPORT_REQUEST_ERROR,
  OVERFLOW_YEAR_REPORT_REQUEST_ATTEMPT_ERROR
} from '../mutation-types'
import {
  REQUEST_REFRESH_TOKEN,
  REQUEST_OVERFLOW_YEAR_REPORT,
  CLEAR_OVERFLOW_YEAR_REPORT
} from '../action-types'
import { AUTH_TOKEN_KEY } from '../storage-keys'
import axios from 'axios'

const actions = {
  [CLEAR_OVERFLOW_YEAR_REPORT]: ({ commit, dispatch }, param) => {
    commit(OVERFLOW_YEAR_REPORT_CLEARING, param.loggerId)
    dispatch(REQUEST_OVERFLOW_YEAR_REPORT, param)
  },
  [REQUEST_OVERFLOW_YEAR_REPORT]: ({ commit, dispatch }, param) => {
    var requestUrl =
      OVERFLOW_REPORT_REQUEST_URL +
      '?findLastTime=true&year=' +
      encodeURIComponent(param.year)
    return new Promise((resolve, reject) => {
      commit(OVERFLOW_YEAR_REPORT_REQUEST, param)
      axios({
        url: requestUrl,
        method: 'post',
        data: [param.loggerId],
        type: 'json',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem(AUTH_TOKEN_KEY) || '',
          Accept: 'application/json, text/plain, */*',
          'Cache-Control': 'no-cache'
        }
      })
        .then(resp => {
          commit(OVERFLOW_YEAR_REPORT_REQUEST_SUCCESS, {
            loggerId: param.loggerId,
            year: param.year,
            report: resp.data
          })
          resolve(resp)
        })
        .catch(err => {
          if (err.response != null && err.response.status === UNAUTHORIZED) {
            commit(OVERFLOW_YEAR_REPORT_REQUEST_ATTEMPT_ERROR, param)
            dispatch(REQUEST_REFRESH_TOKEN, {
              previousRequest: REQUEST_OVERFLOW_YEAR_REPORT,
              param: param
            })
          } else {
            commit(OVERFLOW_YEAR_REPORT_REQUEST_ERROR, param)
          }
          reject(err)
        })
    })
  }
}

export default {
  actions
}
