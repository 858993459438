<template>
  <LoggerInfoDetails logger_type="flow"></LoggerInfoDetails>
</template>

<script>
import LoggerInfoDetails from '../details'

export default {
  name: 'flow_details',
  components: { LoggerInfoDetails }
}
</script>
