import {
  PHOSPHOR_QUANTITY_URL,
  LOGIN_URL,
  AUTH_CLIENT_ID,
  USER_INFO_URL,
  ERROR,
  SUCCESS,
  LOADING
} from '../actions/backend'
import {
  AUTH_TOKEN_KEY,
  RESOURCES_KEY,
  REFRESH_TOKEN_KEY,
  USER_NAME_KEY,
  EXTENT_KEY,
  USER_OPTIONS
} from '../storage-keys'
import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_ERROR,
  AUTH_LOGOUT,
  AUTH_REFRESH_TOKEN_REQUEST,
  AUTH_REFRESH_TOKEN_SUCCESS,
  AUTH_REFRESH_TOKEN_ERROR,
  USER_INFO_REQUEST,
  USER_INFO_REQUEST_SUCCESS,
  USER_INFO_REQUEST_ERROR,
  PHOSPHOR_QUANTITY_UPDATING,
  PHOSPHOR_QUANTITY_UPDATING_SUCCESS,
  PHOSPHOR_QUANTITY_UPDATING_ERROR,
  PHOSPHOR_QUANTITY_GETTING,
  PHOSPHOR_QUANTITY_GETTING_SUCCESS,
  PHOSPHOR_QUANTITY_GETTING_ERROR
} from '../mutation-types'
import { WEIR } from '../../utils/logger_consts'
import {
  GET_PHOSPHOR_QUANTITY,
  UPDATE_PHOSPHOR_QUANTITY,
  AUTHORIZE,
  LOGOUT,
  REQUEST_REFRESH_TOKEN,
  REQUEST_USER_INFO
} from '../action-types'
import axios from 'axios'
import qs from 'qs'

const state = {
  authToken: localStorage.getItem(AUTH_TOKEN_KEY),
  authStatus: '',
  refreshStatus: '',
  refreshCount: 0,
  refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY),
  name: localStorage.getItem(USER_NAME_KEY),
  extent:
    localStorage.getItem(EXTENT_KEY) != null
      ? JSON.parse(localStorage.getItem(EXTENT_KEY))
      : null,
  phosphorQuantityStatus: '',
  phosphorQuantity: 0,
  resources:
    localStorage.getItem(RESOURCES_KEY) != null
      ? JSON.parse(localStorage.getItem(RESOURCES_KEY))
      : [],
  userOptions: 
    localStorage.getItem(USER_OPTIONS) != null
      ? JSON.parse(localStorage.getItem(USER_OPTIONS))
      : {},
}

const getters = {
  isAuthenticated: state => !!state.authToken,
  authStatus: state => state.authStatus,
  refreshStatus: state => state.refreshStatus,
  authToken: state => state.authToken,
  refreshToken: state => state.refreshToken,
  userExtent: state => state.extent,
  userName: state => state.name,
  userOptions: state => state.userOptions,
  phosphorQuantityStatus: state => state.phosphorQuantityStatus,
  phosphorQuantity: state => state.phosphorQuantity,
  resources: state => state.resources,
  hasResource: state => resource => {
    return state.resources.find(r => r === resource) !== undefined
  }
}

const actions = {
  [AUTHORIZE]: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST)
      axios({
        url: LOGIN_URL,
        method: 'POST',
        data: qs.stringify({
          username: user.username,
          password: user.password,
          scope: 'openid regnbyge offline_access',
          grant_type: 'password'
        }),
        headers: {
          Authorization: 'Basic ' + AUTH_CLIENT_ID,
          'Cache-Control': 'no-cache'
        }
      })
        .then(resp => {
          commit(AUTH_SUCCESS, resp)
          dispatch(REQUEST_USER_INFO)
          resolve(resp)
        })
        .catch(err => {
          commit(AUTH_ERROR, err)
          reject(err)
        })
    })
  },
  [LOGOUT]: ({ commit}) => {
    return new Promise((resolve) => {
      commit(AUTH_LOGOUT)
      resolve()
    })
  },
  [REQUEST_REFRESH_TOKEN]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REFRESH_TOKEN_REQUEST)
      if (state.refreshCount < 5) {
        axios({
          url: LOGIN_URL,
          method: 'POST',
          data: qs.stringify({
            grant_type: 'refresh_token',
            refresh_token: state.refreshToken
          }),
          headers: {
            Authorization: 'Basic ' + AUTH_CLIENT_ID,
            'Cache-Control': 'no-cache'
          }
        })
          .then(resp => {
            commit(AUTH_REFRESH_TOKEN_SUCCESS, resp)
            resolve(resp)
            dispatch(params.previousRequest, params.param)
          })
          .catch(err => {
            commit(AUTH_REFRESH_TOKEN_ERROR, err)
            reject(err)
          })
      } else {
        commit(AUTH_REFRESH_TOKEN_ERROR)
      }
    })
  },
  [REQUEST_USER_INFO]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit(USER_INFO_REQUEST)
      axios({
        url: USER_INFO_URL,
        method: 'GET',
        type: 'json',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem(AUTH_TOKEN_KEY) || '',
          Accept: 'application/json, text/plain, */*',
          'Cache-Control': 'no-cache'
        }
      })
        .then(resp => {
          commit(USER_INFO_REQUEST_SUCCESS, resp)
          dispatch(GET_PHOSPHOR_QUANTITY)
          resolve(resp)
        })
        .catch(err => {
          commit(USER_INFO_REQUEST_ERROR, err)
          reject(err)
        })
    })
  },
  [GET_PHOSPHOR_QUANTITY]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      if (state.resources.find(r => r === WEIR) === undefined) {
        return
      }

      commit(PHOSPHOR_QUANTITY_GETTING)
      axios({
        url:
          PHOSPHOR_QUANTITY_URL,
        method: 'get',
        type: 'json',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem(AUTH_TOKEN_KEY) || '',
          Accept: 'application/json, text/plain, */*',
          'Cache-Control': 'no-cache'
        }
      })
        .then(resp => {
          commit(PHOSPHOR_QUANTITY_GETTING_SUCCESS, resp)
          resolve(resp)
        })
        .catch(err => {
          commit(PHOSPHOR_QUANTITY_GETTING_ERROR, err)
          reject(err)
        })
    })
  },
  [UPDATE_PHOSPHOR_QUANTITY]: ({ commit }, param) => {
    return new Promise((resolve, reject) => {
      commit(PHOSPHOR_QUANTITY_UPDATING)
      axios({
        url:
          PHOSPHOR_QUANTITY_URL + '?quantity=' + param.phosphorQuantity,
        method: 'put',
        type: 'json',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem(AUTH_TOKEN_KEY) || '',
          Accept: 'application/json, text/plain, */*',
          'Cache-Control': 'no-cache'
        }
      })
        .then(resp => {
          commit(PHOSPHOR_QUANTITY_UPDATING_SUCCESS, {
            resp: resp,
            param: param
          })
          resolve(resp)
        })
        .catch(err => {
          commit(PHOSPHOR_QUANTITY_UPDATING_ERROR, err)
          reject(err)
        })
    })
  }
}

const mutations = {
  [AUTH_REQUEST]: state => {
    state.authStatus = LOADING
    state.refreshStatus = ''
    state.authToken = ''
    state.refreshToken = ''
  },
  [AUTH_SUCCESS]: (state, resp) => {
    state.authToken = resp.data.access_token
    state.refreshToken = resp.data.refresh_token
    localStorage.setItem(AUTH_TOKEN_KEY, resp.data.access_token)
    localStorage.setItem(REFRESH_TOKEN_KEY, resp.data.refresh_token)
  },
  [AUTH_ERROR]: state => {
    state.authStatus = ERROR
    state.authToken = ''
    localStorage.removeItem(AUTH_TOKEN_KEY)
    localStorage.removeItem(REFRESH_TOKEN_KEY)
  },
  [AUTH_LOGOUT]: state => {
    state.authToken = ''
    state.refreshToken = ''
    localStorage.removeItem(AUTH_TOKEN_KEY)
    localStorage.removeItem(REFRESH_TOKEN_KEY)
    localStorage.removeItem(EXTENT_KEY)
    localStorage.removeItem(USER_NAME_KEY)
    localStorage.removeItem(RESOURCES_KEY)
    localStorage.removeItem(USER_OPTIONS)
  },
  [AUTH_REFRESH_TOKEN_REQUEST]: state => {
    state.authStatus = LOADING
    state.refreshStatus = LOADING
    state.authToken = ''
    state.refreshCount++
  },
  [AUTH_REFRESH_TOKEN_SUCCESS]: (state, resp) => {
    state.authStatus = SUCCESS
    state.refreshStatus = SUCCESS
    state.authToken = resp.data.access_token
    state.refreshToken = resp.data.refresh_token
    localStorage.setItem(AUTH_TOKEN_KEY, resp.data.access_token)
    localStorage.setItem(REFRESH_TOKEN_KEY, resp.data.refresh_token)
  },
  [AUTH_REFRESH_TOKEN_ERROR]: state => {
    state.authStatus = ERROR
    state.refreshStatus = ERROR
    state.authToken = ''
    state.refreshCount = 0
    localStorage.removeItem(AUTH_TOKEN_KEY)
    localStorage.removeItem(REFRESH_TOKEN_KEY)
  },
  [USER_INFO_REQUEST]: state => {
    state.authStatus = LOADING
  },
  [USER_INFO_REQUEST_SUCCESS]: (state, resp) => {
    state.authStatus = SUCCESS
    state.name = resp.data.userName
    state.extent = resp.data.extent
    state.resources = resp.data.resources
    state.userOptions = resp.data.options
    localStorage.setItem(RESOURCES_KEY, JSON.stringify(resp.data.resources))
    localStorage.setItem(EXTENT_KEY, JSON.stringify(resp.data.extent))
    localStorage.setItem(USER_NAME_KEY, resp.data.userName)
    localStorage.setItem(USER_OPTIONS, JSON.stringify(resp.data.options))
    state.refreshCount = 0
  },
  [USER_INFO_REQUEST_ERROR]: state => {
    state.authStatus = ERROR
    state.authToken = ''
    localStorage.removeItem(AUTH_TOKEN_KEY)
    localStorage.removeItem(REFRESH_TOKEN_KEY)
  },
  [PHOSPHOR_QUANTITY_UPDATING]: state => {
    state.phosphorQuantityStatus = LOADING
  },
  [PHOSPHOR_QUANTITY_UPDATING_SUCCESS]: (state, param) => {
    state.phosphorQuantityStatus = SUCCESS
    state.phosphorQuantity = param.param.phosphorQuantity
  },
  [PHOSPHOR_QUANTITY_UPDATING_ERROR]: state => {
    state.phosphorQuantityStatus = ERROR
  },
  [PHOSPHOR_QUANTITY_GETTING]: state => {
    state.authStatus = LOADING
  },
  [PHOSPHOR_QUANTITY_GETTING_SUCCESS]: (state, resp) => {
    state.authStatus = SUCCESS
    state.phosphorQuantity = resp.data.value
  },
  [PHOSPHOR_QUANTITY_GETTING_ERROR]: state => {
    state.authStatus = ERROR
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
