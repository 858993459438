<template>
  <b-container fluid>
    <b-row>
      <b-col md="6" class="text-left">
        <b-btn
          variant="outline-secondary"
          :size="'sm'"
          style="margin: auto;"
          @click="goBack()"
          ><font-awesome-icon icon="arrow-left" />&nbsp;Tilbake</b-btn
        >
      </b-col>
    </b-row>
    <b-row class="report_page_row">
      <b-col md="4" class="mx-auto text-center">
        <div
          class="mx-auto img-circle center"
          style="width: 100px; height: 100px"
        >
          <font-awesome-icon
            icon="file-alt"
            size="4x"
            style="margin-left: 25px;"
          />
        </div>
      </b-col>
    </b-row>
    <b-row class="report_page_row">
      <b-col md="4" class="mx-auto text-center">
        <h4>{{getDownloadText}}</h4>
      </b-col>
    </b-row>
    <b-row class="report_page_row">
      <b-col md="2" class="mx-auto text-left">
        Fra dato:
        <b-form-input
          v-model="startDate"
          :size="'sm'"
          :type="'date'"
          :state="startDateState"
        ></b-form-input>
        <b-form-invalid-feedback
          v-if="!$v.startDate.required && isReportClicked"
          >Fra dato er obligatorisk</b-form-invalid-feedback
        >
        <b-form-invalid-feedback
          v-if="isEndDateBeforeStartDate"
          >Fra dato skal være før til dato</b-form-invalid-feedback
        >
        <b-form-invalid-feedback
          v-if="!isDatesPeriodValid"
          >Valgt periode bør være mindre enn {{this.selectedMaximumPeriodInDays}} dager</b-form-invalid-feedback
        >
      </b-col>
    </b-row>
    <b-row class="report_page_row">
      <b-col md="2" class="mx-auto text-left">
        Til dato:
        <b-form-input
          v-model="endDate"
          :size="'sm'"
          :type="'date'"
          :state="endDateState"
        ></b-form-input>
        <b-form-invalid-feedback
          v-if="!$v.endDate.required && isReportClicked"
          >Til dato er obligatorisk</b-form-invalid-feedback
        >
        <b-form-invalid-feedback
          v-if="isEndDateBeforeStartDate"
          >Fra dato skal være før til dato</b-form-invalid-feedback
        >
        <b-form-invalid-feedback
          v-if="!isDatesPeriodValid"
          >Valgt periode bør være mindre enn {{this.selectedMaximumPeriodInDays}} dager</b-form-invalid-feedback
        >
      </b-col>
    </b-row>
    <b-row class="report_page_row">
      <b-col md="2" class="mx-auto text-left">
        Eksporttype:
        <b-form-select
          id="btn_report_format"
          size="sm"
          v-model="selectedFileFormat"
          :options="reportFormatOptions"
        />
      </b-col>
    </b-row>
    <b-row class="report_page_row">
      <b-col md="4" class="mx-auto text-center">
        <b-form-radio-group
          id="btnradios3"
          stacked
          buttons
          size="sm"
          v-model="selectedDownloadConstraint"
          :options="reportConstraintOptions"
          button-variant="outline-lightgray"
          class="btn-block"
          name="radioBtnStacked"
          style="max-width: 240px"
        />
      </b-col>
    </b-row>
    <b-row v-if="!reportForAll && !reportForActive && !reportForHistorical" class="report_page_row">
      <b-col md="4" lg="3" class="mx-auto text-left">
        <b-card bg-variant="light">
          <VuePerfectScrollbar
            :settings="scroll_settings"
            class="report_page_wrapper"
          >
            <span v-if="activeLoggerOptions.length > 0" class="report_logger_list_divider">I drift:</span>
            <b-form-checkbox-group
              variant="secondary"
              v-model="selectedActiveLoggerIds"
              stacked
              size="sm"
              :options="activeLoggerOptions"
            />
            <span v-if="historicalLoggerOptions.length > 0" class="report_logger_list_divider">Historiske:</span>
            <b-form-checkbox-group
              variant="secondary"
              class="historical"
              v-model="selectedHistoricalLoggerIds"
              stacked
              size="sm"
              :options="historicalLoggerOptions"
            />
          </VuePerfectScrollbar>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="report_page_row">
      <b-col md="4" class="mx-auto text-center">
        <b-btn
          variant="secondary"
          class="btn-block btn-blue"
          v-bind:disabled="isLoading || !hasLoggersForDownload"
          style="display: inline; max-width:240px;"
          @click="handleReportClick()"
        >
          <font-awesome-icon v-if="isLoading" icon="spinner" spin /><span
            v-if="!isLoading"
            >Opprett</span
          >
        </b-btn>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { LOADING, SUCCESS } from '../../../store/actions/backend.js'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { REQUEST_LOGGER_EXPORT, REQUEST_LOGGER_FILE_FORMATS } from '../../../store/action-types'
import { LEVEL, FLOW, RAIN } from '../../../utils/logger_consts'
import { parseDateWithoutTimeStringToDate } from '../../../utils/Date'
import { required } from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'

const DOWNLOAD_CONSTRAINT_ALL_LOGGERS = 'all_loggers'
const DOWNLOAD_CONSTRAINT_SELECTED_LOGGERS = 'selected_loggers'
const DOWNLOAD_CONSTRAINT_ACTIVE_LOGGERS = 'active_loggers'
const DOWNLOAD_CONSTRAINT_HISTORICAL_LOGGERS = 'historical_loggers'

export default {
  name: 'export-form',
  components: {
    VuePerfectScrollbar
  },

  computed: {
    ...mapGetters(['loggerInfos', 'logger_report_status', 'fileFormats', 'fileFormatsStatus', 'year']),

    reportForAll: function () {
      return this.selectedDownloadConstraint === DOWNLOAD_CONSTRAINT_ALL_LOGGERS
    },

    reportForActive: function () {
      return this.selectedDownloadConstraint === DOWNLOAD_CONSTRAINT_ACTIVE_LOGGERS
    },

    reportForHistorical: function () {
      return this.selectedDownloadConstraint === DOWNLOAD_CONSTRAINT_HISTORICAL_LOGGERS
    },

    hasLoggersForDownload: function () {
      return this.effectiveLoggerIds.length > 0
    },

    currentTypeLoggers: function () {
      return this.loggerInfos.filter(entry => entry.logger.logger.type.toLowerCase().includes(this.loggerType))
    },

    currentFileFormats: function () {
      return this.fileFormats.filter(entry => entry.loggerType.toLowerCase() === this.loggerType)
    },

    selectedMaximumPeriodInDays: function () {
      if (this.currentFileFormats.length === 1 && this.selectedFileFormat !== null) {
        var constraints = this.currentFileFormats[0].fileFormats.find(element => element.name === this.selectedFileFormat).constraints
        if (constraints !== null && constraints.maximumPeriodInDays !== 0) {
          return constraints.maximumPeriodInDays
        }
      }

      return 0
    },

    currentTypeActiveLoggers: function () {
      return this.loggerInfos.filter(entry => entry.logger.logger.isActive && entry.logger.logger.type.toLowerCase().includes(this.loggerType))
    },

    currentTypeHistoricalLoggers: function () {
      return this.loggerInfos.filter(entry => !entry.logger.logger.isActive && entry.logger.logger.type.toLowerCase().includes(this.loggerType))
    },

    effectiveLoggerIds: function () {
      if (this.reportForAll) {
        return this.currentTypeLoggers.map(element => element.logger.logger.id)
      }

      if (this.reportForActive) {
        return this.currentTypeActiveLoggers.map(element => element.logger.logger.id)
      }

      if (this.reportForHistorical) {
        return this.currentTypeHistoricalLoggers.map(element => element.logger.logger.id)
      }

      return this.selectedActiveLoggerIds.concat(this.selectedHistoricalLoggerIds)
    },

    isfileFormatsLoading: function () {
      return this.fileFormatsStatus === SUCCESS || this.status === ''
    },

    isLoading: function () {
      return this.logger_report_status === LOADING
    },

    isLevelDownloadPage: function () {
      return this.$route.name === LEVEL + 'Download'
    },

    isFlowDownloadPage: function () {
      return this.$route.name === FLOW + 'Download'
    },

    isRainDownloadPage: function () {
      return this.$route.name === RAIN + 'Download'
    },

    loggerType: function () {
      if (this.isLevelDownloadPage) {
        return LEVEL
      }

      if (this.isFlowDownloadPage) {
        return FLOW
      }

      if (this.isRainDownloadPage) {
        return RAIN
      }

      return 'unknown'
    },

    getDownloadText: function () {
      return 'Last ned data'
    },

    startDateState: function () {
      return ((this.startDate === null || this.startDate === '') && !this.isReportClicked)
        ? null
        : (!this.isEndDateBeforeStartDate && this.startDate !== null && this.startDate !== '' && this.isDatesPeriodValid)
    },

    endDateState: function () {
      return ((this.endDate === null || this.endDate === '') && !this.isReportClicked)
        ? null
        : (!this.isEndDateBeforeStartDate && this.endDate !== null && this.endDate !== '' && this.isDatesPeriodValid)
    },

    isEndDateBeforeStartDate: function () {
      return !(!(this.startDate !== null && this.startDate !== '') || !(this.endDate !== null && this.endDate !== '') || (this.startDate <= this.endDate))
    },

    isDatesPeriodValid: function () {
      if ((this.startDate !== null && this.startDate !== '') && (this.endDate !== null && this.endDate !== '') && (this.startDate <= this.endDate) && this.selectedMaximumPeriodInDays > 0) {
        var minFrom = new Date(parseDateWithoutTimeStringToDate(this.endDate))
        minFrom.setDate(minFrom.getDate() - this.selectedMaximumPeriodInDays)
        var from = new Date(parseDateWithoutTimeStringToDate(this.startDate))
        return minFrom <= from
      }

      return true
    },

    isFormValid: function () {
      return this.startDateState && this.endDateState && this.isDatesPeriodValid
    },

    reportConstraintOptions: function () {
      return [
        { text: 'For alle', value: DOWNLOAD_CONSTRAINT_ALL_LOGGERS, disabled: this.currentTypeLoggers.length === 0 },
        { text: 'For i drift', value: DOWNLOAD_CONSTRAINT_ACTIVE_LOGGERS, disabled: this.currentTypeActiveLoggers.length === 0 },
        { text: 'For historiske', value: DOWNLOAD_CONSTRAINT_HISTORICAL_LOGGERS, disabled: this.currentTypeHistoricalLoggers.length === 0 },
        { text: 'For utvalg', value: DOWNLOAD_CONSTRAINT_SELECTED_LOGGERS, disabled: this.currentTypeLoggers.length === 0 }
      ]
    }
  },

  data () {
    return {
      activeLoggerOptions: [],
      historicalLoggerOptions: [],
      selectedActiveLoggerIds: [],
      selectedHistoricalLoggerIds: [],
      reportFormatOptions: [],
      isReportClicked: false,
      startDate: null,
      endDate: null,
      selectedDownloadConstraint: DOWNLOAD_CONSTRAINT_ALL_LOGGERS,
      selectedFileFormat: null,
      scroll_settings: {
        suppressScrollX: true
      }
    }
  },

  validations: {
    startDate: {
      required
    },
    endDate: {
      required
    }
  },

  methods: {
    ...mapActions({
      loadReport: REQUEST_LOGGER_EXPORT,
      loadFileFormats: REQUEST_LOGGER_FILE_FORMATS
    }),

    handleReportClick: function () {
      this.isReportClicked = true
      if (!this.isFormValid) {
        return
      }

      if (!this.isLoading && this.loggerInfos) {
        this.loadReport({
          loggerIds: this.effectiveLoggerIds,
          reportType: this.selectedFileFormat,
          startDate: this.startDate,
          endDate: this.endDate
        })
      }
    },
    hasHistory: function () { return window.history.length > 1 },
    goBack: function () {
      if (this.hasHistory()) {
        this.$router.go(-1)
      } else {
        this.$router.push('/' + this.loggerType)
      }
    }
  },

  created () {
    this.loadFileFormats()
    if (this.loggerInfos) {
      this.activeLoggerOptions = this.loggerInfos.filter(entry => entry.logger.logger.isActive && entry.logger.logger.type.toLowerCase().includes(this.loggerType))
        .map(entry => {
          let text = entry.logger.logger.name + ''
          if (entry.logger.logger.location && entry.logger.logger.location.length > 0) {
            text += ' (' + entry.logger.logger.location + ')'
          }

          return { text: text, value: entry.logger.logger.id }
        })

      this.historicalLoggerOptions = this.loggerInfos.filter(entry => !entry.logger.logger.isActive && entry.logger.logger.type.toLowerCase().includes(this.loggerType))
        .map(entry => {
          let text = entry.logger.logger.name + ''
          if (entry.logger.logger.location && entry.logger.logger.location.length > 0) {
            text += ' (' + entry.logger.logger.location + ')'
          }

          return { text: text, value: entry.logger.logger.id }
        })
    }
  },

  watch: {
    currentFileFormats: function () {
      this.reportFormatOptions = []

      if (this.currentFileFormats.length === 1) {
        this.reportFormatOptions = this.currentFileFormats[0].fileFormats.map(element => {
          var text = element.displayName
          if (element.constraints && element.constraints.maximumPeriodInDays > 0) {
            text += ' (' + element.constraints.maximumPeriodInDays + ' dager)'
          }

          return { text: text, value: element.name }
        })
      }
    }
  }
}
</script>
