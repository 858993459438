import {
  OVERFLOW_REPORT_REQUEST_URL,
  OVERFLOW_REPORT_BRIEF_REQUEST_URL,
  LOGGER_REPORT_REQUEST_URL,
  ERROR,
  SUCCESS,
  LOADING,
  UNAUTHORIZED
} from '../actions/backend'
import {
  REPORT_REQUEST,
  REPORT_REQUEST_SUCCESS,
  REPORT_REQUEST_ERROR,
  LOGGER_REPORT_REQUEST,
  LOGGER_REPORT_REQUEST_SUCCESS,
  LOGGER_REPORT_REQUEST_ERROR
} from '../mutation-types'
import {
  REQUEST_REFRESH_TOKEN,
  REQUEST_REPORT,
  REQUEST_LOGGER_EXPORT
} from '../action-types'
import { AUTH_TOKEN_KEY } from '../storage-keys'
import { parseDateWithoutTimeStringToDate, toUrlDateString } from '../../utils/Date'
import axios from 'axios'

const state = {
  report_status: '',
  logger_report_status: ''
}

const getters = {
  report_status: state => state.report_status,
  logger_report_status: state => state.logger_report_status
}

const actions = {
  [REQUEST_REPORT]: (
    { commit, dispatch },
    { loggerIds, isPdf, year, isBrief }
  ) => {
    return new Promise((resolve, reject) => {
      commit(REPORT_REQUEST)
      axios({
        url:
          (isBrief
            ? OVERFLOW_REPORT_BRIEF_REQUEST_URL
            : OVERFLOW_REPORT_REQUEST_URL) +
          (year == null ? '' : '?year=' + year),
        method: 'POST',
        type: 'json',
        data: loggerIds,
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem(AUTH_TOKEN_KEY) || '',
          Accept: isPdf ? 'application/pdf' : 'text/csv',
          'Cache-Control': 'no-cache'
        }
      })
        .then(resp => {
          const url = window.URL.createObjectURL(new Blob([resp.data]))
          const link = document.createElement('a')
          link.href = url
          let reportName = year == null ? 'rapport' : 'rapport-' + year
          link.setAttribute(
            'download',
            isPdf ? reportName + '.pdf' : reportName + '.csv'
          )
          document.body.appendChild(link)
          link.click()
          commit(REPORT_REQUEST_SUCCESS)
          resolve(resp)
        })
        .catch(err => {
          if (err.response != null && err.response.status === UNAUTHORIZED) {
            dispatch(REQUEST_REFRESH_TOKEN, {
              previousRequest: REQUEST_REPORT,
              param: { loggerIds, isPdf, year, isBrief }
            })
          } else {
            commit(REPORT_REQUEST_ERROR, err)
          }
          reject(err)
        })
    })
  },
  [REQUEST_LOGGER_EXPORT]: (
    { commit, dispatch },
    { loggerIds, reportType, startDate, endDate }
  ) => {
    var to = new Date(parseDateWithoutTimeStringToDate(endDate))
    to.setTime(to.getTime() + (24 * 60 * 60 * 1000 - 1000))
    var data = {ids: loggerIds, from: encodeURIComponent(startDate), to: toUrlDateString(to), fileType: reportType}
    return new Promise((resolve, reject) => {
      commit(LOGGER_REPORT_REQUEST)
      axios({
        url:
          LOGGER_REPORT_REQUEST_URL,
        method: 'POST',
        type: 'json',
        data: data,
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem(AUTH_TOKEN_KEY) || '',
          Accept: 'application/zip, application/pdf, text/csv',
          'Cache-Control': 'no-cache'
        }
      })
        .then(resp => {
          const url = window.URL.createObjectURL(new Blob([resp.data]))
          const link = document.createElement('a')
          link.href = url
          let filename = ''
          let contentDisposition = resp.headers['content-disposition']

          if (contentDisposition != null && contentDisposition.includes('filename=')) {
            filename = contentDisposition.split('filename=')[1].split(';')[0].trim()
          }

          if (filename == null || filename.length === 0) {
            filename = 'download.zip'
          }

          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
          commit(LOGGER_REPORT_REQUEST_SUCCESS)
          resolve(resp)
        })
        .catch(err => {
          if (err.response != null && err.response.status === UNAUTHORIZED) {
            dispatch(REQUEST_REFRESH_TOKEN, {
              previousRequest: REQUEST_REPORT,
              param: { loggerIds, reportType, startDate, endDate }
            })
          } else {
            commit(LOGGER_REPORT_REQUEST_ERROR, err)
          }
          reject(err)
        })
    })
  }
}

const mutations = {
  [REPORT_REQUEST]: state => {
    state.report_status = LOADING
  },
  [REPORT_REQUEST_SUCCESS]: state => {
    state.report_status = SUCCESS
  },
  [REPORT_REQUEST_ERROR]: state => {
    state.report_status = ERROR
  },
  [LOGGER_REPORT_REQUEST]: state => {
    state.logger_report_status = LOADING
  },
  [LOGGER_REPORT_REQUEST_SUCCESS]: state => {
    state.logger_report_status = SUCCESS
  },
  [LOGGER_REPORT_REQUEST_ERROR]: state => {
    state.logger_report_status = ERROR
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
