var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isAuthenticated && _vm.hasWeirResource)?_c('div',[_c('Weir')],1):_vm._e(),(_vm.isAuthenticated && _vm.hasLevelResource && !_vm.hasWeirResource)?_c('div',[_c('Level')],1):_vm._e(),(_vm.isAuthenticated && _vm.hasFlowResource && !_vm.hasLevelResource && !_vm.hasWeirResource)?_c('div',[_c('Flow')],1):_vm._e(),(
      _vm.isAuthenticated &&
        _vm.hasRainResource &&
        !_vm.hasFlowResource &&
        !_vm.hasLevelResource &&
        !_vm.hasWeirResource
    )?_c('div',[_c('Rain')],1):_vm._e(),(_vm.showWelcome)?_c('div',[_c('h1',[_vm._v("Welcome to Rosie Online!")]),_c('h2',[_vm._v("You don't have access to any parts of Rosie Online right now!")]),_c('h2',[_vm._v("Ask your manager to add them!")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }