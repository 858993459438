<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <b-container fluid>
          <b-row>
            <b-col md="12" class="text-center pb-1">
              <b-btn
                :size="'sm'"
                variant="outline-secondary"
                class="mr-1"
                @click="handlePreviousPeriodClick()"
              >
                <font-awesome-icon icon="angle-left" />
              </b-btn>
              <b-btn
                :size="'sm'"
                variant="outline-secondary"
                v-b-modal.select_date_modal
                >Velg periode</b-btn
              >
              <b-btn
                :size="'sm'"
                variant="outline-secondary"
                class="ml-1"
                v-bind:disabled="!hasNextPeriod"
                @click="handleNextPeriodClick()"
              >
                <font-awesome-icon icon="angle-right" />
              </b-btn>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <div id="overlay" v-show="!isLoaded">
                <div class="loader">
                  <font-awesome-icon icon="spinner" size="4x" spin />
                </div>
              </div>
              <div id="overlay" v-show="isLoaded && noData">
                <div class="overlay-text">
                  Det finnes ingen data for perioden
                </div>
              </div>
              <div>
                <highcharts class="chart" :options="options" ref="highcharts" />
              </div>
            </b-col>
          </b-row>
        </b-container>
        <b-container fluid>
          <b-row>
            <b-col md="12">
              <reports />
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-modal
      id="select_date_modal"
      headerClass="p-2"
      @show="resetSelectDate"
      button-size="sm"
      centered
      ref="select_date_modal"
      @ok="handleChoosePeriodClick"
      cancel-title="Avbryt"
      title="Vis data for perioden"
    >
      <b-container fluid>
        <b-row
          id="select_date_alert"
          ref="select_date_alert"
          style="display: none"
        >
          <b-col md="12" class="text-center">
            <b-alert show variant="danger">Du bør velge periode</b-alert>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12" class="text-center">
            <month-picker
              lang="no"
              :defaultYear="new Date(firstDate).getFullYear()"
              :no-default="true"
              @change="handleSelectDate"
            ></month-picker>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </b-container>
</template>

<script>
import { asTimeInMilliseconds, toUrlDateString } from '../../../utils/Date.js'
import { SUCCESS } from '../../../store/actions/backend.js'
import { REQUEST_OVERFLOW_DATA } from '../../../store/action-types'
import {
  LEVEL,
  OVERFLOW_LEVEL,
  FLOW,
  OVERFLOW_FLOW,
  RAIN,
  LEVEL_MASHUP,
  FLOW_MASHUP,
  OVERFLOW_FLOW_MASHUP,
  THRESHOLD
} from '../../../utils/logger_consts'
import Reports from './reports'
import MonthPicker from '../../utils/month-picker/MonthPicker'
import { mapGetters, mapActions } from 'vuex'

function updateMashupMeasurements (measurements, mashupMeasurements) {
  var mm = []

  measurements.forEach(measurement => {
    const mashupFound = mashupMeasurements.find(
      mashupSeries => mashupSeries[0] === measurement[0]
    )
    if (mashupFound == null) {
      mm.push([measurement[0], null])
    }
  })

  mashupMeasurements = mashupMeasurements.concat(mm)
  mashupMeasurements = mashupMeasurements.sort((lhs, rhs) =>
    lhs[0] > rhs[0] ? 1 : rhs[0] > lhs[0] ? -1 : 0
  )
  mm = mashupMeasurements

  var sLength = mm.length

  measurements.forEach(measurement => {
    const mashupFound = mm.find(
      mashupSeries => mashupSeries[0] === measurement[0]
    )
    if (mashupFound != null) {
      const nextMashupIndex = mm.indexOf(mashupFound) + 1
      var nextMashup = [null, null]

      if (nextMashupIndex !== sLength) {
        nextMashup = mm[nextMashupIndex]
      }

      if (nextMashup[0] != null) {
        if (nextMashup[1] != null && mashupFound[1] == null) {
          mashupFound[1] = measurement[1]
        }

        if (nextMashup[1] == null && mashupFound[1] != null) {
          mashupFound[1] = measurement[1]
        }
      }

      if (mashupFound[1] != null) {
        measurement[1] = null
      }
    }
  })

  return { mm: mm, measurements: measurements }
}

function createTimeXAxisDefinition (firstDate, lastDate) {
  return {
    type: 'datetime',
    startOnTick: false,
    showLastLabel: true,
    endOnTick: false,
    allowDecimals: false,
    lineColor: '#C0D0E0',
    tickmarkPlacement: 'on',
    gridLineWidth: 1,
    minorGridLineWidth: 0,
    crosshair: false,
    labels: {
      style: {
        color: '#666',
        fontSize: '10px'
      }
    },
    max: lastDate,
    min: firstDate,
    dateTimeLabelFormats: {
      second: '%H:%M:%S',
      minute: '%H:%M',
      hour: '%H:%M',
      day: '%e. %b, %y',
      week: '%e - %b',
      month: "%b '%y",
      year: '%Y'
    }
  }
}

export default {
  name: 'chart-home',
  components: { Reports, MonthPicker },
  computed: {
    ...mapGetters([
      'measurements',
      'measurement_status',
      'sensor_measurements',
      'sensor_measurement_status'
    ]),

    isLoaded: function () {
      return this.measurement_status === SUCCESS
    },

    noData: function () {
      return this.measurements.level.measurements.length === 0
    },

    hasNextPeriod: function () {
      return this.lastDateInMilliseconds - 300000 >= this.lastDate // Todo: 300000 to params
    },

    lastDateInMilliseconds: function () {
      return this.measurements && this.measurements.lastLevel
        ? asTimeInMilliseconds(this.measurements.lastLevel.t)
        : 0
    },

    showOverflowLevel : function () {
      return this.measurements?.logger?.showOverflowLevel ?? false
    },

    weirLevelSeries: function () {
      var opt = []

      if (this.measurements == null) {
        return opt
      }

      if (
        this.measurements.logger.type.toLowerCase() === 'computed with measured'
      ) {
        opt = [
          {
            name: 'Nivå',
            data: this.weirLevelMeasurements,
            lineColor: '#337ab7',
            color: '#337ab7',
            type: 'spline',
            fillOpacity: 0.5,
            lineWidth: 1.5,
            states: { hover: { lineWidth: 2 } },
            visible: false,
            events: {
              legendItemClick: () => {
                this.handleSensorDataClick(LEVEL)
                return false
              }
            }
          },
          {
            name: 'Nivå mashup',
            data: this.mashupSeries,
            lineColor: '#FFF000',
            color: '#BBB000',
            type: 'spline',
            fillOpacity: 0.5,
            lineWidth: 1.5,
            states: { hover: { lineWidth: 2 } },
            visible: false,
            events: {
              legendItemClick: () => {
                this.handleSensorDataClick(LEVEL_MASHUP)
                return false
              }
            }
          },
          {
            name: `Terskel (${this.measurements.logger.threshold}m)`,
            data: this.weirLine,
            dashStyle: 'Dot',
            type: 'spline',
            lineWidth: 3,
            color: '#FF0000',
            visible: false,
            events: {
              legendItemClick: () => {
                this.handleSensorDataClick(THRESHOLD)
                return false
              }
            }
          },
          {
            yAxis: 1,
            name: 'Videreført, Qv',
            data: this.outPipeMeasurements,
            lineColor: '#00BB00',
            color: '#00BB00',
            type: 'spline',
            fillOpacity: 0.5,
            lineWidth: 1.5,
            visible: false,
            states: { hover: { lineWidth: 2 } },
            events: {
              legendItemClick: () => {
                this.handleSensorDataClick(FLOW)
                return false
              }
            }
          },
          {
            yAxis: 1,
            name: 'Videreført mashup',
            data: this.mashupFlowMeasurements,
            lineColor: '#0000BB',
            color: '#0000BB',
            type: 'spline',
            fillOpacity: 0.5,
            lineWidth: 1.5,
            visible: false,
            states: { hover: { lineWidth: 2 } },
            events: {
              legendItemClick: () => {
                this.handleSensorDataClick(FLOW_MASHUP)
                return false
              }
            }
          },
          {
            yAxis: 1,
            name: 'Overløp, Qo',
            data: this.weirFlowMeasurements,
            color: '#0000FF',
            type: 'spline',
            lineWidth: 1.5,
            visible: false,
            states: { hover: { lineWidth: 2 } },
            events: {
              legendItemClick: () => {
                this.handleSensorDataClick(OVERFLOW_FLOW)
                return false
              }
            }
          },
          {
            yAxis: 1,
            name: 'Overløp mashup',
            data: this.mashupOutPipeMeasurements,
            lineColor: '#BB00BB',
            color: '#BB00BB',
            type: 'spline',
            fillOpacity: 0.5,
            lineWidth: 1.5,
            visible: false,
            states: { hover: { lineWidth: 2 } },
            events: {
              legendItemClick: () => {
                this.handleSensorDataClick(OVERFLOW_FLOW_MASHUP)
                return false
              }
            }
          }
        ]
      } else {
        opt = [
          {
            name: 'Nivå',
            data: this.weirLevelMeasurements,
            lineColor: '#337ab7',
            color: '#337ab7',
            type: 'spline',
            fillOpacity: 0.5,
            lineWidth: 1.5,
            states: { hover: { lineWidth: 2 } },
            visible: false,
            events: {
              legendItemClick: () => {
                this.handleSensorDataClick(LEVEL)
                return false
              }
            }
          },
          {
            name: 'Overløp Nivå',
            data: this.outPipeLevelMeasurements,
            lineColor: '#3cacc9',
            color: '#3cacc9',
            type: 'spline',
            fillOpacity: 0.5,
            lineWidth: 1.5,
            states: { hover: { lineWidth: 2 } },
            visible: false,
            events: {
              legendItemClick: () => {
                this.handleSensorDataClick(OVERFLOW_LEVEL)
                return false
              }
            }
          },
          {
            name: `Terskel (${this.measurements.logger.threshold}m)`,
            data: this.weirLine,
            dashStyle: 'Dot',
            type: 'spline',
            lineWidth: 3,
            color: '#FF0000',
            visible: false,
            events: {
              legendItemClick: () => {
                this.handleSensorDataClick(THRESHOLD)
                return false
              }
            }
          },
          {
            yAxis: 1,
            name: 'Videreført, Qv',
            data: this.weirFlowMeasurements,
            lineColor: '#00BB00',
            color: '#00BB00',
            type: 'spline',
            fillOpacity: 0.5,
            lineWidth: 1.5,
            visible: false,
            states: { hover: { lineWidth: 2 } },
            events: {
              legendItemClick: () => {
                this.handleSensorDataClick(FLOW)
                return false
              }
            }
          },
          {
            yAxis: 1,
            name: 'Overløp, Qo',
            data: this.outPipeMeasurements,
            color: '#0000FF',
            type: 'spline',
            lineWidth: 1.5,
            visible: false,
            states: { hover: { lineWidth: 2 } },
            events: {
              legendItemClick: () => {
                this.handleSensorDataClick(OVERFLOW_FLOW)
                return false
              }
            }
          }
        ]

        if(!this.showOverflowLevel) {
          opt.splice(1, 1)
        }
      }

      if (this.rainMeasurements.length > 0) {
        opt.push({
          yAxis: 2,
          name: 'Nedbør',
          data: this.rainMeasurements,
          lineColor: 'rgba(250, 250, 250, 0.80)',
          color: 'rgba(250, 250, 250, 0.80)',
          type: 'column',
          fillOpacity: 0.5,
          lineWidth: 4,
          visible: true,
          states: { hover: { lineWidth: 5 } },
          events: {
            legendItemClick: () => {
              this.handleSensorDataClick(RAIN)
              return false
            }
          }
        })
      }

      return opt
    }
  },

  methods: {
    ...mapActions({
      loadData: REQUEST_OVERFLOW_DATA
    }),
    handleSensorDataClick: function (sensorType) {
      switch (sensorType) {
        case RAIN:
          this.isRainActive = !this.isRainActive
          break
        case LEVEL:
          this.isLevelActive = !this.isLevelActive
          break
        case OVERFLOW_LEVEL:
          this.isLevelOverflowActive = !this.isLevelOverflowActive
          break
        case FLOW:
          this.isFlowActive = !this.isFlowActive
          break
        case OVERFLOW_FLOW:
          this.isOverflowActive = !this.isOverflowActive
          break
        case LEVEL_MASHUP:
          this.isLevelMashupActive = !this.isLevelMashupActive
          break
        case FLOW_MASHUP:
          this.isFlowMashupActive = !this.isFlowMashupActive
          break
        case OVERFLOW_FLOW_MASHUP:
          this.isOverflowMashupActive = !this.isOverflowMashupActive
          break
        case THRESHOLD:
          this.isThresholdActive = !this.isThresholdActive
          break
      }

      this.updateSensorsVisibility()
    },
    updateSensorsVisibility: function () {
      if (
        this.measurements.logger.type.toLowerCase() === 'computed with measured'
      ) {
        this.options.series[0].visible = this.isLevelActive
        this.options.series[1].visible = this.isLevelMashupActive
        this.options.series[2].visible = this.isThresholdActive
        this.options.series[3].visible = this.isFlowActive
        this.options.series[4].visible = this.isFlowMashupActive
        this.options.series[5].visible = this.isOverflowActive
        this.options.series[6].visible = this.isOverflowMashupActive
        if (this.options.series.length > 7) {
          this.options.series[7].visible = this.isRainActive
        }
      } else {
        this.options.series[0].visible = this.isLevelActive
        if(this.showOverflowLevel) {
          this.options.series[1].visible = this.isLevelOverflowActive
          this.options.series[2].visible = this.isThresholdActive
          this.options.series[3].visible = this.isFlowActive
          this.options.series[4].visible = this.isOverflowActive
          if (this.options.series.length > 5) {
            this.options.series[5].visible = this.isRainActive
          }
        } else {
          this.options.series[1].visible = this.isThresholdActive
          this.options.series[2].visible = this.isFlowActive
          this.options.series[3].visible = this.isOverflowActive
          if (this.options.series.length > 4) {
            this.options.series[4].visible = this.isRainActive
          }
        }
      }
    },

    handlePreviousPeriodClick: function () {
      var maxDate = new Date(this.firstDate)
      var minDate = new Date(maxDate)
      minDate.setMonth(minDate.getMonth() - 1)
      this.firstDate = minDate.getTime()
      this.lastDate = maxDate.getTime()
      this.loadData({
        loggerId: this.$route.params.id,
        dateFrom: toUrlDateString(minDate),
        dateTo: toUrlDateString(maxDate)
      })
    },

    handleNextPeriodClick: function () {
      var minDate = new Date(this.lastDate)
      var maxDate = new Date(minDate)
      maxDate.setMonth(maxDate.getMonth() + 1)
      this.firstDate = minDate.getTime()
      this.lastDate = maxDate.getTime()
      this.loadData({
        loggerId: this.$route.params.id,
        dateFrom: toUrlDateString(minDate),
        dateTo: toUrlDateString(maxDate)
      })
    },

    handleSelectDate (date) {
      this.selectedDate = date
      this.requariedSelectedDate = false
      this.$refs['select_date_alert'].style = 'display: none;'
    },

    resetSelectDate () {
      this.selectedDate = null
    },
    fillGapsWithZeroValues: function (measurements) {
      let tempMeasurements = []

      let shiftIndex = 0
      let millisecondToCompare = this.measurements.logger.type.toLowerCase() === 'computed' ? 3600000 : 300000


      if (measurements.length > 0) {
        for (let index = 0; index < measurements.length - 1; index++) {
          const element = measurements[index]
          const element2 = measurements[index + 1]

          if (element2[0] > element[0] + millisecondToCompare) {
            tempMeasurements[index + shiftIndex] = element
            let currentTime = element[0] + millisecondToCompare
            while(element2[0] > currentTime) {
              shiftIndex++
              tempMeasurements[index + shiftIndex] = [currentTime, 0]
              currentTime += millisecondToCompare
            }
          } else {
            tempMeasurements[index + shiftIndex] = element
          }
        }
      }

      return tempMeasurements
    },
    handleChoosePeriodClick: function (bvModalEvt) {
      if (this.selectedDate == null || this.selectedDate.monthIndex === 0) {
        bvModalEvt.preventDefault()
        this.requariedSelectedDate = true
        this.$refs['select_date_alert'].style = 'display: block;'
        return
      }

      this.requariedSelectedDate = false
      var minDate = new Date(
        Date.UTC(this.selectedDate.year, this.selectedDate.monthIndex - 1, 1)
      )
      var maxDate = new Date(minDate)
      maxDate.setMonth(maxDate.getMonth() + 1)
      this.loadData({
        loggerId: this.$route.params.id,
        dateFrom: toUrlDateString(minDate),
        dateTo: toUrlDateString(maxDate)
      })

      this.firstDate = minDate.getTime()
      this.lastDate = maxDate.getTime()
    },
    defaultOptions: function () {
      var opt = {
        chart: {
          zoomType: 'x',
          type: 'area',
          height: '400',
          plotBackgroundColor: '#CECFF1'
        },
        title: {
          text: '',
          style: { display: 'none' }
        },
        legend: {
          enabled: true,
          layout: 'horizontal',
          backgroundColor: '#DFDFF2',
          itemHiddenStyle: { color: '#999' },
          itemStyle: { fontWeight: 'normal' }
        },
        xAxis: createTimeXAxisDefinition(this.firstDate, this.lastDate),
        yAxis: [
          {
            min: 0,
            title: { text: 'Nivå (m)' },
            minorGridLineWidth: 1,
            minorTickInterval: 'auto'
          },
          {
            min: 0,
            gridLineWidth: 0,
            minorGridLineWidth: 1,
            minorTickInterval: 'auto',
            title: { text: 'Vannmengde, Q (l/s)' },
            opposite: true
          }
        ],
        tooltip: {
          backgroundColor: '#DFDFF2',
          shared: true,
          crosshairs: true
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          columns: {
            borderColor: '#222222'
          },
          line: {
            marker: {
              enabled: false
            }
          },
          spline: {
            marker: {
              enabled: false
            }
          },
          area: {
            marker: {
              enabled: false
            }
          }
        },
        hoverData: null,
        series: this.weirLevelSeries,
        navigation: {
          menuItemStyle: {
            fontSize: '10px'
          }
        },
        lang: {
          resetZoom: 'Fullt utsnitt',
          shortMonths: [
            'Jan',
            'Febr',
            'Mars',
            'April',
            'Mai',
            'Juni',
            'July',
            'Aug',
            'Sept',
            'Okt',
            'Nov',
            'Des'
          ],
          months: [
            'Januar',
            'Februar',
            'Mars',
            'April',
            'May',
            'Juni',
            'Juli',
            'August',
            'September',
            'Oktober',
            'November',
            'Desember'
          ],
          weekdays: [
            'søndag',
            'mandag',
            'tirsdag',
            'onsdag',
            'torsdag',
            'fredag',
            'lørdag'
          ]
        }
      }

      if (this.rainMeasurements.length > 0) {
        opt.yAxis.push({
          min: 0,
          gridLineWidth: 0,
          minorGridLineWidth: 1,
          minorTickInterval: 'auto',
          labels: {
            style: { color: '#0000DD' }
          },
          title: {
            text: 'Nedbør, mm',
            style: { color: '#0000DD' }
          },
          opposite: true,
          reversed: true
        })
      }

      return opt
    }
  },

  data () {
    return {
      weirLevelMeasurements: [],
      weirFlowMeasurements: [],
      outPipeLevelMeasurements: [],
      outPipeMeasurements: [],
      yearSelected: null,
      yearOptions: [],
      monthSelected: null,
      options: null,
      monthOptions: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'Mai',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Okt',
        'Nov',
        'Des'
      ],
      mashupSeries: [],
      mashupFlowMeasurements: [],
      mashupOutPipeMeasurements: [],
      rainMeasurements: [],
      weirLine: [],
      firstDate: null,
      lastDate: Date.now(),
      showChoosePeriod: false,
      selectedDate: null,
      requariedSelectedDate: false,
      isLevelActive: true,
      isLevelOverflowActive: false,
      isFlowActive: false,
      isOverflowActive: false,
      isLevelMashupActive: true,
      isFlowMashupActive: false,
      isOverflowMashupActive: false,
      isThresholdActive: true,
      isRainActive: true
    }
  },

  created () {
    const numberOfLastYears = 10
    const currentYear = new Date().getFullYear()
    let yearStored = this.year
    if (
      isNaN(yearStored) ||
      (yearStored > currentYear && yearStored < currentYear - numberOfLastYears)
    ) {
      yearStored = currentYear
    }

    const yearInfoList = []
    yearInfoList.push({ value: null, text: 'År', disabled: true })
    for (
      let year = currentYear;
      year >= currentYear - numberOfLastYears;
      year--
    ) {
      yearInfoList.push({ value: year, text: year + '' })
    }

    this.yearOptions = yearInfoList

    this.options = this.defaultOptions()
  },

  watch: {
    isLoaded: function () {
      if (this.measurement_status !== SUCCESS) {
        return
      }

      if (this.measurements.level.measurements.length <= 0) {
        this.weirLine = null
        if (this.firstDate === null) {
          const lastDateInMilliseconds =
            this.measurements && this.measurements.lastLevel
              ? asTimeInMilliseconds(this.measurements.lastLevel.t)
              : 0
          let maxDate =
            lastDateInMilliseconds > 0
              ? new Date(lastDateInMilliseconds)
              : new Date()
          let minDate =
            lastDateInMilliseconds > 0
              ? new Date(lastDateInMilliseconds)
              : new Date()
          minDate.setMonth(minDate.getMonth() - 1)
          this.firstDate = minDate.getTime()
          this.lastDate = maxDate.getTime()
        }

        this.options = this.defaultOptions()
        this.updateSensorsVisibility()

        return
      }

      this.outPipeMeasurements = this.fillGapsWithZeroValues(this.measurements.outPipeFlow.measurements.map(
        m => [asTimeInMilliseconds(m.t), Math.round(m.v * 1000) / 1000]
      ))

      if(this.measurements.outPipeLevel) {
        this.outPipeLevelMeasurements = this.fillGapsWithZeroValues(this.measurements.outPipeLevel.measurements.map(
          m => [asTimeInMilliseconds(m.t), Math.round(m.v * 1000) / 1000]
        ))
      }
      else {
        this.outPipeLevelMeasurements = []
      }

      this.weirLevelMeasurements = this.fillGapsWithZeroValues(this.measurements.level.measurements.map(
        m => [asTimeInMilliseconds(m.t), Math.round(m.v * 1000) / 1000]
      ))


      this.weirFlowMeasurements = this.fillGapsWithZeroValues(this.measurements.flow.measurements.map(m => [
        asTimeInMilliseconds(m.t),
        Math.round(m.v * 1000) / 1000
      ]))

      if (this.measurements.mashupMeasurements != null) {
        this.mashupSeries = this.measurements.mashupLevel.measurements.map(
          m => [asTimeInMilliseconds(m.t), Math.round(m.v * 1000) / 1000]
        )

        var m = updateMashupMeasurements(
          this.weirLevelMeasurements,
          this.mashupSeries
        )
        this.mashupSeries = m.mm
        this.weirLevelMeasurements = m.measurements
      }

      if (this.measurements.rainfall != null) {
        this.rainMeasurements = this.measurements.rainfall.measurements.map(
          m => [asTimeInMilliseconds(m.t), Math.round(m.v * 1000) / 1000]
        )
      }

      if (this.measurements.mashupFlowMeasurements != null) {
        this.mashupFlowMeasurements =
          this.measurements.mashupFlow.measurements.map(m => [
            asTimeInMilliseconds(m.t),
            Math.round(m.v * 1000) / 1000
          ])

        this.weirFlowMeasurements.forEach(measurement => {
          this.mashupFlowMeasurements.forEach(mashupSeries => {
            if (mashupSeries[0] === measurement[0]) {
              let mashupSeriesIndex =
                this.mashupFlowMeasurements.indexOf(mashupSeries)
              if (
                mashupSeriesIndex !== 0 &&
                mashupSeriesIndex + 1 !== this.mashupFlowMeasurements.length
              ) {
                measurement[1] = null
              } else {
                measurement[1] = mashupSeries[1]
              }
            }
          })
        })
      }

      if (this.firstDate === null) {
        this.firstDate = this.weirLevelMeasurements[0][0]
        this.lastDate =
          this.weirLevelMeasurements[this.weirLevelMeasurements.length - 1][0]
      }

      if (this.weirLevelMeasurements.length > 1) {
        let maxDate = new Date(this.firstDate)
        maxDate.setMonth(maxDate.getMonth() + 1)
        let maxDateInMilliseconds = maxDate.getTime()
        if (this.lastDate < maxDateInMilliseconds) {
          this.lastDate = maxDateInMilliseconds
        }
      } else {
        let minDate = new Date(this.lastDate)
        minDate.setMonth(minDate.getMonth() - 1)
        let minDateInMilliseconds = minDate.getTime()
        if (this.firstDate > minDateInMilliseconds) {
          this.firstDate = minDateInMilliseconds
        }
      }

      this.weirLine = [
        [this.firstDate, this.measurements.logger.threshold],
        [this.lastDate, this.measurements.logger.threshold]
      ]

      this.options = this.defaultOptions()
      this.updateSensorsVisibility()
    }
  }
}
</script>
