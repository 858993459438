import {
  OVERFLOW_REQUEST_URL,
  ERROR,
  SUCCESS,
  LOADING,
  UNAUTHORIZED
} from '../actions/backend'
import {
  OVERFLOW_DATA_REQUEST,
  OVERFLOW_DATA_REQUEST_SUCCESS,
  OVERFLOW_DATA_REQUEST_ERROR
} from '../mutation-types'
import { REQUEST_REFRESH_TOKEN, REQUEST_OVERFLOW_DATA } from '../action-types'
import { AUTH_TOKEN_KEY } from '../storage-keys'
import axios from 'axios'

const state = {
  measurements: null,
  measurement_status: ''
}

const getters = {
  measurements: state => state.measurements,
  measurement_status: state => state.measurement_status
}

const actions = {
  [REQUEST_OVERFLOW_DATA]: ({ commit, dispatch }, param) => {
    var requestUrl = OVERFLOW_REQUEST_URL + '/' + encodeURIComponent(param.loggerId)
    requestUrl += '?aggregation=FiveMinute'
    if (param.dateFrom) {
      requestUrl += '&dateFrom=' + encodeURIComponent(param.dateFrom)
    }

    if (param.dateTo) {
      requestUrl += '&date=' + encodeURIComponent(param.dateTo)
    }

    return new Promise((resolve, reject) => {
      commit(OVERFLOW_DATA_REQUEST, param.force)
      axios({
        url: requestUrl,
        method: 'GET',
        type: 'json',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem(AUTH_TOKEN_KEY) || '',
          Accept: 'application/json, text/plain, */*',
          'Cache-Control': 'no-cache'
        }
      })
        .then(resp => {
          commit(OVERFLOW_DATA_REQUEST_SUCCESS, resp)
          resolve(resp)
        })
        .catch(err => {
          if (err.response != null && err.response.status === UNAUTHORIZED) {
            dispatch(REQUEST_REFRESH_TOKEN, {
              previousRequest: REQUEST_OVERFLOW_DATA,
              param: param
            })
          } else {
            commit(OVERFLOW_DATA_REQUEST_ERROR, err)
          }
          reject(err)
        })
    })
  }
}

const mutations = {
  [OVERFLOW_DATA_REQUEST]: (state, force) => {
    state.measurement_status = LOADING
    if (force != null && force) {
      state.measurements = null
    }
  },
  [OVERFLOW_DATA_REQUEST_SUCCESS]: (state, resp) => {
    state.measurement_status = SUCCESS
    state.measurements = resp.data
    // localStorage.setItem('measurements', resp.data);
  },
  [OVERFLOW_DATA_REQUEST_ERROR]: state => {
    state.measurement_status = ERROR
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
