import { MASHUP_REQUEST_URL, LOADING, SUCCESS, ERROR, UNAUTHORIZED } from '../actions/backend'
import {
  REQUEST_MASHUP_PERIODS,
  ADD_MASHUP_PERIODS,
  REMOVE_MASHUP_PERIODS,
  REQUEST_REFRESH_TOKEN
} from '../action-types'
import {
  MASHUP_PERIODS_REQUEST,
  MASHUP_PERIODS_REQUEST_SUCCESS,
  MASHUP_PERIODS_REQUEST_ERROR,
  MASHUP_PERIODS_ADDITION,
  MASHUP_PERIODS_REMOVAL
} from '../mutation-types'
import { AUTH_TOKEN_KEY } from '../storage-keys'
import axios from 'axios'

const MASHUP_PERIODS_KEY = 'mashup_periods'

const state = {
  mashup_status: '',
  mashup_periods: localStorage.getItem(MASHUP_PERIODS_KEY) || null
}

const getters = {
  mashup_status: state => state.mashup_status,
  mashup_periods: state => state.mashup_periods
}

const actions = {
  [REQUEST_MASHUP_PERIODS]: ({ commit, dispatch }, { loggerId }) => {
    return new Promise((resolve, reject) => {
      commit(MASHUP_PERIODS_REQUEST)
      var requestUrl = MASHUP_REQUEST_URL + '/' + encodeURIComponent(loggerId)
      axios({
        url: requestUrl,
        method: 'GET',
        type: 'json',
        responseType: 'json',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem(AUTH_TOKEN_KEY) || '',
          Accept: 'application/json, text/plain, */*',
          'Cache-Control': 'no-cache'
        }
      })
        .then(resp => {
          commit(MASHUP_PERIODS_REQUEST_SUCCESS, resp)
          resolve(resp)
        })
        .catch(err => {
          if (err.response != null && err.response.status === UNAUTHORIZED) {
            dispatch(REQUEST_REFRESH_TOKEN, {
              previousRequest: REQUEST_MASHUP_PERIODS,
              param: { loggerId }
            })
          } else {
            commit(MASHUP_PERIODS_REQUEST_ERROR, err)
          }
          reject(err)
        })
    })
  },
  [ADD_MASHUP_PERIODS]: ({ commit, dispatch }, param) => {
    return new Promise((resolve, reject) => {
      commit(MASHUP_PERIODS_ADDITION)
      var requestUrl = MASHUP_REQUEST_URL
      axios({
        url: requestUrl,
        method: 'POST',
        data: param.data,
        type: 'json',
        responseType: 'json',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem(AUTH_TOKEN_KEY) || '',
          Accept: 'application/json, text/plain, */*',
          'Cache-Control': 'no-cache'
        }
      })
        .then(resp => {
          commit(MASHUP_PERIODS_REQUEST_SUCCESS, resp)
          resolve(resp)
        })
        .catch(err => {
          if (err.response != null && err.response.status === UNAUTHORIZED) {
            dispatch(REQUEST_REFRESH_TOKEN, {
              previousRequest: ADD_MASHUP_PERIODS,
              param: param
            })
          } else {
            commit(MASHUP_PERIODS_REQUEST_ERROR, err)
          }
          reject(err)
        })
    })
  },
  [REMOVE_MASHUP_PERIODS]: ({ commit, dispatch }, param) => {
    return new Promise((resolve, reject) => {
      commit(MASHUP_PERIODS_REMOVAL)
      var requestUrl = MASHUP_REQUEST_URL
      axios({
        url: requestUrl,
        method: 'DELETE',
        data: param.data,
        type: 'json',
        responseType: 'json',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem(AUTH_TOKEN_KEY) || '',
          Accept: 'application/json, text/plain, */*',
          'Cache-Control': 'no-cache'
        }
      })
        .then(resp => {
          commit(MASHUP_PERIODS_REQUEST_SUCCESS, resp)
          resolve(resp)
        })
        .catch(err => {
          if (err.response != null && err.response.status === UNAUTHORIZED) {
            dispatch(REQUEST_REFRESH_TOKEN, {
              previousRequest: REMOVE_MASHUP_PERIODS,
              param: param
            })
          } else {
            commit(MASHUP_PERIODS_REQUEST_ERROR, err)
          }
          reject(err)
        })
    })
  }
}

const mutations = {
  [MASHUP_PERIODS_REQUEST]: state => {
    state.mashup_status = LOADING
  },
  [MASHUP_PERIODS_ADDITION]: state => {
    state.mashup_status = LOADING
  },
  [MASHUP_PERIODS_REMOVAL]: state => {
    state.mashup_status = LOADING
  },
  [MASHUP_PERIODS_REQUEST_SUCCESS]: (state, resp) => {
    if (resp.data) {
      state.mashup_periods = resp.data
      localStorage.setItem(MASHUP_PERIODS_KEY, state.mashup_periods)
    }
    state.mashup_status = SUCCESS
  },
  [MASHUP_PERIODS_REQUEST_ERROR]: state => {
    state.mashup_status = ERROR
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
