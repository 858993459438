<template>
  <b-container fluid="fluid">
    <b-row>
      <b-col md="12">
        <b-container fluid="fluid">
          <b-row>
            <b-col md="12" class="text-center pb-1">
              <b-btn
                :size="'sm'"
                variant="outline-secondary"
                class="mr-1"
                @click="handlePreviousPeriodClick()"
              >
                <font-awesome-icon icon="angle-left" />
              </b-btn>
              <b-btn
                :size="'sm'"
                variant="outline-secondary"
                v-b-modal.select_date_modal
                >Velg periode</b-btn
              >
              <b-btn
                :size="'sm'"
                variant="outline-secondary"
                class="ml-1"
                v-bind:disabled="!hasNextPeriod"
                @click="handleNextPeriodClick()"
              >
                <font-awesome-icon icon="angle-right" />
              </b-btn>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <div id="overlay" v-show="!isFullyLoaded">
                <div class="loader">
                  <font-awesome-icon icon="spinner" size="4x" spin="spin" />
                </div>
              </div>
              <div id="overlay" v-show="isFullyLoaded && noData">
                <div class="overlay-text">
                  Det finnes ingen data for perioden
                </div>
              </div>
              <div>
                <highcharts class="chart" :options="options" ref="chart" />
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-modal
      id="select_date_modal"
      headerClass="p-2"
      @show="resetSelectDate"
      centered
      button-size="sm"
      ref="select_date_modal"
      @ok="handleChoosePeriodClick"
      cancel-title="Avbryt"
      title="Vis data for perioden"
    >
      <b-container fluid="fluid">
        <b-row
          id="select_date_alert"
          ref="select_date_alert"
          style="display: none"
        >
          <b-col md="12" class="text-center">
            <b-alert show="show" variant="danger">Du bør velge periode</b-alert>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12" class="text-center">
            <month-picker
              lang="no"
              :defaultYear="new Date(firstDate).getFullYear()"
              :no-default="true"
              @change="handleSelectDate"
            ></month-picker>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <b-modal
      id="warning_select_sensor_modal"
      headerClass="p-2"
      footerClass="p-2"
      centered
      button-size="sm"
      ok-only
      ref="warning_select_sensor_modal"
      cancel-disabled
      v-model="shouldSelectSensor"
      @close="handleShouldSelectSensor"
      title="Velg minst én tidsserie"
    >
      <b-container fluid="fluid">
        <b-row>
          <b-col md="12" class="text-center">
            Du bør velge minst én tidsserie: <br />
            <div v-html="getAlmostOneSensorSelectedModalText" style="text-align: left; width: 150px; position: relative; left: 30%;" />
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </b-container>
</template>

<script>
import { asTimeInMilliseconds, toUrlDateString } from '../../../utils/Date.js'
import { SUCCESS } from '../../../store/actions/backend.js'
import { REQUEST_SENSOR_DATA } from '../../../store/action-types'
import { LEVEL, FLOW, VELOCITY, RAIN, ASSOCIATED_RAIN, THRESHOLD } from '../../../utils/logger_consts'
import MonthPicker from '../../utils/month-picker/MonthPicker'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'chart-sensor',  
  props: ['logger_type'],
  components: {
    MonthPicker
  },

  computed: {
    ...mapGetters(['loggerInfos']),

    isFullyLoaded: function () {
      switch (this.logger_type) {
        case RAIN:
          return this.isRainLoaded

        case LEVEL:
          return this.isLevelLoaded && this.isAssociatedLoggerInfoLoaded &&
            (this.associatedRainLogger == null || !this.isAssociatedRainActive || this.isAssociatedRainLoaded)

        case FLOW:
          return (
            (!this.isLevelActive || this.isLevelLoaded) &&
            (!this.isFlowActive || this.isFlowLoaded) &&
            (!this.isVelocityActive || this.isVelocityLoaded) &&
            this.isAssociatedLoggerInfoLoaded &&
            (this.associatedRainLogger == null || !this.isAssociatedRainActive || this.isAssociatedRainLoaded)
          )
      }

      return false
    },

    isAlmostOneSensorSelected: function () {
      switch (this.logger_type) {
        case RAIN:
          return this.isRainActive
        case LEVEL:
          return this.isLevelActive
        case FLOW:
          return (
            (this.isLevelActive || this.isFlowActive || this.isVelocityActive)
          )
        default:
          return false
      }
    },

    getAlmostOneSensorSelectedModalText: function () {
      switch (this.logger_type) {
        case RAIN:
          return '<ul><li>Nedbør, mm</li></ul>'
        case LEVEL:
          return '<ul><li>Nivå, m</li></ul>'
        case FLOW:
          return '<ul><li>Nivå, m</li><li>Videreført, l/s</li><li>Hastighet, m/s</li></ul>'
      }
      return ''
    },

    isLevelLoaded: function () {
      return this.isSensorLoaded(LEVEL)
    },

    isRainLoaded: function () {
      return this.isSensorLoaded(RAIN)
    },

    isFlowLoaded: function () {
      return this.isSensorLoaded(FLOW)
    },

    isVelocityLoaded: function () {
      return this.isSensorLoaded(VELOCITY)
    },

    isAssociatedRainLoaded: function () {
      if (this.associatedRainLogger == null) {
        return false
      }

      let ts = this.associatedRainLogger.timeSeries.find(t => t.type.toLowerCase() === RAIN)
      return ts ? ts.measurement_status === SUCCESS : false
    },

    isAssociatedLoggerInfoLoaded: function () {
      return this.associatedLoggerInfoStatus === SUCCESS
    },

    noData: function () {
      return this.levelMeasurements.length === 0 && this.flowMeasurements.length === 0 &&
       this.velocityMeasurements.length === 0 && this.rainMeasurements.length === 0
    },

    hasNextPeriod: function () {
      return this.lastDateInMilliseconds - 3600000 >= this.lastDate // Todo: 300000 to params
    },

    lastDateInMilliseconds: function () {
      if (this.currentLogger.timeSeries.length > 0) {
        let latestData = this.currentLogger.timeSeries[0].latestData
        if (latestData) {
          return asTimeInMilliseconds(latestData.t)
        }
      }

      return 0
    },

    currentLogger: function () {
      if (this.loggerInfos && this.loggerInfos.length > 0) {
        let loggerId = Number(this.$route.params.id)
        let loggerInfo = this.loggerInfos.find(info => info.logger.logger.id === loggerId)
        if (loggerInfo) {
          return loggerInfo.logger
        }
      }

      return null
    },

    associatedRainLogger: function () {
      if (this.currentLogger != null && this.currentLogger.logger.associated_loggers.length > 0) {
        let rainInfo = this.currentLogger.logger.associated_loggers.find(info => info.logger.type.toLowerCase().includes(RAIN))
        if (this.loggerInfos && this.loggerInfos.length > 0) {
          let loggerId = rainInfo.logger.id
          let loggerInfo = this.loggerInfos.find(info => info.logger.logger.id === loggerId)
          if (loggerInfo) {
            return loggerInfo.logger
          }
        }
      }

      return null
    }
  },

  data () {
    return {
      loadedStatuses: [],
      shouldSelectSensor: false,
      associatedLoggerInfoStatus: '',
      isLevelActive: false,
      isFlowActive: false,
      isVelocityActive: false,
      isRainActive: false,
      isAssociatedRainActive: false,
      isThresholdActive: false,
      levelMeasurements: [],
      flowMeasurements: [],
      velocityMeasurements: [],
      rainMeasurements: [],
      associatedRainMeasurements: [],
      thresholdLine: [],
      yearSelected: null,
      yearOptions: [],
      currentSensorType: LEVEL,
      monthSelected: null,
      options: {
        chart: {
          zoomType: 'x',
          type: 'area',
          height: '400',
          plotBackgroundColor: '#CECFF1'
        },
        title: {
          text: '',
          style: {
            display: 'none'
          }
        },
        legend: {
          enabled: true,
          layout: 'horizontal',
          backgroundColor: '#DFDFF2',
          itemHiddenStyle: { color: '#999' },
          itemStyle: { fontWeight: 'normal' }
        },
        xAxis: {
          type: 'datetime',
          startOnTick: false,
          showLastLabel: true,
          endOnTick: false,
          allowDecimals: false,
          lineColor: '#C0D0E0',
          tickmarkPlacement: 'on',
          gridLineWidth: 1,
          minorGridLineWidth: 0,
          crosshair: false,
          labels: {
            style: {
              color: '#666',
              fontSize: '10px'
            }
          },
          max: this.lastDate,
          min: this.firstDate,
          dateTimeLabelFormats: {
            second: '%H:%M:%S',
            minute: '%H:%M',
            hour: '%H:%M',
            day: '%e. %b, %y',
            week: '%e - %b',
            month: "%b '%y",
            year: '%Y'
          }
        },
        yAxis: this.getYAxis(),
        tooltip: {
          backgroundColor: '#DFDFF2',
          shared: true,
          crosshairs: true
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          columns: {
            borderColor: '#222222'
          },
          line: {
            marker: {
              enabled: false
            }
          },
          spline: {
            marker: {
              enabled: false
            }
          },
          area: {
            marker: {
              enabled: false
            }
          }
        },
        series: [
          {
            name: 'Nivå, m',
            data: this.levelMeasurements,
            lineColor: '#337ab7',
            color: '#337ab7',
            type: 'spline',
            fillOpacity: 0.5,
            lineWidth: 1.5,
            visible: this.isLevelActive,
            states: {
              hover: {
                lineWidth: 2
              }
            },
            events: {
              legendItemClick: () => {
                this.handleSensorDataClick(LEVEL)
                return false
              }
            }
          },
          {
            name: `Terskel (${this.getLoggerThreshold()}m)`,
            data: this.thresholdLine,
            dashStyle: 'Dot',
            type: 'spline',
            lineWidth: 3,
            visible: this.isThresholdActive,
            color: '#FF0000',
            events: {
              legendItemClick: () => {
                this.handleSensorDataClick(THRESHOLD)
                return false
              }
            }
          },
          {
            yAxis: 1,
            name: 'Videreført, l/s',
            data: this.flowMeasurements,
            lineColor: '#00BB00',
            color: '#00BB00',
            type: 'spline',
            fillOpacity: 0.5,
            lineWidth: 1.5,
            visible: this.isFlowActive,
            states: {
              hover: {
                lineWidth: 2
              }
            },
            events: {
              legendItemClick: () => {
                this.handleSensorDataClick(FLOW)
                return false
              }
            }
          },
          {
            yAxis: 2,
            name: 'Hastighet, m/s',
            data: this.velocityMeasurements,
            lineColor: '#FF00FF',
            color: '#FF00FF',
            type: 'spline',
            fillOpacity: 0.5,
            lineWidth: 1.5,
            visible: this.isVelocityActive,
            states: {
              hover: {
                lineWidth: 2
              }
            },
            events: {
              legendItemClick: () => {
                this.handleSensorDataClick(VELOCITY)
                return false
              }
            }
          },
          {
            name: 'Nedbør, mm',
            data: this.rainMeasurements,
            lineColor: 'rgba(250, 250, 250, 1)',
            color: 'rgba(250, 250, 250, 1)',
            type: 'column',
            fillOpacity: 0.8,
            lineWidth: 4,
            visible: this.isRainActive,
            states: {
              hover: {
                lineWidth: 5
              }
            },
            events: {
              legendItemClick: () => {
                this.handleSensorDataClick(RAIN)
                return false
              }
            }
          },
          {
            yAxis: 1,
            name: 'Nedbør, mm',
            data: this.associatedRainMeasurements,
            lineColor: 'rgba(250, 250, 250, 1)',
            color: 'rgba(250, 250, 250, 1)',
            type: 'column',
            fillOpacity: 0.8,
            lineWidth: 4,
            visible: this.isAssociatedRainActive,
            states: {
              hover: {
                lineWidth: 5
              }
            },
            events: {
              legendItemClick: () => {
                this.handleSensorDataClick(ASSOCIATED_RAIN)
                return false
              }
            }
          }
        ],
        navigation: {
          menuItemStyle: {
            fontSize: '10px'
          }
        },
        lang: {
          resetZoom: 'Fullt utsnitt',
          shortMonths: [
            'Jan',
            'Febr',
            'Mars',
            'April',
            'Mai',
            'Juni',
            'July',
            'Aug',
            'Sept',
            'Okt',
            'Nov',
            'Des'
          ],
          months: [
            'Januar',
            'Februar',
            'Mars',
            'April',
            'May',
            'Juni',
            'Juli',
            'August',
            'September',
            'Oktober',
            'November',
            'Desember'
          ],
          weekdays: [
            'søndag',
            'mandag',
            'tirsdag',
            'onsdag',
            'torsdag',
            'fredag',
            'lørdag'
          ]
        }
      },
      monthOptions: [ 'Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des' ],
      firstDate: null,
      lastDate: Date.now(), // UTC now
      showChoosePeriod: false,
      selectedDate: null,
      requiredSelectedDate: false
    }
  },

  methods: {
    ...mapActions({ loadSensorData: REQUEST_SENSOR_DATA }),

    isSensorLoaded: function (type) {
      let ts = this.currentLogger.timeSeries.find(t => t.type.toLowerCase() === type)
      return ts ? ts.measurement_status === SUCCESS : false
    },

    getAssociatedLoggerInfoStatus: function () {
      return this.currentLogger ? this.currentLogger.associated_logger_status : ''
    },

    getLevelMeasurements: function () {
      return this.levelMeasurements
    },

    getCurrentMeasurements: function (sensorType) {
      let sensorTimeSeries = this.currentLogger.timeSeries.find(t => t.type.toLowerCase() === sensorType)
      return sensorTimeSeries ? sensorTimeSeries.measurements : null
    },

    getAssociatedMeasurements: function (sensorType) {
      let sensorTimeSeries = this.associatedRainLogger.timeSeries.find(t => t.type.toLowerCase() === sensorType)
      return sensorTimeSeries ? sensorTimeSeries.measurements : null
    },

    loadLoggerData: function (firstDate, lastDate) {
      switch (this.logger_type) {
        case RAIN:
          this.rainMeasurements = []
          this.loadSensorData({
            loggerId: this.$route.params.id,
            dateFrom: toUrlDateString(firstDate),
            dateTo: toUrlDateString(lastDate),
            sensorType: RAIN
          })
          break

        case LEVEL:
          this.levelMeasurements = []
          this.associatedRainMeasurements = []
          this.loadSensorData({
            loggerId: this.$route.params.id,
            dateFrom: toUrlDateString(firstDate),
            dateTo: toUrlDateString(lastDate),
            sensorType: LEVEL
          })

          if (this.associatedRainLogger != null && this.isAssociatedRainActive) {
            this.loadSensorData({
              loggerId: this.associatedRainLogger.logger.id,
              dateFrom: toUrlDateString(firstDate),
              dateTo: toUrlDateString(lastDate),
              sensorType: RAIN
            })
          }
          break

        case FLOW: {
          this.levelMeasurements = []
          this.flowMeasurements = []
          this.velocityMeasurements = []
          this.associatedRainMeasurements = []
          if (this.isLevelActive) {
            this.loadSensorData({
              loggerId: this.$route.params.id,
              dateFrom: toUrlDateString(firstDate),
              dateTo: toUrlDateString(lastDate),
              sensorType: LEVEL
            })
          }

          if (this.isFlowActive) {
            this.loadSensorData({
              loggerId: this.$route.params.id,
              dateFrom: toUrlDateString(firstDate),
              dateTo: toUrlDateString(lastDate),
              sensorType: FLOW
            })
          }

          if (this.isVelocityActive) {
            this.loadSensorData({
              loggerId: this.$route.params.id,
              dateFrom: toUrlDateString(firstDate),
              dateTo: toUrlDateString(lastDate),
              sensorType: VELOCITY
            })
          }

          if (this.associatedRainLogger != null && this.isAssociatedRainActive) {
            this.loadSensorData({
              loggerId: this.associatedRainLogger.logger.id,
              dateFrom: toUrlDateString(firstDate),
              dateTo: toUrlDateString(lastDate),
              sensorType: RAIN
            })
          }
        }
      }
    },

    hasDataForCurrentPeriod: function (measurements) {
      return (measurements && measurements.length > 0 &&
        measurements[0][0] >= this.firstDate && measurements[measurements.length - 1][0] <= this.lastDate)
    },

    handlePreviousPeriodClick: function () {
      if (!this.isAlmostOneSensorSelected) {
        this.shouldSelectSensor = true
        return
      }

      let maxDate = new Date(this.firstDate)
      let minDate = new Date(maxDate)
      minDate.setMonth(minDate.getMonth() - 1)
      this.firstDate = minDate.getTime()
      this.lastDate = maxDate.getTime()
      this.loadLoggerData(minDate, maxDate)
    },

    handleNextPeriodClick: function () {
      if (!this.isAlmostOneSensorSelected) {
        this.shouldSelectSensor = true
        return
      }

      var minDate = new Date(this.lastDate)
      var maxDate = new Date(minDate)
      maxDate.setMonth(maxDate.getMonth() + 1)
      this.firstDate = minDate.getTime()
      this.lastDate = maxDate.getTime()
      this.loadLoggerData(minDate, maxDate)
    },

    handleSensorDataClick (sensorType) {
      let isSensorActive = true
      let forceDataLoading = true

      switch (sensorType) {
        case LEVEL:
          isSensorActive = this.isLevelActive === true
          this.isLevelActive = !isSensorActive
          forceDataLoading = !this.hasDataForCurrentPeriod(this.levelMeasurements)
          break

        case FLOW:
          isSensorActive = this.isFlowActive === true
          this.isFlowActive = !isSensorActive
          forceDataLoading = !this.hasDataForCurrentPeriod(this.flowMeasurements)
          break

        case VELOCITY:
          isSensorActive = this.isVelocityActive === true
          this.isVelocityActive = !isSensorActive
          forceDataLoading = !this.hasDataForCurrentPeriod(this.velocityMeasurements)
          break

        case RAIN:
          isSensorActive = this.isRainActive === true
          this.isRainActive = !isSensorActive
          forceDataLoading = !this.hasDataForCurrentPeriod(this.rainMeasurements)
          break

        case THRESHOLD:
          isSensorActive = this.isThresholdActive === true
          forceDataLoading = false
          this.isThresholdActive = !this.isThresholdActive
          break

        case ASSOCIATED_RAIN:
          isSensorActive = this.isAssociatedRainActive === true
          this.isAssociatedRainActive = !isSensorActive
          forceDataLoading = !this.hasDataForCurrentPeriod(this.associatedRainMeasurements)
          break
      }

      if (!isSensorActive && forceDataLoading) {
        var self = this
        var loggerId = sensorType === ASSOCIATED_RAIN ? this.associatedRainLogger.logger.id : self.$route.params.id
        setTimeout(function () {
          self.loadSensorData({
            loggerId: loggerId,
            dateFrom: toUrlDateString(self.firstDate),
            dateTo: toUrlDateString(self.lastDate),
            sensorType: sensorType === ASSOCIATED_RAIN ? RAIN : sensorType
          })
        }, 10)
      } else {
        this.updateMeasurements()
      }
    },

    handleSelectDate (date) {
      this.selectedDate = date
      this.requiredSelectedDate = false
      this.$refs['select_date_alert'].style = 'display: none;'
    },

    resetSelectDate (bvModalEvt) {
      if (!this.isAlmostOneSensorSelected) {
        this.shouldSelectSensor = true
        bvModalEvt.preventDefault()
      }

      this.selectedDate = null
    },

    getLoggerThreshold () {
      return this.currentLogger != null && this.currentLogger.logger.threshold !== null
        ? this.currentLogger.logger.threshold
        : 0
    },

    handleShouldSelectSensor: function () {
      this.shouldSelectSensor = false
    },

    handleChoosePeriodClick: function (bvModalEvt) {
      if (this.selectedDate == null || this.selectedDate.monthIndex === 0) {
        bvModalEvt.preventDefault()
        this.requiredSelectedDate = true
        this.$refs['select_date_alert'].style = 'display: block;'
        return
      }

      this.requiredSelectedDate = false
      var minDate = new Date(Date.UTC(this.selectedDate.year, this.selectedDate.monthIndex - 1, 1))
      var maxDate = new Date(minDate)
      maxDate.setMonth(maxDate.getMonth() + 1)

      this.firstDate = minDate.getTime()
      this.lastDate = maxDate.getTime()
      this.loadLoggerData(minDate, maxDate)
    },

    getYAxis: function () {
      if (this.logger_type === FLOW) {
        return [
          {
            min: 0,
            title: {
              text: 'Nivå, m'
            },
            minorGridLineWidth: 1,
            minorTickInterval: 'auto'
          },
          {
            min: 0,
            title: {
              text: 'Vannmengde, l/s'
            },
            minorGridLineWidth: 1,
            minorTickInterval: 'auto',
            opposite: true
          },
          {
            min: 0,
            title: {
              text: 'Hastighet, m/s'
            },
            minorGridLineWidth: 1,
            minorTickInterval: 'auto',
            opposite: true
          },
          {
            gridLineWidth: 0,
            minorGridLineWidth: 1,
            labels: {
              style: { color: '#0000DD' }
            },
            title: {
              text: 'Nedbør, mm',
              style: { color: '#0000DD' }
            },
            opposite: true,
            reversed: true,
            visible: false
          }
        ]
      }

      if (this.logger_type === RAIN) {
        return [
          {
            min: 0,
            title: {
              text: 'Nedbør, mm'
            },
            minorGridLineWidth: 1,
            minorTickInterval: 'auto'
          }
        ]
      }

      if (this.logger_type === LEVEL) {
        if (this.associatedRainLogger == null) {
          return [
            {
              min: 0,
              title: {
                text: 'Nivå, m'
              },
              minorGridLineWidth: 1,
              minorTickInterval: 'auto'
            },
            {
              gridLineWidth: 0,
              minorGridLineWidth: 1,
              labels: {
                style: { color: '#0000DD' }
              },
              title: {
                text: 'Nedbør, mm',
                style: { color: '#0000DD' }
              },
              opposite: true,
              reversed: true,
              visible: false
            }
          ]
        }
      }
    },

    getYearInfoList: function () {
      const numberOfLastYears = 10
      const currentYear = new Date().getFullYear()
      let yearStored = this.year
      if (isNaN(yearStored) || (yearStored > currentYear && yearStored < currentYear - numberOfLastYears)) {
        yearStored = currentYear
      }

      const yearInfoList = []
      yearInfoList.push({ value: null, text: 'År', disabled: true })
      for (let year = currentYear; year >= currentYear - numberOfLastYears; year--) {
        yearInfoList.push({ value: year, text: year + '' })
      }

      return yearInfoList
    },

    updateMeasurements: function () {
      switch (this.logger_type) {
        case RAIN:
          this.options.series[0].visible = this.isRainActive
          break
        case LEVEL:
          this.options.series[0].visible = this.isLevelActive
          this.options.series[1].visible = this.isThresholdActive
          if (this.associatedRainLogger != null) {
            this.options.series[2].visible = this.isFullyLoaded && !this.noData && this.isAssociatedRainActive
          }
          break
        case FLOW:
          this.options.series[0].visible = this.isLevelActive
          this.options.series[1].visible = this.isThresholdActive
          this.options.series[2].visible = this.isFlowActive
          this.options.series[3].visible = this.isVelocityActive

          if (this.associatedRainLogger != null) {
            this.options.series[4].visible = this.isFullyLoaded && !this.noData && this.isAssociatedRainActive
          }
      }

      if (this.isLevelLoaded && this.isLevelActive) {
        let currentLevelMeasurements = this.getCurrentMeasurements(LEVEL)
        if (currentLevelMeasurements && currentLevelMeasurements.length > 0) {
          this.levelMeasurements = currentLevelMeasurements.map(m => [asTimeInMilliseconds(m.t), Math.round(m.v * 1000) / 1000])
          this.thresholdLine = [
            [this.firstDate, this.getLoggerThreshold()],
            [this.lastDate, this.getLoggerThreshold()]
          ]

          this.options.series[0].data = this.levelMeasurements
          this.options.series[0].visible = this.isLevelActive
          this.options.series[1].visible = this.isThresholdActive
          this.options.series[1].data = this.thresholdLine
          this.options.series[1].name = `Terskel (${this.getLoggerThreshold()}m)`
        }
      }

      if (this.isRainLoaded && this.isRainActive) {
        let currentRainMeasurements = this.getCurrentMeasurements(RAIN)
        if (currentRainMeasurements && currentRainMeasurements.length > 0) {
          this.rainMeasurements = currentRainMeasurements.map(function (m) {
            return [asTimeInMilliseconds(m.t), Math.round(m.v * 1000) / 1000]
          })

          this.options.series[0].data = this.rainMeasurements
          this.options.series[0].visible = this.isRainActive
        }
      }

      if (this.isAssociatedRainLoaded && this.isAssociatedRainActive) {
        let associatedRainMeasurements = this.getAssociatedMeasurements(RAIN)
        if (associatedRainMeasurements && associatedRainMeasurements.length > 0) {
          this.associatedRainMeasurements = associatedRainMeasurements.map(function (m) {
            return [asTimeInMilliseconds(m.t), Math.round(m.v * 1000) / 1000]
          })
          let associatedRainSeriesIndex = 2
          if (this.logger_type === FLOW) {
            associatedRainSeriesIndex = 4
          }
          this.options.series[associatedRainSeriesIndex].data = this.associatedRainMeasurements
          this.options.series[associatedRainSeriesIndex].visible = this.isFullyLoaded && !this.noData && this.isAssociatedRainActive
        }
      }

      if (this.isFlowLoaded && this.isFlowActive) {
        let currentFlowMeasurements = this.getCurrentMeasurements(FLOW)
        if (currentFlowMeasurements && currentFlowMeasurements.length > 0) {
          this.flowMeasurements = currentFlowMeasurements.map(m => [asTimeInMilliseconds(m.t), Math.round(m.v * 1000) / 1000])

          this.options.series[2].data = this.flowMeasurements
          this.options.series[2].visible = this.isFlowActive
        }
      }

      if (this.isVelocityLoaded && this.isVelocityActive) {
        let currentVelocityMeasurements = this.getCurrentMeasurements(VELOCITY)
        if (currentVelocityMeasurements && currentVelocityMeasurements.length > 0) {
          this.velocityMeasurements = currentVelocityMeasurements.map(m => [asTimeInMilliseconds(m.t), Math.round(m.v * 1000) / 1000])

          this.options.series[3].data = this.velocityMeasurements
          this.options.series[3].visible = this.isVelocityActive
        }
      }
    }
  },

  created () {
    this.yearOptions = this.getYearInfoList()

    let timestampInMilliseconds = asTimeInMilliseconds(this.currentLogger.timeSeries[0].latestData.t)
    let minDate = new Date(timestampInMilliseconds)
    minDate.setMonth(minDate.getMonth() - 1)
    let maxDate = new Date(timestampInMilliseconds)
    this.firstDate = minDate.getTime()
    this.lastDate = maxDate.getTime()

    switch (this.logger_type) {
      case LEVEL:
        this.isLevelActive = true
        this.isThresholdActive = true
        this.options.series.splice(2, 3)

        this.options.series[0].visible = this.isLevelActive
        this.options.series[1].visible = this.isThresholdActive

        this.isAssociatedRainActive = false
        this.options.series[2].visible = this.isAssociatedRainActive
        this.options.series[2].data = this.associatedRainMeasurements
        break

      case FLOW:
        this.isLevelActive = true
        this.isThresholdActive = true
        this.options.series.splice(4, 1)

        this.options.series[1].visible = this.isThresholdActive

        this.isFlowActive = false
        this.options.series[2].visible = this.isFlowActive
        this.options.series[2].data = this.flowMeasurements

        this.isVelocityActive = false
        this.options.series[3].visible = this.isVelocityActive
        this.options.series[3].data = this.velocityMeasurements

        this.isAssociatedRainActive = false
        this.options.series[4].visible = this.isAssociatedRainActive
        this.options.series[4].data = this.associatedRainMeasurements
        break

      case RAIN:
        this.isRainActive = true
        this.options.series.splice(0, 4)
        this.options.series.splice(1, 1)
        this.options.series[0].visible = this.isRainActive
        this.currentSensorType = RAIN
        break
    }

    this.loadSensorData({
      loggerId: this.$route.params.id,
      dateFrom: toUrlDateString(minDate),
      dateTo: toUrlDateString(maxDate),
      sensorType: this.currentSensorType
    })
  },

  watch: {
    currentLogger: {
      handler: function () {
        if (this.currentLogger && this.associatedLoggerInfoStatus !== this.currentLogger.associated_logger_status) {
          this.associatedLoggerInfoStatus = this.currentLogger.associated_logger_status
        }
      },
      deep: true
    },
    isFullyLoaded: function () {
      if (!this.isFullyLoaded) {
        return
      }

      this.updateMeasurements()

      let maxDate = new Date(this.lastDate)
      maxDate.setMonth(maxDate.getMonth() - 1)
      let maxDateInMilliseconds = maxDate.getTime()
      if (this.firstDate > maxDateInMilliseconds) {
        this.firstDate = maxDateInMilliseconds
      }

      this.options.xAxis.min = this.firstDate
      this.options.xAxis.max = this.lastDate

      if (this.associatedRainLogger != null) {
        if (this.logger_type === FLOW) {
          this.options.yAxis[3].visible = true
          this.options.series[4].yAxis = 3
        }
        if (this.logger_type === LEVEL) {
          this.options.yAxis[1].visible = true
        }
      } else {
        let associatedRainSeriesIndex = 2
        if (this.logger_type === FLOW) {
          associatedRainSeriesIndex = 4
        }
        this.options.series.splice(associatedRainSeriesIndex, 1)
      }
    }
  }
}
</script>
