// initial auth
export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_ERROR = 'AUTH_ERROR'

// refresh token
export const AUTH_REFRESH_TOKEN_REQUEST = 'AUTH_REFRESH_TOKEN_REQUEST'
export const AUTH_REFRESH_TOKEN_SUCCESS = 'AUTH_REFRESH_TOKEN_SUCCESS'
export const AUTH_REFRESH_TOKEN_ERROR = 'AUTH_REFRESH_TOKEN_ERROR'

// logout
export const AUTH_LOGOUT = 'AUTH_LOGOUT'

// user
export const USER_INFO_REQUEST = 'USER_INFO_REQUEST'
export const USER_INFO_REQUEST_SUCCESS = 'USER_INFO_REQUEST_SUCCESS'
export const USER_INFO_REQUEST_ERROR = 'USER_INFO_REQUEST_ERROR'

// mashup periods
export const MASHUP_PERIODS_REQUEST = 'MASHUP_PERIODS_REQUEST'
export const MASHUP_PERIODS_REQUEST_SUCCESS = 'MASHUP_PERIODS_REQUEST_SUCCESS'
export const MASHUP_PERIODS_REQUEST_ERROR = 'MASHUP_PERIODS_REQUEST_ERROR'
export const MASHUP_PERIODS_ADDITION = 'MASHUP_PERIODS_ADDITION'
export const MASHUP_PERIODS_REMOVAL = 'MASHUP_PERIODS_REMOVAL'

// report
export const REPORT_REQUEST = 'REPORT_REQUEST'
export const REPORT_REQUEST_SUCCESS = 'REPORT_REQUEST_SUCCESS'
export const REPORT_REQUEST_ERROR = 'REPORT_REQUEST_ERROR'

// report
export const LOGGER_REPORT_REQUEST = 'LOGGER_REPORT_REQUEST'
export const LOGGER_REPORT_REQUEST_SUCCESS = 'LOGGER_REPORT_REQUEST_SUCCESS'
export const LOGGER_REPORT_REQUEST_ERROR = 'LOGGER_REPORT_REQUEST_ERROR'

// overflow year report
export const OVERFLOW_YEAR_REPORT_REQUEST = 'OVERFLOW_YEAR_REPORT_REQUEST'
export const OVERFLOW_YEAR_REPORT_CLEARING = 'OVERFLOW_YEAR_REPORT_CLEARING'
export const OVERFLOW_YEAR_REPORT_REQUEST_SUCCESS =
  'OVERFLOW_YEAR_REPORT_REQUEST_SUCCESS'
export const OVERFLOW_YEAR_REPORT_REQUEST_ERROR =
  'OVERFLOW_YEAR_REPORT_REQUEST_ERROR'
export const OVERFLOW_YEAR_REPORT_REQUEST_ATTEMPT_ERROR =
  'OVERFLOW_YEAR_REPORT_REQUEST_ATTEMPT_ERROR'

// overflow data
export const OVERFLOW_DATA_REQUEST = 'OVERFLOW_DATA_REQUEST'
export const OVERFLOW_DATA_REQUEST_SUCCESS = 'OVERFLOW_DATA_REQUEST_SUCCESS'
export const OVERFLOW_DATA_REQUEST_ERROR = 'OVERFLOW_DATA_REQUEST_ERROR'

// time series by sensor type
export const SENSOR_DATA_REQUEST = 'SENSOR_DATA_REQUEST'
export const SENSOR_DATA_REQUEST_SUCCESS = 'SENSOR_DATA_REQUEST_SUCCESS'
export const SENSOR_DATA_REQUEST_ERROR = 'SENSOR_DATA_REQUEST_ERROR'

// overflow
export const OVERFLOW_REQUEST = 'OVERFLOW_REQUEST'
export const OVERFLOW_REQUEST_SUCCESS = 'OVERFLOW_REQUEST_SUCCESS'
export const OVERFLOW_REQUEST_ERROR = 'OVERFLOW_REQUEST_ERROR'

// logger info
export const LOGGER_INFO_REQUEST = 'LOGGER_INFO_REQUEST'
export const LOGGER_INFO_REQUEST_SUCCESS = 'LOGGER_INFO_REQUEST_SUCCESS'
export const LOGGER_INFO_REQUEST_ERROR = 'LOGGER_INFO_REQUEST_ERROR'

// associated logger info
export const ASSOCIATED_LOGGER_INFO_REQUEST = 'ASSOCIATED_LOGGER_INFO_REQUEST'
export const ASSOCIATED_LOGGER_INFO_REQUEST_SUCCESS = 'ASSOCIATED_LOGGER_INFO_REQUEST_SUCCESS'
export const ASSOCIATED_LOGGER_INFO_REQUEST_ERROR = 'ASSOCIATED_LOGGER_INFO_REQUEST_ERROR'

// overflow status
export const OVERFLOW_STATUS_REQUEST = 'OVERFLOW_STATUS_REQUEST'
export const OVERFLOW_STATUS_REQUEST_SUCCESS = 'OVERFLOW_STATUS_REQUEST_SUCCESS'
export const OVERFLOW_STATUS_REQUEST_ERROR = 'OVERFLOW_STATUS_REQUEST_ERROR'

export const OVERFLOW_ALL_STATUSES_REQUEST = 'OVERFLOW_ALL_STATUSES_REQUEST'
export const OVERFLOW_ALL_STATUSES_CLEARING = 'OVERFLOW_ALL_STATUSES_CLEARING'

// year
export const YEAR_ASSIGNMENT = 'YEAR_ASSIGNMENT'

export const LOGGER_CHECKING = 'LOGGER_CHECKING'
export const LOGGER_CHECKING_SUCCESS = 'LOGGER_CHECKING_SUCCESS'
export const LOGGER_CHECKING_ERROR = 'LOGGER_CHECKING_ERROR'

export const PHOSPHOR_QUANTITY_UPDATING = 'PHOSPHOR_QUANTITY_UPDATING'
export const PHOSPHOR_QUANTITY_UPDATING_SUCCESS = 'PHOSPHOR_QUANTITY_UPDATING_SUCCESS'
export const PHOSPHOR_QUANTITY_UPDATING_ERROR = 'PHOSPHOR_QUANTITY_UPDATING_ERROR'

export const PHOSPHOR_QUANTITY_GETTING = 'PHOSPHOR_QUANTITY_GETTING'
export const PHOSPHOR_QUANTITY_GETTING_SUCCESS = 'PHOSPHOR_QUANTITY_GETTING_SUCCESS'
export const PHOSPHOR_QUANTITY_GETTING_ERROR = 'PHOSPHOR_QUANTITY_GETTING_ERROR'

export const WEIR_PHOSPHOR_QUANTITY_UPDATING = 'WEIR_PHOSPHOR_QUANTITY_UPDATING'
export const WEIR_PHOSPHOR_QUANTITY_UPDATING_SUCCESS = 'WEIR_PHOSPHOR_QUANTITY_UPDATING_SUCCESS'
export const WEIR_PHOSPHOR_QUANTITY_UPDATING_ERROR = 'WEIR_PHOSPHOR_QUANTITY_UPDATING_ERROR'

export const LOGGER_FILE_FORMATS_REQUEST = 'LOGGER_FILE_FORMATS_REQUEST'
export const LOGGER_FILE_FORMATS_REQUEST_SUCCESS = ' LOGGER_FILE_FORMATS_REQUEST_SUCCESS'
export const LOGGER_FILE_FORMATS_REQUEST_ERROR = 'LOGGER_FILE_FORMATS_REQUEST_ERROR'

export const PAGE_SETTINGS_UPDATE = 'PAGE_SETTINGS_UPDATE'
